import { IActions } from '../../interfaces/actions';
import { ICategoryPhotoResponce } from '../../interfaces/ICategoryPhoto';

import {
  UPDATE_CATEGORY_IMAGES_REQUEST,
  UPDATE_CATEGORY_IMAGES_ERROR,
  UPDATE_CATEGORY_IMAGES_SUCCESS,
  CLEAR_UPDATE_CATEGORY_IMAGES_RESULT,
} from '../types';

interface ICategoryImages {
  result: ICategoryPhotoResponce | null;
  loading: boolean;
  error: string | null;
}

const initialState: ICategoryImages = {
  result: null,
  loading: false,
  error: null,
};

const categoryImagesReducer = (state = initialState, { type, data }: IActions) => {
  switch (type) {
    case UPDATE_CATEGORY_IMAGES_REQUEST:
      return { ...state, loading: true, error: null, result: null };

    case UPDATE_CATEGORY_IMAGES_SUCCESS:
      return { ...state, loading: false, result: data };

    case UPDATE_CATEGORY_IMAGES_ERROR:
      return { ...state, loading: false, error: data };

    case CLEAR_UPDATE_CATEGORY_IMAGES_RESULT:
      return { ...state, result: null, error: null };

    default:
      return state;
  }
};

export default categoryImagesReducer;
