import {
  MouseSensor,
  useSensor,
  TouchSensor,
  DndContext,
  closestCenter,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { Dispatch, SetStateAction, useCallback } from 'react';
import SortableItem from '../../../../Hocs/sortable-item';
import { ProductImage } from './productImage';
import React from 'react';
import { ImagePreview } from '../types/image-preview';

interface DndImagesParam {
  handleUpdateImageOrder?: (images: ImagePreview[]) => void;
  setActiveImgId: Dispatch<SetStateAction<number | string | null>>;
  setImagesPreviewValue: Dispatch<SetStateAction<ImagePreview[]>>;
  imagesPreview: ImagePreview[];
  handleDeleteImg: (img: ImagePreview, idx?: number) => void;
}

function DndImages({
  handleUpdateImageOrder,
  setActiveImgId,
  imagesPreview,
  setImagesPreviewValue,
  handleDeleteImg,
}: DndImagesParam) {
  const sensorsOption = {
    activationConstraint: {
      distance: 8,
    },
  };

  const sensors = useSensors(useSensor(MouseSensor, sensorsOption), useSensor(TouchSensor, sensorsOption));

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveImgId(event.active.id);
  }, []);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id && handleUpdateImageOrder) {
      setImagesPreviewValue((items) => {
        const oldIndex = items.findIndex((item) => item.url === String(active.id));
        const newIndex = items.findIndex((item) => item.url === String(over?.id));

        const updatedImageArray = arrayMove(items, oldIndex, newIndex);

        handleUpdateImageOrder(updatedImageArray);

        return updatedImageArray;
      });
    }

    setActiveImgId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveImgId(null);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={imagesPreview.map((img) => img.url)} strategy={rectSortingStrategy}>
        {imagesPreview.length
          ? imagesPreview.map((img, index) => (
              <SortableItem key={`${img.url}`} id={img.url}>
                <ProductImage
                  key={`${img.url}}`}
                  img={img}
                  handleDeleteImg={() => handleDeleteImg(img, index)}
                />
              </SortableItem>
            ))
          : null}
      </SortableContext>
    </DndContext>
  );
}

export default DndImages;
