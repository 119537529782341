import { DateTime } from 'luxon';

const usePreviousThreeMonthDateRange = () => {

  const currentDate = DateTime.now().setZone('Europe/Kiev');

  const startOfPreviousThreeMonth = currentDate.minus({ months: 3 }).startOf('month');
  const endOfPreviousThreeMonth = startOfPreviousThreeMonth.plus({ months: 2 }).endOf('month');

  const dateRange = {
    start: startOfPreviousThreeMonth!.toISODate().split('T')[0],
    end: endOfPreviousThreeMonth!.toISODate().split('T')[0],
  }

  return dateRange;
};

export default usePreviousThreeMonthDateRange;