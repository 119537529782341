import { IActions } from '../../interfaces/actions';
import { ICategoryPriority, IFeedParameters, ISettingsItem, ISettingsParser } from '../../interfaces/ISettings';
import { ITreeCategory } from '../../interfaces/ITreeCategory';
import {
  REQUEST_SETTINGS,
  LOAD_SETTINGS,
  REQUEST_UPDATE_SETTINGS,
  UPDATE_SETTINGS,
  GET_PARSER_SETTINGS,
  UPDATE_PARSER_SETTINGS,
  SET_PARSER_SETTINGS,
  REQUEST_UPDATE_PARSER_SETTINGS,
  GET_CATEGORIES_WITH_PRIORITY,
  SET_CATEGORIES_WITH_PRIORITY,
  CHANGE_CATEGORY_PRIORITY,
  GENERATE_FEED,
  SET_FEED_PARAMETERS,
  SET_LOADING_SETTINGS,
} from '../types';

export const fetchSettings = (): IActions => ({ type: REQUEST_SETTINGS });

export const loadSettings = (settings: ISettingsItem[]): IActions => ({
  type: LOAD_SETTINGS,
  data: settings,
});

export const fetchUpdateSettings = (name: string, settings: object | string): IActions => ({
  type: REQUEST_UPDATE_SETTINGS,
  data: { name, settings },
});

export const updateSetting = (settings: ISettingsItem): IActions => ({
  type: UPDATE_SETTINGS,
  data: settings,
});

export const generateFeed = (): IActions => ({
  type: GENERATE_FEED,
});

//For working on the parser page
export const getParserSettings = (): IActions => ({
  type: GET_PARSER_SETTINGS,
});

export const setParserSettings = (parsers: ISettingsParser): IActions => ({
  type: SET_PARSER_SETTINGS,
  data: { parsers },
});

export const setFeedParameters = (parameters: IFeedParameters): IActions => ({
  type: SET_FEED_PARAMETERS,
  data: { parameters },
});

export const setLoadingSettings = (): IActions => ({
  type: SET_LOADING_SETTINGS,
});

export const updateParserSettings = (parsers: ISettingsParser): IActions => ({
  type: UPDATE_PARSER_SETTINGS,
  data: { parsers },
});

export const fetchUpdateParserSettings = (name: string, settings: object): IActions => ({
  type: REQUEST_UPDATE_PARSER_SETTINGS,
  data: { name, settings },
});

export const getCategoriesWithPriority = (): IActions => ({
  type: GET_CATEGORIES_WITH_PRIORITY,
});

export const setCategoriesWithPriority = (categories: ITreeCategory[]): IActions => ({
  type: SET_CATEGORIES_WITH_PRIORITY,
  data: categories,
});

export const changeCategoryPriority = (categoriesPriority: ICategoryPriority): IActions => ({
  type: CHANGE_CATEGORY_PRIORITY,
  data: categoriesPriority,
});
