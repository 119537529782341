import { withFormik } from 'formik';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import InnerForm from './Inner-form';
import { IFormCarouselValues } from '../../../../interfaces/widget-form';
import { fetchUpdateSettings } from '../../../../store/actions/settings.actions';

interface carouselFormProps {
  dispatch: Dispatch;
  handleClick: () => void;
  parameters: {
    defaultProductsShow: number;
  };
  name: string;
}

const validationSchema = Yup.object({
  defaultProductsShow: Yup.number().min(4, 'Значення має бути не менше 4').required(),
});

const CarouselForm = withFormik<carouselFormProps, IFormCarouselValues>({
  mapPropsToValues: ({ parameters: { defaultProductsShow } }) => {
    return {
      defaultProductsShow,
    };
  },
  validationSchema: validationSchema,
  handleSubmit: (values: IFormCarouselValues, { setSubmitting, props }) => {
    setSubmitting(false);
    props.handleClick();

    const parameters: carouselFormProps['parameters'] = {
      defaultProductsShow: values.defaultProductsShow,
    };

    props.dispatch(fetchUpdateSettings(props.name, parameters));
  },
})(InnerForm);

export default CarouselForm;
