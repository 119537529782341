import { withFormik } from 'formik';
import { Dispatch } from 'redux';

import InnerForm from './Inner-form';
import { IFormCarouselValues } from '../../../../interfaces/widget-form';
import { generateFeed } from '../../../../store/actions/settings.actions';

interface feedSettingsFormProps {
  dispatch: Dispatch;
}

const FeedSettingsForm = withFormik<feedSettingsFormProps, IFormCarouselValues>({
  handleSubmit: (values: IFormCarouselValues, { setSubmitting, props }) => {
    setSubmitting(false);
    props.dispatch(generateFeed());
  },
})(InnerForm);

export default FeedSettingsForm;
