import React, { useLayoutEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useExtractCategories from '../../../../hooks/useExtractCategories';

const InputCategories = ({ filter, values, handleInputChange, setFieldValue }) => {
  const [flatCategories] = useExtractCategories();
  const [value, setValue] = useState<string>('');

  const getValue = () => {
    const categoryId = parseFloat(filter.category) || null;
    if (categoryId) {
      const category = flatCategories.find((category) => category.id === filter.category);
      return category?.value || '';
    }
    return '';
  };

  useLayoutEffect(() => {
    const currentValue = getValue();

    setValue(currentValue);
  }, [flatCategories, filter]);

  return (
    <Autocomplete
      fullWidth
      disableClearable
      options={flatCategories}
      getOptionLabel={(option) => option.value}
      onChange={(event, newValue: any) => {
        handleInputChange('category', newValue?.id || '', setFieldValue);
      }}
      inputValue={value}
      onInputChange={(event, newInputValue) => {
        setValue(event ? newInputValue : filter.category);
      }}
      renderInput={(params) => <TextField {...params} label="Категорія" />}
      disabled={!values.selectCategory}
      freeSolo
      renderOption={(props) => <span title={props.fullPath}>{`${props.value} - ${props.group}`}</span>}
    />
  );
};

export default InputCategories;
