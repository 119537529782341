import { useEffect, useState } from 'react';
import useTreeCategories from '../hooks/useTreeCategories';
import { ITreeCategory } from '../interfaces/ITreeCategory';

const extractCategories = (categories: ITreeCategory[], path: string[] = [], parentGroup: string = '') => {
  return categories.flatMap((category) => {
    const currentPath: string[] = [...path, category.name];
    const index: number = category.children?.length ? 1 : 0;
    const group: string = parentGroup || (currentPath.length > 1 ? currentPath[index] : '');

    if (category.children?.length) {
      const childCategories = extractCategories(category.children, currentPath, group);
      return category.productCount
        ? [
            {
              value: category.name,
              group: group || category.name,
              fullPath: currentPath.join(' - '),
              id: `${category.id}`,
            },
            ...childCategories,
          ]
        : childCategories;
    }

    return category.productCount
      ? [{ value: category.name, group, fullPath: currentPath.join(' - '), id: `${category.id}` }]
      : [];
  });
};

interface IFlatCategory {
  value: string;
  group: string;
  fullPath: string;
  id: string;
}

const useExtractCategories = () => {
  const { data } = useTreeCategories();
  const [flatCategories, setFlatCategories] = useState<IFlatCategory[]>([]);

  useEffect(() => {
    if (data.length) {
      setFlatCategories(extractCategories(data));
    }
  }, [data]);

  return [flatCategories];
};

export default useExtractCategories;
