import { put, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  addSlides,
  apiGetByRangeSlides,
  deleteSlides,
  fetchedSlides,
  updateSlides,
  updateSlideVisibility,
} from './services/slides.service';
import {
  loadSlides,
  addSlide,
  deleteSlide,
  updateSlide,
  loadSlidesError,
  fetchSlides,
  getSlidesByRangeSuccess,
  getSlidesByRangeError,
} from '../actions/slides.actions';
import { IActions } from '../../interfaces/actions';
import { failSnackBar, successSnackBar } from '../actions/snackbar.actions';
import { RootState } from '../store';

export function* fetchSlideWorker({ data: { page, limit, sort, sortDirect } }: IActions): SagaIterator {
  try {
    const slidesData = yield call(fetchedSlides, page, limit, sort, sortDirect);
    yield put(loadSlides(slidesData));
  } catch (error) {
    yield put(loadSlidesError(error?.message));
    yield put(failSnackBar(error?.message));
  }
}

export function* addSlideWorker({ data }: IActions): SagaIterator {
  try {
    const { paginationPage, paginationLimit, sort, sortDirect } = yield select(
      (state: RootState) => state.slides
    );
    yield call(addSlides, data);
    yield put(successSnackBar());
    yield put(fetchSlides(paginationPage, paginationLimit, sort, sortDirect));
  } catch (error) {
    yield put(failSnackBar(error?.message));
  }
}

export function* deleteSlideWorker({ data: payload }: IActions): SagaIterator {
  try {
    const { data } = yield call(deleteSlides, payload);
    yield put(successSnackBar());
    yield put(deleteSlide(data));
  } catch (error) {}
}

export function* updateSlideWorker({ data }: IActions): SagaIterator {
  try {
    const newSlide = yield call(updateSlides, data);
    yield put(updateSlide(newSlide));
    yield put(successSnackBar());
  } catch (error) {}
}

export function* updateSlideVisibilityWorker({ data }: IActions): SagaIterator {
  try {
    const newSlide = yield call(updateSlideVisibility, data);
    yield put(updateSlide(newSlide));
  } catch (error) {}
}

export function* getSLidesByDateRangeWorker({ data }: IActions): SagaIterator {
  try {
    const { datesArray, slideId } = data;
    const slides = yield call(apiGetByRangeSlides, datesArray, slideId);
    yield put(getSlidesByRangeSuccess(slides));
  } catch (error) {
    yield put(failSnackBar(error.message));
    yield put(getSlidesByRangeError(error.message));
  }
}
