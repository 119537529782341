import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import useTreeCategories from '../../hooks/useTreeCategories';
import useCategoriesModal from '../../hooks/useCategoriesModal';

import Preloader from '../../components/Preloader/Preloader';
import FormDialog from '../../components/Modals/MainTreeCategoryAddModal';
import TreeCategoriesCards from './Cards/TreeCategoriesCards';
import styles from './TreeCategories.module.scss';
import AddBtn from '../../components/AddBtn/AddBtn';
import useModal from '../../hooks/useModal';
import AddPhotosForCategoryModal from '../../components/Modals/AddPhotosForCategoryModal/AddPhotosForCategoryModal';
import products from '../../store/reducers/products.reducer';

const TreeCategories: React.FC = () => {
  const { data, dispatch } = useTreeCategories();
  const categoriesCreateModalData = useCategoriesModal();
  const { isOpened, handleClickOpen, handleClose } = useModal();
  const [isLoaded, setIsLoaded] = useState(false);

  const list = useSelector((state: RootState) => state.treeCategories.list);
  console.log(list);
  useEffect(() => {
    if (data.length && list.length) setIsLoaded(true);
  }, [list, data]);

  return (
    <>
      {!isLoaded ? (
        <>
          <Preloader />
        </>
      ) : (
        <>
          <div className={styles['btns-wrapper']}>
            <AddBtn title="Завантажити фото" handleAdd={handleClickOpen} />
            <AddBtn title="Додати" handleAdd={categoriesCreateModalData.handleClickOpen} />
          </div>

          <div className={styles['content-wrapper']}>
            <FormDialog
              dispatch={dispatch}
              categoriesLength={data?.length}
              modalData={categoriesCreateModalData}
            />
            {isOpened && <AddPhotosForCategoryModal isModalOpen={isOpened} closeModal={handleClose} />}
            {list ? <TreeCategoriesCards dispatch={dispatch} list={list} /> : null}
          </div>
        </>
      )}
    </>
  );
};

export default TreeCategories;
