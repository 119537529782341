import React from 'react';
import { createStyles, makeStyles, Theme, ThemeOptions } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { IInvoiceFile } from '../../../interfaces/IInvoice';
import InvoiceTableBody from './Body/TableBody';
import InvoiceTableFooter from './Footer/TableFooter';
import InvoiceTableHeader from './Header/TableHeader';
import { RootState, AppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';

interface InvoiceDataProps {
  dispatch: AppDispatch;
}

function createData(
  id: number,
  name: string,
  createdAt: string,
  updatedAt: string,
  fileSize: number,
  url?: string
) {
  return { id, name, createdAt, updatedAt, fileSize, url };
}

const useTableStyles = makeStyles(
  (theme: Theme): ThemeOptions =>
    createStyles({
      tableWrapper: {
        padding: theme.spacing(2),
        paddingTop: 0,
        height: '83vh',
      },
      table: {
        'minWidth': 500,
        'overflowY': 'scroll',
        'maxHeight': '75vh',
        '&::-webkit-scrollbar-thumb': {
          display: 'none',
        },
        'scrollbarWidth': 'none',
      },
    })
);

const InvoicesTable: React.FC<InvoiceDataProps> = ({ dispatch }) => {
  const classes = useTableStyles();
  const { invoicesList, page, limit } = useSelector((state: RootState) => state.invoice);
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const filterInvoicesData = (array: any[]) => {
    let filteredArray;
    if (array.length > 0) {
      filteredArray = array.filter((i) => i.invoiceFile !== null);
    } else {
      filteredArray = [];
    }
    return filteredArray;
  };

  const tableData: any[] = filterInvoicesData(invoicesList);

  const data =
    tableData.length > 0
      ? tableData.map((i) => {
          return {
            id: i.id,
            createdAt: i.createdAt,
            updatedAt: i.updatedAt,
            url: i.url,
            fileSize: i.fileSize,
            name: i.invoiceFile.name,
          };
        })
      : [];

  const rows: Array<IInvoiceFile> = data.map((invoice: IInvoiceFile) => {
    return createData(
      invoice.id,
      invoice.name,
      invoice.createdAt,
      invoice.updatedAt,
      invoice.fileSize,
      invoice.url
    );
  });

  return (
    <div className={classes.tableWrapper}>
      <TableContainer component={Paper}>
        <div className={classes.table}>
          <Table aria-label="custom pagination table">
            <InvoiceTableHeader />
            <InvoiceTableBody rows={rows} darkMode={darkMode} dispatch={dispatch} />
          </Table>
        </div>
        <InvoiceTableFooter rowsPerPage={limit} page={page} />
      </TableContainer>
    </div>
  );
};

export default InvoicesTable;
