import { IActions } from '../../interfaces/actions';
import {
  SAVE_PARSERS_STATUSES,
  SET_LOADING,
  CHANGE_PARSER,
  START_CONNECTING,
  SOCKET_CONNECTED,
  RECEIVE_MESSAGE,
  STOP_CONNECTING,
  SAVE_IN_PROGRESS_STATUS,
} from '../types';
import { IParsersData } from '../../interfaces/IParsers';

const initialState: IParsersData = {
  statuses: {
    id: 0,
    parserName: '',
    parserStatus: '',
    lastMessage: '',
    lastStart: '',
    lastUpdate: '',
    lastParsedProduct: 0,
    allParsedProducts: 0,
  },
  isLoading: false,
  inProgressParser: '',
  activeParser: 'fashionGirl',
  message: {
    fashionGirl: ['Service ready. Waiting for command...'],
    pobedov: ['Service ready. Waiting for command...'],
    letsShop: ['Service ready. Waiting for command...'],
    whiteMandarin: ['Service ready. Waiting for command...'],
    sewel: ['Service ready. Waiting for command...'],
    toysi: ['Service ready. Waiting for command...'],
  },
  isEstablishingConnection: false,
  isConnected: false,
};

const parsers = (state = initialState, { type, data }: IActions) => {
  switch (type) {
    // GET ALL
    case SAVE_PARSERS_STATUSES: {
      return {
        ...state,
        statuses: {
          ...state.statuses,
          ...data.statuses,
        },
      };
    }
    // SET LOADING STATUS
    case SET_LOADING: {
      return {
        ...state,
        isLoading: data.isLoading,
      };
    }
    // CHANGE ACTIVE PARSER
    case CHANGE_PARSER: {
      return {
        ...state,
        activeParser: data.activeParser,
      };
    }

    case START_CONNECTING: {
      return {
        ...state,
        isEstablishingConnection: true,
      };
    }

    case STOP_CONNECTING: {
      return {
        ...state,
        isEstablishingConnection: false,
        isConnected: false,
      };
    }

    case SOCKET_CONNECTED: {
      return {
        ...state,
        isConnected: true,
        isEstablishingConnection: true,
      };
    }

    case RECEIVE_MESSAGE: {
      return {
        ...state,
        message: {
          ...state.message,
          [data.newStatus.parserName]: [...state.message[data.newStatus.parserName], '\n' + data.data],
        },
        statuses: {
          ...state.statuses,
          ...data.newStatus,
        },
      };
    }

    case SAVE_IN_PROGRESS_STATUS: {
      return {
        ...state,
        inProgressParser: data,
      };
    }

    default:
      return state;
  }
};

export default parsers;
