import React from 'react';

import { ISettingsItem } from '../../../interfaces/ISettings';
import SettingsAccordion from './SettingsAccordion/SettingsAccordion';
import s from './SettingsContent.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

interface SettingsDataProps {
  data: Array<ISettingsItem>;
}

const SettingsContent: React.FC<SettingsDataProps> = ({ data }) => {
  const { user } = useSelector((state: RootState) => state.user);
  return (
    <div className={s.container}>
      {data.map((item) => {
        const isString = typeof item.name === 'string';
        if ((isString && item.name === 'parser') || (isString && item.name === 'size-priority')) {
          return null;
        }
        if (
          user?.role.name !== 'moderator' &&
          (item.name === 'auto-start-parsers' || item.name === 'feed-settings')
        ) {
          return null;
        }
        return <SettingsAccordion key={item.id} data={item} />;
      })}
    </div>
  );
};

export default SettingsContent;
