import { COLORS } from '../../values/colors';

export const stylesParserPage = {
  containerDark: {
    backgroundColor: COLORS.darkGray,
  },
  consoleDark: {
    backgroundColor: COLORS.secondaryDarkGray,
    color: '#fff',
  },
  lineItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
  title: {
    width: '250px',
    marginRight: '10px',
  },
  btn: {
    borderRadius: '30px',
    color: COLORS.primaryLight,
    marginLeft: 'auto',
    marginBottom: '10px',
  },
  btnLight: {
    'backgroundColor': COLORS.primaryGreen,
    '&:hover': {
      backgroundColor: COLORS.secondaryGreen,
    },
  },
  btnDark: {
    'backgroundColor': COLORS.darkGreyButtonColor,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkGreyButtonColor,
    },
  },
  btnPrimaryDark: {
    'backgroundColor': COLORS.darkGreen,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkGreen,
    },
  },

  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: COLORS.primaryGreen,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: COLORS.primaryGreen,
    },
    'margin': 'left',
  },
  switchDark: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: COLORS.darkGreen,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: COLORS.darkGreen,
    },
    'margin': 'left',
  },
  declineButton: {
    'backgroundColor': COLORS.primaryGray,
    '&:hover': {
      backgroundColor: COLORS.secondaryGray,
    },
  },
  declineButtonDark: {
    'backgroundColor': COLORS.darkGray,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkGray,
    },
  },
  confirmButton: {
    'backgroundColor': COLORS.primaryRed,
    '&:hover': {
      backgroundColor: COLORS.secondaryRed,
    },
  },
  confirmButtonDark: {
    'backgroundColor': COLORS.darkRed,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkRed,
    },
  },
  tabsLight: {
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: COLORS.primaryGreen,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: COLORS.primaryGreen,
    },
  },
};
