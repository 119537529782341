import React, { useEffect } from 'react';
import style from './ErrorModal.module.scss';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import useModal from '../../../hooks/useModal';
import { RootState } from '../../../store/store';
import { stylesParserPage } from '../styles';
import { changeParser } from '../../../store/actions/parsers.actions';

const useStyles = makeStyles(stylesParserPage);

type ErrorModalProps = {
  inProgressParser: string
  parserName: string
}

const ErrorModal: React.FC<ErrorModalProps> = ({inProgressParser, parserName}) => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const { handleClickOpen, isOpened, handleClose } = useModal();

  useEffect(() => {
    if (inProgressParser && parserName !== inProgressParser) {
      handleClickOpen()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const onBack = (values: any) => {
    dispatch(changeParser(inProgressParser))
    handleClose();
  };

  return (
    <div className='errorForm'>
      <Dialog
        open={isOpened}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogContent dividers>
          <Formik
            initialValues={{
            }}
            onSubmit={() => {}}
          >
            {({ values, setFieldValue }) => (
              <Form className={style.mainForm}>
                <DialogTitle className={style.text}>Працює парсер {inProgressParser}</DialogTitle>
                <div className={style.box}>
                  <Button
                    variant="contained"
                    type="button"
                    onClick={onBack}
                    className={classNames(
                      classes.btn,
                      darkMode ? classes.btnDark : classes.btnLight
                    )}
                  >
                    Перейти до парсера {inProgressParser}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ErrorModal;
