export const cleanObject = (obj: object) => {
  const cleaned = {};

  for (const key in obj) {
    if (obj[key] || obj[key] === false) {
      cleaned[key] = obj[key];
    }
  }

  return cleaned;
};
