import React from 'react';
import { LinearProgress } from '@material-ui/core';
import styles from './LightScreen.module.scss';

const LightScreenLinearProgress: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.linearProgress}>
        <LinearProgress />
      </div>
      <div className={styles.lightScreen}></div>
    </div>
  );
};

export default LightScreenLinearProgress;
