import { ITreeCategory, ICharResponse } from './ITreeCategory';

export interface Common {
  id: number;
  createdAt: string;
  updatedAt: string;
}

export interface IBasicProduct extends Common {
  name: string;
  key: string;
  description: string;
  price: number;
  discountedPrice: number | null;
  isActiveDiscount?: boolean;
  discountedPriceEffectiveDate?: string;
  availability: boolean;
  disabled: boolean;
  characteristicValue: ICharResponse[];
  nameInProvider: string;
  status: string;
  user: any;
  updateParams: any | null;
}

export interface GeneralProductInfo {
  name: string;
  description: string;
  price: number | string;
  categoryName: string;
  categoryId: number | string;
  key: string;
  nameInProvider?: string;
}

export interface IGetProducts extends IBasicProduct {
  category: ITreeCategory;
  files: IImg[];
  mainImg: IImg | null;
}

export interface IImg extends Common {
  name: string;
  url: string;
}

export interface IGetProductById extends IGetProducts {
  characteristicValue: ICharResponse[];
}

export interface IProductsSearchResponse {
  data: IGetProducts[];
  count: number;
  totalPages: number;
}

interface GroupChar extends Common {
  name: string;
}

export interface IAddCharResponse extends Common {
  name: string;
  type: string;
  stringValue: string | null;
  numberValue: number | null;
  enumValue: string[] | null;
  booleanValue: boolean | null;
  jsonValue: object | null;
  dateValue: string | null;
  characteristic: ICharResponse;
  group: GroupChar;
  product: IBasicProduct;
}

export interface IAddProduct extends GeneralProductInfo {
  files?: [] | FormData;
  subForm?: object;
}

export interface IUpdateProduct {
  id?: number;
  files: IImg[] | {};
  subForm?: object;
}

export interface IProductItem extends IBasicProduct {
  category: ITreeCategory;
  files?: IImg[];
  mainImg?: IImg;
}

export interface IProductsData {
  list: Array<IGetProducts>;
  loading: boolean;
  currentProduct: IGetProductById | null;
  error: string | null;
  count: number;
  totalPages: number;
  isSearch: boolean;
  paginationPage: number;
  paginationLimit: number;
  searchValue: null | string;
  paginationPageSearch: number;
  sort: string;
  sortDirect: string;
  priceMin: number | null;
  priceMax: number | null;
  filter: IProductsFilter;
  sizes: string[];
  statistic: IProductStatistic | IMessage | null;
  discountInfo: IDiscountInfo;
  shopList: IShopList[] | null;
  isFirstRender?: boolean;
  reloadPrice: boolean;
}

export interface IPriceRange {
  max: number;
  min: number;
}

export interface CharValues {
  name: string;
  stringValue?: string | null;
  numberValue?: number | null;
  enumValue?: string[] | null;
  rangeValue?: number[] | null;
  booleanValue?: boolean | null;
  jsonValue?: object | null;
  dateValue?: string | null;
}

export interface ICharValue extends CharValues {
  id?: number;
  characteristicId: number;
}

export interface IProductCharResponse extends Common, CharValues {
  type: string;
}

export interface IProductCharRequest {
  productId: number;
  characteristicValues: ICharValue[];
}

export interface IDeleteProductChars {
  characteristicValuesIds: string[];
}

export interface IAddImgResponse extends Common {
  name: string;
  product: IBasicProduct;
  url: string;
}

export interface IProductsInCart extends Common {
  quantity: number;
  productId: number;
  ordersId: number;
  orders: {
    id: number;
    createdAt: string;
    updatedAt: string;
    userId: number;
    status: string;
    notcall: boolean;
  }[];
  product: IGetProducts;
}

export enum Type {
  enum = 'enum',
  json = 'json',
  string = 'string',
  number = 'number',
  boolean = 'boolean',
  range = 'range',
  date = 'date',
}

export interface IProductsFilter extends IBasicProductsFilter {
  id?: null | number;
  name?: string;
  price?: number[] | null[];
}

export interface IBasicProductsFilter {
  shop: string;
  size: string;
  category: string;
  disabled: string;
  discounted: string;
  availability: string;
}

export interface ProductsTableProps {
  list: IGetProducts[];
  activeColumns: string[];
  isSearch: boolean;
  count: number;
  paginationPage: number;
  paginationLimit: number;
  sort: string;
  sortDirect: string;
  filter: IProductsFilter;
}

export interface IUpdateAvailabilityProduct {
  userId: number | undefined;
  productId: number;
  availability: boolean;
  categoryId: number;
}

export interface IDisableProduct {
  userId: number | undefined;
  productId: number;
  disabled: boolean;
  categoryId: number;
}

export interface IUpdateDiscountedPrice {
  userId: number | undefined;
  productId: number;
  discountedPrice: number | null;
  categoryId: number;
}

export interface IProductStatistic {
  totalItemsSold: number;
  totalSoldPrice: number;
  totalBuyWithDiscount: number;
  totalSoldPriceWithDiscount: number;
}

export interface IMessage {
  message: string;
}

export interface IDiscountInfo {
  totalDiscount: number | null;
  discountPercentage: number | null;
  totalNextDiscount: number | null;
}

export interface IShopList {
  id: number;
  parserName: string;
}
