import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { IInvoiceDateRange } from '../../interfaces/IInvoice';
import {
  generateInvoiceError,
  generateInvoiceSuccess,
  getInvoicesListError,
  getInvoicesListSuccess,
  removeInvoiceError,
  getInvoicesListRequest,
} from '../actions/invoice.actions';
import { failSnackBar, successSnackBar } from '../actions/snackbar.actions';
import { apiGenerateInvoice, apiGetInvoicesList, apiRemoveInvoice } from './services/invoice.service';

export function* getInvoicesListWorker({ data }): SagaIterator {
  try {
    const invoices = yield call(apiGetInvoicesList, data);
    yield put(getInvoicesListSuccess(invoices));
  } catch (e: any) {
    yield put(failSnackBar(e.message));
    yield put(getInvoicesListError(e.message));
  }
}

type removeParams = { data: { invoiceName: string }; type: string };

export function* removeInvoiceWorker({ data }: removeParams): SagaIterator {
  try {
    const invoice = yield call(apiRemoveInvoice, data.invoiceName);

    if (invoice) {
      const { page, limit, count, sort, sortDirect } = yield select((state) => state.invoice);
      const finalPage = Math.max(1, Math.min(page, Math.ceil((count - 1) / limit)));

      yield put(getInvoicesListRequest(finalPage, limit, sort, sortDirect));
    }

    yield put(successSnackBar());
  } catch (e: any) {
    yield put(failSnackBar(e.message));
    yield put(removeInvoiceError(e.message));
  }
}

type generateParams = { data: { invoiceDateRange: IInvoiceDateRange }; type: IInvoiceDateRange };

export function* generateInvoiceWorker({ data }: generateParams): SagaIterator {
  try {
    const newInvoice = yield call(apiGenerateInvoice, data.invoiceDateRange);
    yield put(generateInvoiceSuccess(newInvoice));

    const { page, limit, sort, sortDirect } = yield select((state) => state.invoice);
    yield put(getInvoicesListRequest(page, limit, sort, sortDirect));
  } catch (e: any) {
    yield put(failSnackBar(e.message));
    yield put(generateInvoiceError(e.message));
  }
}
