import React from 'react';

import SettingsContent from '../Content/Settings/SettingsContent';
import useSettings from '../../hooks/useSettings';
import Preloader from '../Preloader/Preloader';

const SettingsContainer: React.FC = () => {
  const { data } = useSettings();
  return <>{data?.length ? <SettingsContent data={data} /> : <Preloader />}</>;
};

export default SettingsContainer;
