// import moment from 'moment-timezone'; // old way REMOVE PACKAGE AFTER TASK WILL BE DONE!!!
import { DateTime } from 'luxon';

const usePreviousWeekDateRange = () => {

  // const currentDate = moment().tz('Europe/Kiev'); old way
  const currentDate = DateTime.now().setZone('Europe/Kiev');
  // const startOfPreviousWeek = currentDate.subtract(1, 'week').startOf('week').add(1, 'day'); old way
  const startOfPreviousWeek = currentDate.minus({ weeks: 1 }).startOf('week');
  // const endOfPreviousWeek = startOfPreviousWeek.clone().add(6, 'day'); old way
  const endOfPreviousWeek = startOfPreviousWeek.plus({ days: 6 });
  const dateRange = {
    start: startOfPreviousWeek!.toISODate().split('T')[0],
    end: endOfPreviousWeek!.toISODate().split('T')[0],
  }

  return dateRange;
};

export default usePreviousWeekDateRange;