import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { AppDispatch, RootState } from '../store/store';
import { getOrdersRequest, getOrdersByParamsRequest } from '../store/actions/orders.actions';

const useOrders = (searchValue: string) => {
  const dispatch: AppDispatch = useDispatch();
  const { list, filter, page, limit, sortingInfo } = useSelector((state: RootState) => state.orders);
  const location = useLocation();

  useEffect(() => {
    const parsed = queryString.parse(location.search) as QueryTypes;
    const actualPage = Number(parsed.page) || page || 1;
    const actualLimit = Number(parsed.limit) || limit || 10;
    const actualSortingInfo = {
      sort: parsed.sort || sortingInfo.sort || 'createdAt',
      sortDirect: parsed.sortDirect || sortingInfo.sortDirect || 'asc',
    };
    const actualSearchValue = parsed.searchValue || searchValue || null;
    const actualFilter: QueryTypes = {
      status: parsed.status || filter.status || '',
      paymentStatus: parsed.paymentStatus || filter.paymentStatus || '',
      phoneNumber: parsed.phoneNumber || filter.phoneNumber || '',
      minAmount: Number(parsed.minAmount) || filter.minAmount || null,
      maxAmount: Number(parsed.maxAmount) || filter.maxAmount || null,
    };

    dispatch(getOrdersRequest(actualPage, actualLimit, actualFilter, actualSortingInfo, actualSearchValue));
  }, []);

  return { list, dispatch };
};

export default useOrders;

type QueryTypes = {
  page?: string | number;
  limit?: string | number;
  status?: string;
  paymentStatus?: string;
  phoneNumber?: string;
  minAmount?: string | number;
  maxAmount?: string | number;
  sort?: string;
  sortDirect?: string;
  searchValue?: string | null;
};
