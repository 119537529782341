import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';

import { ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip, CartesianGrid } from 'recharts';

import CustomTooltip from './ToolTipBlock';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { IUserDateRange } from '../../interfaces/IUsers';
import { COLORS } from '../../values/colors';
import { StatisticActionContext } from '../../context/staistic-action-context';

interface IDataChart {
  date: string;
  value: string;
  valueSecond?: string;
}
interface IStatisBlock {
  chartData: IDataChart[] | null;
  setChartData: Dispatch<SetStateAction<IDataChart[] | null>>;
}

const StatisticsBlock: React.FC<IStatisBlock> = ({ chartData, setChartData }) => {
  const users = useSelector((state: RootState) => state.users.rangeUsers);
  const comments = useSelector((state: RootState) => state.comments.rangeComments);
  const orders = useSelector((state: RootState) => state.orders.rangeOrders);
  const status = useSelector((state: RootState) => state.orders.rangeOrdersStatus);
  const slides = useSelector((state: RootState) => state.slides.rangeSlides);

  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const [dataFrom, setDataFrom] = useState<string | null>(null);

  const { action } = useContext(StatisticActionContext);

  const dataToChart = useCallback(
    (reqData: any) => {
      let dataArr: any[];
      if (reqData && reqData.registredUsers && reqData.registredUsers.length) {
        dataArr = reqData.registredUsers.map((user: IUserDateRange) => {
          return { date: user.date, value: user.creatad, label: dataFrom };
        });
      }
      if (reqData[0] && reqData[0].paid) {
        dataArr = reqData.map(
          (user: { date: string; paid: string; notpaid: string; paidSum: string; notPaidSum: string }) => {
            return {
              date: user.date,
              value: user.paid,
              valueSecond: user.notpaid,
              sumValue: user.paidSum,
              sumValueSecond: user.notPaidSum,
              label: dataFrom,
            };
          }
        );
      }
      if (reqData[0] && reqData[0].completed) {
        dataArr = reqData.map(
          (user: {
            date: string;
            completed: string;
            cancelled: string;
            completedSum: string;
            cancelledSum: string;
          }) => {
            return {
              date: user.date,
              value: user.completed,
              valueSecond: user.cancelled,
              sumValue: user.completedSum,
              sumValueSecond: user.cancelledSum,
              label: dataFrom,
            };
          }
        );
      }
      if (reqData[0] && reqData[0].creatad) {
        dataArr = reqData.map((user: { date: string; creatad: string }) => {
          return { date: user.date, value: user.creatad, label: dataFrom };
        });
      }

      if (reqData[0] && reqData[0].label === 'slides') {
        dataArr = reqData;
      }

      if (dataArr! && dataArr.length === 1) {
        const mockData = dataArr[0];
        dataArr.unshift(mockData);
        dataArr.push(mockData);
      }
      return setChartData(dataArr!);
    },
    [dataFrom]
  );

  useEffect(() => {
    if (action) {
      setDataFrom(action);
    }
  }, [action]);

  useEffect(() => {
    if (users) {
      dataToChart(users);
    }
  }, [users]);
  useEffect(() => {
    if (orders) {
      dataToChart(orders);
    }
  }, [orders]);
  useEffect(() => {
    if (status) {
      dataToChart(status);
    }
  }, [status]);
  useEffect(() => {
    if (comments) {
      dataToChart(comments);
    }
  }, [comments]);

  useEffect(() => {
    if (slides) {
      dataToChart(slides);
    }
  }, [slides]);

  return (
    <>
      {chartData && (
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart data={chartData}>
            <defs>
              <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor={darkMode ? COLORS.darkGreen : COLORS.primaryGreen}
                  stopOpacity={0.4}
                />
                <stop
                  offset="75%"
                  stopColor={darkMode ? COLORS.darkGreen : COLORS.primaryGreen}
                  stopOpacity={0.05}
                />
              </linearGradient>
              {chartData[0].valueSecond && (
                <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={COLORS.primaryBlue} stopOpacity={0.4} />
                  <stop offset="75%" stopColor={COLORS.primaryBlue} stopOpacity={0.05} />
                </linearGradient>
              )}
            </defs>

            <Area
              dataKey="value"
              stroke={darkMode ? COLORS.darkGreen : COLORS.primaryGreen}
              fill="url(#color1)"
            />
            {chartData[0].valueSecond && (
              <Area dataKey="valueSecond" stroke={COLORS.primaryBlue} fill="url(#color2)" />
            )}

            <XAxis
              dataKey={(data) => {
                if (data.date)
                  return `${data.date.split('-')[2]}.${data.date.split('-')[1]}.${data.date.split('-')[0]}`;
              }}
              axisLine={false}
              tickLine={false}
            />

            <YAxis
              dataKey={(data) => {
                if (data.valueSecond && parseInt(data.valueSecond) > parseInt(data.value))
                  return parseInt(data.valueSecond) + parseInt(data.valueSecond);
                return parseInt(data.value) + parseInt(data.value);
              }}
              axisLine={true}
              tickLine={true}
              tickCount={1}
            />

            <Tooltip content={<CustomTooltip />} />

            <CartesianGrid opacity={0.1} vertical={false} />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default StatisticsBlock;
