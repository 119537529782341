import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface Props {
    id: string,
    children: React.ReactNode
}

const SortableItem = ({ id, children }: Props) => {
    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    };

    return (
        <div
            ref={setNodeRef}
            style={{ ...style, opacity: isDragging ? '0.5' : '1' }}
            {...attributes}
            {...listeners}
        >{children}</div>
    );
};

export default SortableItem;
