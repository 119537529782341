import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import React, { FC } from 'react';
import Delete from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { ICategoryPhoto } from '../../../interfaces/ICategoryPhoto';
import styles from './ImageCardList.module.scss';

const useStyles = makeStyles({
  checkIcon: {
    color: 'green',
  },
});

interface IImageCardList {
  imageList: ICategoryPhoto[];
  onDelete: (url: string) => void;
}

const ImageCardList: FC<IImageCardList> = ({ imageList, onDelete }) => {
  const classes = useStyles();
  return (
    <ImageList cols={3} rowHeight={150}>
      {imageList.map((item) => (
        <ImageListItem key={item.file.name}>
          {item?.errorMessage && (
            <div className={styles.errorIconContainer}>
              <Tooltip arrow title={item?.errorMessage ? item?.errorMessage : ''}>
                <ErrorOutlineIcon fontSize="large" color="error" />
              </Tooltip>
            </div>
          )}
          {item?.success && (
            <div className={styles.errorIconContainer}>
              <CheckCircleOutlineIcon fontSize="large" className={classes.checkIcon} />
            </div>
          )}
          <img src={item.url} alt={item.file.name} loading="lazy" />
          <ImageListItemBar
            title={item.file.name}
            subtitle={`Category: ${item.category}`}
            position="bottom"
            actionIcon={
              <IconButton onClick={() => onDelete(item.file.name)}>
                <Delete color="primary" />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImageCardList;
