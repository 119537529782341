import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Button, createStyles, makeStyles, ThemeOptions } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { COLORS } from '../../values/colors';
import { RootState } from '../../store/store';

interface IBtnProps {
  handleClick: () => void;
}

const useStyles = makeStyles(
  (): ThemeOptions =>
    createStyles({
      btn: {
        'borderRadius': '30px',
        'padding': '6px 15px 6px 15px',
        'color': COLORS.primaryLight,
        'backgroundColor': COLORS.primaryGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryGreen,
        },
      },
      btnDark: {
        'backgroundColor': COLORS.darkGreen,
        'border': 'transparent',
        '&:hover': {
          backgroundColor: COLORS.secondaryDarkGreen,
        },
      },
    })
);

const ColumnsBtn: React.FC<IBtnProps> = ({ handleClick }) => {
  const classes = useStyles();
  const { darkMode } = useSelector((state: RootState) => state.theme);

  return (
    <Button
      variant="contained"
      color="default"
      type="button"
      className={clsx(classes.btn, darkMode && classes.btnDark)}
      onClick={handleClick}
      endIcon={<SettingsIcon />}
    >
      Колонки
    </Button>
  );
};

export default ColumnsBtn;
