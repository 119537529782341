import {
  ADD_SLIDE,
  DELETE_SLIDE,
  LOAD_SLIDES,
  REQUEST_SLIDES,
  UPDATE_SLIDE,
  SET_SLIDES_FIRST_RENDER,
  GET_SLIDES_BY_RANGE_ERROR,
  GET_SLIDES_BY_RANGE_REQUEST,
  GET_SLIDES_BY_RANGE_SUCCESS,
  LOAD_SLIDES_ERROR,
} from './../types';
import { IActions } from '../../interfaces/actions';
import { ISlidesData } from '../../interfaces/ISlides';

const data: ISlidesData = {
  list: [],
  loading: false,
  error: null,
  count: 0,
  totalPages: 0,
  paginationPage: 1,
  paginationLimit: 10,
  sort: 'id',
  sortDirect: 'desc',
  isFirstRender: true,
  rangeSlides: [],
};

const slides = (state = data, action: IActions): ISlidesData => {
  switch (action.type) {
    case LOAD_SLIDES: {
      return {
        ...state,
        list: action.data.data,
        count: action.data.count,
        totalPages: action.data.totalPages,
        loading: false,
        isFirstRender: false,
      };
    }
    case LOAD_SLIDES_ERROR: {
      return { ...state, error: action.data, loading: false };
    }
    case REQUEST_SLIDES: {
      return {
        ...state,
        loading: true,
        error: null,
        paginationPage: action.data.page,
        paginationLimit: action.data.limit,
        sort: action.data.sort,
        sortDirect: action.data.sortDirect,
      };
    }
    case SET_SLIDES_FIRST_RENDER: {
      return {
        ...state,
        isFirstRender: action.data,
      };
    }
    case ADD_SLIDE: {
      return { ...state, list: [...state.list, action.data] };
    }
    case DELETE_SLIDE: {
      return {
        ...state,
        list: action.data.data,
        count: action.data.count,
        paginationPage: action.data.page,
        totalPages: action.data.totalPages,
      };
    }
    case UPDATE_SLIDE: {
      return { ...state, list: [...state.list.map((x) => (x.id === action.data.id ? action.data : x))] };
    }
    case GET_SLIDES_BY_RANGE_REQUEST: {
      return {
        ...state,
        rangeSlides: [],
        loading: true,
        error: null,
      };
    }

    case GET_SLIDES_BY_RANGE_SUCCESS: {
      return {
        ...state,
        rangeSlides: action.data,
        loading: false,
      };
    }

    case GET_SLIDES_BY_RANGE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    }

    default:
      return state;
  }
};

export default slides;
