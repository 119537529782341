import { withFormik } from 'formik';
import { Dispatch } from 'redux';

import InnerForm from './Inner-form';
import { changeCategoryPriority } from '../../../../../store/actions/settings.actions';
import { ICategoryPriority } from '../../../../../interfaces/ISettings';

interface CategoriesPriorityFormProps {
  category: ICategoryPriority;
  existingPriorities: Set<number>;
  cleanSelector: () => void;
  dispatch: Dispatch;
}

const CategoriesPriority = withFormik<CategoriesPriorityFormProps, ICategoryPriority>({
  mapPropsToValues: (props) => props.category,
  validate(values, props) {
    const errors = {};

    if (!values.priority) {
      errors[values.name] = "Пріоритет обов'язковий";
    } else if (values.priority < 1) {
      errors[values.name] = 'Пріоритет має бути позитивним числом.';
    } else if (props.existingPriorities.has(values.priority)) {
      errors[values.name] = 'Пріоритет має бути унікальним.';
    } else if (values.priority > 10000) {
      errors[values.name] = 'Це занадто :)';
    }

    return errors;
  },

  handleSubmit: (values: ICategoryPriority, { setSubmitting, props }) => {
    setSubmitting(false);

    props.dispatch(changeCategoryPriority(values));
    props.cleanSelector();
  },
})(InnerForm);

export default CategoriesPriority;
