import { api } from '../../../api/api';

export async function apiGetOrders(
  page: number,
  limit: number,
  filter: object,
  sortingInfo: object,
  searchValue: string
) {
  const orders = await api.orders.get(page, limit, filter, sortingInfo, searchValue);
  return orders.data;
}

export async function apiGetNewAmount(filter: object, searchValue: string | null) {
  const amount = await api.orders.getNewAmount(filter, searchValue);
  return amount.data;
}

export async function apiGetOrderById(id: number) {
  const order = await api.orders.getById(id);
  return order.data;
}

export async function apiDeleteOrderById(id: number) {
  return await api.orders.deleteOrderById(id);
}

export async function apiGetOrderByRange(datesArray: string[]) {
  const order = await api.orders.getByDatesRange(datesArray);
  return order.data;
}
export async function apiGetOrderStatusByRange(datesArray: string[]) {
  const order = await api.orders.getStatusByDatesRange(datesArray);
  return order.data;
}

export async function apiGetOrderStatisticByRange(datesArray: string[]) {
  const order = await api.orders.getStatusStatisticByRange(datesArray);
  return order.data;
}

export async function apiUpdateOrder(orderId: number, productId: number, data) {
  const order = await api.orders.update(orderId, productId, data);
  return order.data;
}

export async function apiUpdateOrderStatus(id: number, status) {
  const order = await api.orders.updateStatus(id, status);
  return order.data;
}

export async function apiUpdateOrderPaymentStatus(id: number, paymentStatus) {
  const order = await api.orders.updatePaymentStatus(id, paymentStatus);
  return order.data;
}

export async function apiGetOrdersByParams(page: number, limit: number, searchValue: string) {
  const order = await api.orders.getByParams(page, limit, searchValue);
  return order.data;
}

export async function apiUpdateProductInOrder(data) {
  const order = await api.orders.updateProductInOrder(data);
  return order.data;
}
