import { IActions } from '../../interfaces/actions';
import { SUCCESS_SNACKBAR, FAIL_SNACKBAR, CLOSE_SNACKBAR } from '../types';

export const successSnackBar = (message?: string | null): IActions => ({
  type: SUCCESS_SNACKBAR,
  data: message || null,
});
export const failSnackBar = (error: string | string[]): IActions => ({
  type: FAIL_SNACKBAR,
  data: error,
});
export const closeSnackBar = (): IActions => ({ type: CLOSE_SNACKBAR });
