import React, { useState } from 'react'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawWinnerStart } from '../../store/actions/draw.actions';
import { RootState } from '../../store/store';

import { Card, makeStyles } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { api } from '../../api/api';

enum cols {
   id = 'id',
   name = 'Імʼя учасника',
   createdAt = 'Дата реэстрації',
   phoneNumber = 'Номер телефону',
   instagram = 'Instagram',
   isWinner = 'Переможець',
}

const useStyles = makeStyles({
   'wrapper': {
      'height': '450px',
      'overflow': 'scroll',
      'width': '100%',
      'padding': '5px',
      'justifyContent': 'space-around',
      'alignItems': 'center',
      'border': '1px solid #000',
      'boxShadow': 'rgba(0, 0, 0, 0.35) 0px 5px 15px;',
      'borderRadius': '5px',
      'fontSize': '18px'
   },
   'title': {
      'fontSize': '20px',
      'padding': '10px 0',
      'margin-left': '30px'
   },
   'table': {
      'marginTop': '10px',
      'width': '100%',
      'padding': '5px',
      'position': 'relative'
   },
   'btnRefresh': {
      'position': 'absolute',
      'top': '20px',
      'right': '50px',
      'cursor': 'pointer',
      'transition': '0.3s all',
      'color': '#26bd62',
      'zIndex': 5,
 
      '&:hover': {
         'opacity': '.7',
      }
   },
   'btnRefreshDark': {
      'cursor': 'pointer',
      'transition': '0.3s all',
      'color': 'rgb(74, 124, 86)',
      'position': 'absolute',
      'top': '20px',
      'right': '50px',
      'zIndex': 5,
 
      '&:hover': {
         'opacity': '.7',
      }
   },
   'btnFilterAll': {
      'position': 'absolute',
      'top': '20px',
      'right': '15px',
      'cursor': 'pointer',
      'transition': '0.3s all',
      'color': '#26bd62',
      'zIndex': 5,
 
      '&:hover': {
         'opacity': '.7',
      }
   },
   'btnFilterAllDark': {
      'cursor': 'pointer',
      'transition': '0.3s all',
      'color': 'rgb(74, 124, 86)',
      'position': 'absolute',
      'top': '20px',
      'right': '15px',
      'zIndex': 5,
 
      '&:hover': {
         'opacity': '.7',
      }
   },
   'delete': {
      'cursor': 'pointer',
      'transition': '0.3s all',
      'color': 'red',
 
      '&:hover': {
         'color': 'rgb(216, 0, 0)',
      }
   },

})

const DrawParticipants: React.FC = () => {

   const dispatch = useDispatch()
   
   const data = useSelector((state: RootState) => state.drawsReducer.drawUsers)
   const draw = useSelector((state: RootState) => state.drawsReducer)
   const { darkMode } = useSelector((state: RootState) => state.theme);

   const [checkedMap, setCheckedMap] = useState({});
   
   const classes = useStyles();

   const selectedRowWinner = row => row.winner === true

   const isWinner = data?.filter(el => el.winner === true).length

   const drawsColumns = [
         {
            name: '№',
            cell: (row, index) => index+1,
            grow: 0,
            maxWidth: '9%',
            minWidth: '7%',
         },
         {
            name: cols.name,
            selector: (row) => `${row.user.firstName} ${row.user.lastName}`,
         },
         {
            name: cols.instagram,
            selector: (row) => row.instagram ? row.instagram : <RemoveCircleOutlineIcon fontSize='small' color={'error'}/>,
         },
         {
            name: cols.phoneNumber,
            selector: (row) => row.user.phoneNumber,
         },
         {
            name: cols.createdAt,
            selector: (row) => row.user.createdAt.toString().split('T')[0],
         },
         {
            name: cols.isWinner,
            cell: (row) => <input 
                              type='checkbox' 
                              style={{accentColor: '#28a745'}} 
                              onChange={() => handleWinner(row.user.id)} 
                              checked={checkedMap[row.id] || false}
                           />,
            omit: isWinner
         },   
   ];

   // table custom styles
   const dataTable = {
      headCells: {
         style: {
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '23px',
            fontSize: '16px',
            textAlign: 'center'
         },
      },
      cells: {
         style: {
            justifyContent: 'center'
         },
      },
      
   }


   const handleWinner = (winnerId) => {
      const confirmed = window.confirm(`Підтверждаєте переможця? Відмінити це рішення буде не можливо!`);
      if (confirmed) {
        const updatedCheckedMap = { ...checkedMap, [winnerId]: true };
  
        const raffleId = String(draw.id);
        const userId = String(winnerId);
  
        dispatch(setDrawWinnerStart(raffleId, userId));
        setCheckedMap(updatedCheckedMap);
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
      
        const createCoupon = {
          userId,
          raffleId,
          discountAmount: draw.prizeFund,
          expiresAt: expirationDate.toISOString(),
        };
        api.coupon.createCoupon(createCoupon)
      } else {
        alert('Переможця не визначено..!');
      }
    };

   
   return (
      <div className={classes.wrapper}>
            <p className={classes.title}>Список учасників розіграшу:</p>
               <Card className={classes.table}>
                  <DataTable
                     data={data}
                     columns={drawsColumns}
                     customStyles={dataTable}
                     theme={darkMode ? 'dark' : 'default'}
                     center
                     responsive
                     fixedHeader={true}
                     fixedHeaderScrollHeight={'60vh'}
                     pagination
                     paginationRowsPerPageOptions={[5, 10, 20, 30, 40, 50, 100]}
                     selectableRowsHighlight
                     selectableRowSelected={selectedRowWinner}
                  />
               </Card>
         </div>
      )
   }

   export default DrawParticipants