import axios from 'axios';

import { getToken } from '../services/local-storage-controller';
import { root as apiUrl } from './config';

axios.defaults.baseURL = apiUrl;

const instance = axios.create({
  baseURL: `${apiUrl}`,
});

instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return error.data.message;
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      console.error('Network error:', error);
      // Перенаправляємо з передачею поточного шляху
      window.location.href = '/error?prev=' + window.location.pathname;
    } else if (error.response?.status === 500) {
      console.error('Internal server:', error);
      window.location.href = '/error?prev=' + window.location.pathname;
    }
    return Promise.reject(error);
  }
);

export const temporaryToken = 'Bearer ' + getToken();

export default instance;
