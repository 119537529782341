import { withFormik } from 'formik';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import InnerForm from './Inner-form';
import { IFormDrawValues } from '../../../../interfaces/widget-form';
import { fetchUpdateSettings } from '../../../../store/actions/settings.actions';

interface drawFormProps {
  dispatch: Dispatch;
  handleClick: () => void;
  parameters: {
    enable: boolean;
  };
  name: string;
}

const validationSchema = Yup.object({});

const DrawForm = withFormik<drawFormProps, IFormDrawValues>({
  mapPropsToValues: ({ parameters: { enable } }) => {
    return {
      isEnable: enable,
    };
  },
  validationSchema: validationSchema,
  handleSubmit: (values: IFormDrawValues, { setSubmitting, props }) => {
    setSubmitting(false);
    props.handleClick();

    const parameters: drawFormProps['parameters'] = {
      enable: values.isEnable,
    };

    props.dispatch(fetchUpdateSettings(props.name, parameters));
  },
})(InnerForm);

export default DrawForm;
