import React from 'react';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import MoreIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles, ThemeOptions } from '@material-ui/core/styles';
import Avatar, { ConfigProvider } from 'react-avatar';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { switchDarkMode } from '../../../store/actions/theme.actions';
import MobileMenuList from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { root } from '../../../api/config';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PieChartIcon from '@material-ui/icons/PieChart';
import countUnreadNotifications from '../../../utils/count-unread-notifications';
import { INotification } from '../../../interfaces/INotification';
import { NavLink } from 'react-router-dom';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

interface HeaderBarProps {
  onMailListOpen: (evt: any) => void;
  onNotificationListOpen: (evt: any) => void;
  onAccountListOpen: (evt: any) => void;
  onMobileMenuOpen: (evt: any) => void;
  mobileMenuAnchorEl: null | Element;
  allDropdownsClose: () => void;
}

const useStyles = makeStyles(
  (theme): ThemeOptions => ({
    sectionDesktop: {
      color: '#fff',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
      },
      margin: 0,
    },
    sectionMobile: {
      'color': '#fff',
      'display': 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      '@media (max-width: 475px)': {
        marginTop: '6px',
      },
    },
    nightMode: {
      color: (isDark: boolean) => (isDark ? 'black' : 'white'),
    },
    imgAvatar: {
      display: 'block',
    },
    menuButton: {
      'color': '#fff',
      '@media (max-width: 475px)': {
        padding: '6px',
      },
    },
  })
);

interface BadgeButtonProps {
  icon: React.ReactNode;
  nmb: number | null;
  ariaName: string;
}

function BadgeButton({ icon, nmb, ariaName }: BadgeButtonProps) {
  const label = nmb ? `show ${nmb} new ${ariaName}` : 'icon';

  return (
    <IconButton aria-label={label} color="inherit">
      {typeof nmb === 'number' ? (
        <Badge overlap="rectangular" badgeContent={nmb} color="secondary">
          {icon}
        </Badge>
      ) : (
        icon
      )}
    </IconButton>
  );
}

const getUnreadMessageNum = (notifications: INotification[], userId?: number) =>
  userId ? countUnreadNotifications(notifications, userId) : 0;

const AppBarMenu: React.FC<HeaderBarProps> = (props) => {
  const {
    onMailListOpen,
    onNotificationListOpen,
    onAccountListOpen,
    onMobileMenuOpen,
    mobileMenuAnchorEl,
    allDropdownsClose,
  } = props;

  const isDark = useSelector((state: RootState) => state.theme.darkMode);
  const { user, avatarLink } = useSelector((state: RootState) => state.user);
  const classes = useStyles(isDark);
  const userAvatar = user ? (!avatarLink ? null : user?.avatar?.name) : null;
  const avatarSize = '30';

  const dispatch: AppDispatch = useDispatch();
  const themeToggle = () => dispatch(switchDarkMode());

  const { notifications } = useSelector((state: RootState) => state.notifications);

  const unreadMessageNum = getUnreadMessageNum(notifications, user?.id);
  const redirectToRoute = (route) => {
    window.location.href = route;
  };
  const mobileMenuList = [
    {
      ariaName: 'Інвойси',
      nmb: null,
      icon: <AssignmentTurnedInIcon fontSize="medium" />,
      msg: 'Інвойси',
      onClick: () => redirectToRoute('/invoices'),
    },
    {
      ariaName: 'Повідомлення',
      nmb: 4,
      icon: <MailIcon />,
      msg: 'Повідомлення',
      onClick: onMailListOpen,
    },
    {
      ariaName: 'Сповіщення',
      nmb: unreadMessageNum,
      icon: <NotificationsIcon />,
      msg: 'Сповіщення',
      onClick: onNotificationListOpen,
    },
    {
      ariaName: 'Контакти',
      nmb: null,
      icon: <QuestionAnswerIcon />,
      msg: 'Контакти',
      onClick: () => {},
    },
    {
      ariaName: 'Аналітка',
      nmb: null,
      icon: <PieChartIcon />,
      msg: 'Аналітка',
      onClick: () => {},
    },
  ];
  const item = { pageURL: '/invoices', title: 'Інвойси' };
  return (
    <>
      <div className={classes.sectionDesktop}>
        <NavLink
          to={item.pageURL}
          key={item.pageURL}
          style={{ fontSize: '1rem' }}
          className={classes.menuButton}
        >
          <AssignmentTurnedInIcon fontSize="medium" /> {item.title}
        </NavLink>
        <IconButton color="inherit" onClick={themeToggle} className={classes.menuButton}>
          <Brightness2Icon className={classes.nightMode} />
        </IconButton>
        <IconButton color="inherit" onClick={onMailListOpen}>
          <Badge overlap="rectangular" color="primary">
            <MailIcon />
          </Badge>
        </IconButton>
        <IconButton color="inherit" onClick={onNotificationListOpen}>
          <Badge overlap="rectangular" color="error" badgeContent={unreadMessageNum}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          onClick={onAccountListOpen}
          color="inherit"
          style={{ margin: '0', padding: '6px', width: '48px' }}
        >
          {!user ? (
            <AccountCircleIcon />
          ) : userAvatar ? (
            <Avatar
              src={`${root}/static/uploads/avatar/${userAvatar}`}
              alt="avatar"
              round={true}
              size={avatarSize}
              className={classes.imgAvatar}
            />
          ) : (
            <ConfigProvider avatarRedirectUrl="https://avatar-redirect.appspot.com">
              <Avatar
                googleId={user?.googleId}
                facebookId={user?.facebookId}
                name={`${user.lastName} ${user.firstName}`}
                round={true}
                alt="avatar"
                size={avatarSize}
              />
            </ConfigProvider>
          )}
        </IconButton>
      </div>

      <div className={classes.sectionMobile}>
        <IconButton color="inherit" onClick={themeToggle} className={classes.menuButton}>
          <Brightness2Icon className={classes.nightMode} />
        </IconButton>
        <IconButton
          aria-label="show more"
          aria-haspopup="true"
          onClick={onMobileMenuOpen}
          color="inherit"
          className={classes.menuButton}
        >
          <MoreIcon />
        </IconButton>
      </div>

      <MobileMenuList
        anchorEl={mobileMenuAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!mobileMenuAnchorEl}
        onClose={allDropdownsClose}
      >
        {mobileMenuList.map(({ ariaName, nmb, icon, msg, onClick }) => (
          <MenuItem key={msg} onClick={onClick}>
            <BadgeButton ariaName={ariaName} nmb={nmb} icon={icon} />
            <p>{msg}</p>
          </MenuItem>
        ))}
      </MobileMenuList>
    </>
  );
};

export default AppBarMenu;
