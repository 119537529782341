import { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { getInvoicesListRequest, setInvoicesFirstRender } from '../store/actions/invoice.actions';
import { AppDispatch, RootState } from '../store/store';

const useInvoices = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { page, limit, sort, sortDirect } = useSelector((state: RootState) => state.invoice);

  useEffect(() => {
    const parsed = queryString.parse(location.search) as QueryTypes;
    const actualPage = Number(parsed.page) || page || 1;
    const actualLimit = Number(parsed.limit) || limit || 5;
    const actualSort = parsed.sort || sort || 'createdAt';
    const actualSortDirect = parsed.sortDirect || sortDirect || 'desc';

    dispatch(getInvoicesListRequest(actualPage, actualLimit, actualSort, actualSortDirect));
    return () => {
      dispatch(setInvoicesFirstRender(true));
    };
  }, []);

  useLayoutEffect(() => {
    const querySearch = {} as QueryTypes;
    if (!!page && page !== 1) querySearch.page = page;
    if (!!limit && limit !== 5) querySearch.limit = limit;
    if (!!sort && sort !== 'createdAt') querySearch.sort = sort;
    if (!!sortDirect && sortDirect !== 'asc') querySearch.sortDirect = sortDirect;

    history.push({
      pathname: '/invoices',
      search: queryString.stringify(querySearch),
      state: { update: true },
    });
  }, [page, limit, sort, sortDirect]);

  return { dispatch };
};

export default useInvoices;

type QueryTypes = {
  page?: string | number;
  limit?: string | number;
  sort?: string;
  sortDirect?: string;
};
