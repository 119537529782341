import React, { forwardRef, useState } from 'react';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

import { Button, makeStyles } from '@material-ui/core';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './customPickerStyle.scss';
import { COLORS } from '../../values/colors';

import uk from 'date-fns/locale/uk';

registerLocale('uk', uk);

enum datePosition {
  start = 'start',
  stop = 'stop',
}

const useStyles = makeStyles({
  'dateSections': {
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
  },
  'datePickers': {
    'display': 'flex',
    'width': 'fit-content',
    'margin': '15px auto 0 auto',
    'gap': '15px',
    '& > div': {
      marginRight: '0',
    },
    '& .react-datepicker__day--selected ': {
      'background-color': COLORS.primaryGreen,
    },
  },
  'datePickersDark': {
    'display': 'flex',
    'width': 'fit-content',
    'margin': '15px auto 0 auto',
    'gap': '15px',
    '& > div': {
      'marginRight': '0',
    },
    '& .react-datepicker__header': {
    'background-color': COLORS.darkGreen
    },
    '& .react-datepicker__month-container': {
      'background-color': COLORS.secondaryDarkGray,
    },
    '& .react-datepicker__day--selected ': {
      'background-color': COLORS.darkGreen,
    },
  },
  'buttonItem': {
    'width': '100%',
    'backgroundColor': COLORS.primaryGreen,
    'borderRadius': '30px',
    '&:hover': {
      backgroundColor: COLORS.secondaryGreen,
    },
  },
  'buttonItemDark': {
    'backgroundColor': COLORS.darkGreen,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkGreen,
    },
  },
  'button': {
    'width': '120px',
    'height': '37px',
    'marginTop': '15px',
    'marginLeft': '15px',
    'border': 'none',
    'color': COLORS.primaryLight,
  },
  'buttonsSection': {
    width: '100%',
  },
  'buttonsList': {
    listStyle: 'none',
    display: 'flex',
    gap: '15px',
    width: 'fit-content',
    margin: '30px auto 0 auto',
    padding: '0',
  },
  '@media(max-width: 475px)': {
    buttonsList: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },

  'selectSection': {
    'outline': `1px solid ${COLORS.primaryGreen}`,
    'backgroundColor': COLORS.primaryGreen,
    'border': 'none',
    'borderRadius': '30px',
    'fontSize': '0.875rem',
    'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif',
    'fontWeight': 500,
    'lineHeight': '1.75',
    'letterSpacing': '0.02857em',
    'padding': '6px 16px',
    'color': COLORS.primaryLight,
    'boxShadow':
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: COLORS.secondaryGreen,
    },
  },
  'selectSectionDark': {
    'outline': `1px solid ${COLORS.darkGreen}`,
    'backgroundColor': COLORS.darkGreen,
    '&:hover': {
      'backgroundColor': COLORS.secondaryDarkGreen,
    },
  },
  'autocomplete': {
    width: '250px',
    marginTop: '15px',
    marginRight: '7px',
    animation: '$AppearanceThree 1s',
    position: 'absolute',
    transform: 'translate(-20%, 5%)',
  },
  '@keyframes AppearanceThree': {
    '0%': {
      opacity: 0,
      transform: 'translate(0% , -130%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(-20% , 5%)',
    },
  },
});

interface IDrawsButtons {
  enterStartDate: (data) => void;
  enterEndDate: (data) => void;
}

const DrawsButtons: React.FC<IDrawsButtons> = ({ enterStartDate, enterEndDate }) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const styles = useStyles();


  const onDateChangeHandler = (date: any, position: string) => {
    position === datePosition.start ? setStartDate(date) : setEndDate(date);
  };

  const setDates = () => {
    // const formatedStartDate = startDate!.toISOString().split('T')[0];
    // const formatedEndDate = endDate!.toISOString().split('T')[0];
    enterStartDate(startDate) //start :2024-04-01
    enterEndDate(endDate)    //end :2024-04-14
  }

  const ExampleCustomInput = forwardRef<HTMLButtonElement, { value: any; onClick: any }>(
    ({ value, onClick }, ref) => (
      <button
        className={`${styles.selectSection} ${darkMode && styles.selectSectionDark}`}
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
    )
  );

  return (
    <div className={styles.dateSections}>
      <div className={darkMode ? styles.datePickersDark : styles.datePickers}>
        <DatePicker
          locale="uk"
          selected={startDate}
          onChange={(date) => onDateChangeHandler(date, datePosition.start)}
          customInput={<ExampleCustomInput value={undefined} onClick={undefined} />}
          dateFormat="dd.MM.yyyy"
          maxDate={new Date() || endDate}
        />
        <DatePicker
          locale="uk"
          selected={endDate}
          onChange={(date) => onDateChangeHandler(date, datePosition.stop)}
          customInput={<ExampleCustomInput value={undefined} onClick={undefined} />}
          dateFormat="dd.MM.yyyy"
          minDate={startDate}
          maxDate={new Date()}
        />
      </div>
        <Button 
          className={`${styles.buttonItem} ${styles.button} ${darkMode && styles.buttonItemDark}`}
          variant="contained"
          color="primary"
          type="button"
          onClick={setDates}
          >Пошук
        </Button>
    </div>
  );
};


export default DrawsButtons;
