import React from 'react';
import style from './Preloader.module.css';
import Loading from './loading';

const Preloader: React.FC = () => {
    return (
        <div className={style.preloader}>
          <Loading/>
        </div>
    )
}

export default Preloader;