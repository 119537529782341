import { api } from '../../../api/api';

export async function apiGetFeedbacks(page: number, limit: number, sort: string, sortDirect: string) {
  const feedbacks = await api.feedbacks.get(page, limit, sort, sortDirect);
  return feedbacks.data;
}

export async function apiDeleteFeedback({ id, page, limit, sort, sortDirect }) {
  return await api.feedbacks.delete(id, page, limit, sort, sortDirect);
}
