import { put, call, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { IActions } from '../../interfaces/actions';
import {
  apiGetProducts,
  apiGetProductById,
  apiAddProduct,
  apiUpdateProduct,
  apiDeleteProduct,
  apiUploadMainImg,
  apiUploadImages,
  apiDeleteImg,
  apiUpdateProductCharValues,
  apiAddProductCharValues,
  apiUpdateAvailabilityProduct,
  disableProduct,
  getProductsSizes,
  apiUpdateProductDiscountedPrice,
  getProductStatistic,
  getDiscountInfo,
  getShopList,
  apiUpdateImagesOrder,
  getPriceRange,
} from './services/products.service';
import {
  addProductError,
  addProductSuccess,
  deleteProductError,
  deleteProductSuccess,
  getProductByIdError,
  getProductByIdSuccess,
  getProductsError,
  getProductsSuccess,
  updateProductError,
  updateProductSuccess,
  uploadMainImgError,
  uploadMainImgSuccess,
  updateAvailabilityProductError,
  updateAvailabilityProductSuccess,
  disableProductSuccess,
  disableProductError,
  getProductsSizesSuccess,
  getProductsSizesError,
  updateProductDiscountedPriceError,
  updateProductDiscountedPriceSuccess,
  getProductStatisticSuccess,
  getProductStatisticError,
  getDiscountInfoSuccess,
  getDiscountInfoError,
  getShopListSuccess,
  getShopListError,
  getPriceRangeSuccess,
  getPriceRangeError,
} from '../actions/products.actions';
import { failSnackBar, successSnackBar } from '../actions/snackbar.actions';
import { cleanObject } from '../../utils/cleanObject';

export function* getProductsWorker({
  data: { page, limit, sort, sortDirect, filter },
}: IActions): SagaIterator {
  try {
    const products = yield call(apiGetProducts, page, limit, sort, sortDirect, filter);
    yield put(getProductsSuccess(products));
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(getProductsError(error.message));
  }
}

export function* getProductByIdWorker({ data: id }: IActions): SagaIterator {
  try {
    const product = yield call(apiGetProductById, id);
    yield put(getProductByIdSuccess(product));
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(getProductByIdError(error.message));
  }
}

export function* addProductWorker({
  data: { productValues, userId, characteristicValues },
}: IActions): SagaIterator {
  try {
    const { name, price, description, categoryName, categoryId, key, files, nameInProvider } = productValues;
    const product = yield call(
      apiAddProduct,
      {
        name,
        price,
        description,
        categoryName,
        categoryId,
        key,
        nameInProvider,
      },
      userId
    );

    if (product && files instanceof FormData) {
      files.append('productId', product.id);
      yield call(apiUploadImages, files);
    }

    if (product && characteristicValues) {
      yield call(apiAddProductCharValues, {
        productId: product.id,
        characteristicValues,
      });
    }
    const updatedProduct = yield call(apiGetProductById, product.id);

    yield put(addProductSuccess(updatedProduct));
    yield put(successSnackBar());
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(addProductError(error.message));
  }
}

export function* uploadMainImgWorker({ data }: IActions): SagaIterator {
  try {
    yield call(apiUploadMainImg, data);

    const updatedProduct = yield call(apiGetProductById, data.productId);

    yield put(uploadMainImgSuccess(updatedProduct));
    yield put(successSnackBar());
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(uploadMainImgError(error.message));
  }
}

export function* updateProductWorker({
  data: {
    id,
    userId,
    productValues,
    characteristicValues: { charsToAdd, charsToEdit, charsToDelete },
    imagesToDelete,
    imagesToReorder,
  },
}: IActions): SagaIterator<void> {
  try {
    const { categoryId, name, price, description, key, files } = productValues;
    const editedProduct = yield call(
      apiUpdateProduct,
      {
        id,
        categoryId,
        name,
        price,
        description,
        key,
      },
      userId
    );

    if (editedProduct && files instanceof FormData) {
      files.append('productId', editedProduct.id);
      yield call(apiUploadImages, files);
    }

    if (charsToAdd.length) {
      yield call(apiAddProductCharValues, {
        productId: id,
        characteristicValues: charsToAdd,
      });
    }

    if (charsToEdit.length) {
      yield call(apiUpdateProductCharValues, {
        productId: id,
        characteristicValues: charsToEdit,
      });
    }

    if (imagesToDelete.length) {
      yield all(imagesToDelete.map((img) => call(apiDeleteImg, img)));
    }

    if (imagesToReorder?.length) {
      yield call(apiUpdateImagesOrder, imagesToReorder);
    }

    const updatedProduct = yield call(apiGetProductById, editedProduct.id);

    yield put(updateProductSuccess(updatedProduct));
    yield put(successSnackBar());
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(updateProductError(error.message));
  }
}

export function* deleteProductWorker({ data: product }: IActions): SagaIterator {
  try {
    const { message } = yield call(apiDeleteProduct, product.id);
    if (message === 'Товар НЕ видалений, бо він є в замовленні. Але тепер він DISABLED') {
      yield put(failSnackBar(message));
      yield put(deleteProductError(message));
    } else {
      yield put(deleteProductSuccess(product.id));
      yield put(successSnackBar(message));
    }
  } catch (error: any) {
    console.log(error);
    yield put(failSnackBar(error.message));
    yield put(deleteProductError(error.message));
  }
}

export function* updateAvailabilityProductWorker({ data }: IActions): SagaIterator {
  try {
    yield call(apiUpdateAvailabilityProduct, data);
    yield put(updateAvailabilityProductSuccess(data));
    yield put(successSnackBar());
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(updateAvailabilityProductError(error.message));
  }
}

export function* disableProductWorker({ data }: IActions): SagaIterator {
  try {
    const newProduct = yield call(disableProduct, data);
    yield put(disableProductSuccess(newProduct));
    yield put(successSnackBar());
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(disableProductError(error.message));
  }
}

export function* updateDiscountedPriceWorker({ data }: IActions): SagaIterator {
  try {
    const newProduct = yield call(apiUpdateProductDiscountedPrice, data);
    yield put(updateProductDiscountedPriceSuccess(newProduct));
    yield put(successSnackBar());
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(updateProductDiscountedPriceError(error.message));
  }
}

export function* getProductsSizesWorker(): SagaIterator {
  try {
    const productSizes = yield call(getProductsSizes);
    yield put(getProductsSizesSuccess(productSizes));
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(getProductsSizesError(error.message));
  }
}

export function* getProductStatisticWorker({ data }: IActions): SagaIterator {
  try {
    const productStatistic = yield call(getProductStatistic, data);
    yield put(getProductStatisticSuccess(productStatistic));
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(getProductStatisticError(error.message));
  }
}

export function* getDiscountInfoWorker(): SagaIterator {
  try {
    const discountInfo = yield call(getDiscountInfo);
    yield put(getDiscountInfoSuccess(discountInfo));
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(getDiscountInfoError(error.message));
  }
}

export function* getShopListWorker(): SagaIterator {
  try {
    const shops = yield call(getShopList);
    yield put(getShopListSuccess(shops));
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(getShopListError(error.message));
  }
}

export function* getPriceRangeWorker({ data }): SagaIterator {
  try {
    const filter = cleanObject(data);
    const renameFilter = Object.fromEntries(
      Object.entries(filter).map(([key, value]) => [`filter${key[0].toUpperCase()}${key.slice(1)}`, value])
    );

    const priceRange = yield call(getPriceRange, renameFilter);
    yield put(getPriceRangeSuccess(priceRange));
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(getPriceRangeError(error.message));
  }
}
