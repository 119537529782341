import * as Yup from 'yup';

export const productValidationShema = (discountedPrice: any = 0) => {
  return Yup.object().shape({
    name: Yup.string().trim().min(2, 'Мінімальна довжина 2 символа').required('Обов`язкове поле'),
    price: Yup.number()
      .positive('Число повинно бути більше нуля')
      .nullable()
      .min(discountedPrice, `Ціна повинна бути більшою за акційну ціну ${discountedPrice}`)
      .required('Обов`язкове поле'),
    key: Yup.string()
      .trim()
      .min(2, 'Мінімальна довжина 2 символа')
      .matches(/(^[A-Za-z0-9-_]+$)/, 'Може містити латинські літери, цифри (0-9), знак тире (-) та (_)')
      .required('Обов`язкове поле'),
  });
};

export const formatKey = (string: string) => string.split(/\W/ || ' ').join('-');
