import React, { useContext } from 'react';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from '../../values/colors';
import { LabelEnum } from './labelEnum';
import { StatisticActionContext } from '../../context/staistic-action-context';

const useStyles = makeStyles({
  tooltip: {
    borderRadius: '4px',
    backgroundColor: COLORS.primaryLight,
    color: COLORS.secondaryDarkGray,
    padding: '16px',
    boxShadow: '15px 30px 40px 5px rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
  },
});

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  const styles = useStyles();

  const { action } = useContext(StatisticActionContext);

  if (active && payload) {
    return (
      <div className={styles.tooltip}>
        <h4>{label.split('-')[0]}</h4>

        {(action === LabelEnum.user || action === LabelEnum.comments) && (
          <p>
            {payload[0]?.value}{' '}
            {payload[0]?.payload.label === LabelEnum.user ? 'зареєстрованих' : 'коментарі(ів)'}
          </p>
        )}
        {action === LabelEnum.orders && (
          <>
            <p>{payload[0]?.value} оплачених замовлень</p>
            <p>на суму {payload[0]?.payload.sumValue} грн</p>

            <p>{payload[1]?.value} неоплачених замовлень</p>
            <p>на суму {payload[1]?.payload.sumValueSecond} грн</p>
          </>
        )}
        {action === LabelEnum.status && (
          <>
            <p>{payload[0]?.value} завершених замовлень</p>
            <p>на суму {payload[0]?.payload.sumValue} грн</p>

            <p>{payload[1]?.value} скасованих замовлень</p>
            <p>на суму {payload[1]?.payload.sumValueSecond} грн</p>
          </>
        )}
        {action === LabelEnum.slides && (
          <>
            {payload[0].payload.id ? <p>Id: {payload[0].payload.id}</p> : null}
            <p>{payload[0].value} переходів по банерам</p>
            {payload[0].payload.slides &&
              payload[0].payload.slides.map((item, i) => {
                if (i > 4) return null;
                return (
                  <p>
                    {item.visitors} переходів по слайду з Id {item.id}
                  </p>
                );
              })}
          </>
        )}
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
