import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { FC, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import styles from './AvatarEditModal.module.scss';

interface IAvatarEditModalProps {
  isAvatarEditModalOpen: boolean;
  setIsAvatarEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  previewImage: any;
  saveAvatar: (avatar: FormData) => void;
}

const AvatarEditModal: FC<IAvatarEditModalProps> = ({
  isAvatarEditModalOpen,
  setIsAvatarEditModalOpen,
  previewImage,
  saveAvatar,
}) => {
  const [selectedRegion, setSelectedRegion] = useState<any>(null);

  const editorRef = useRef<AvatarEditor | null>(null);

  const onSave = async () => {
    if (previewImage && editorRef.current) {
      const editor = editorRef.current;

      const canvas = editor.getImageScaledToCanvas();

      const context = canvas.getContext('2d');

      const x = selectedRegion?.x || 0.5;
      const y = selectedRegion?.y || 0.5;
      const width = editor.getImageScaledToCanvas().width;
      const height = editor.getImageScaledToCanvas().height;

      const imageData: ImageData | null = context?.getImageData(x, y, width, height) || null;

      if (imageData) {
        const croppedCanvas = document.createElement('canvas');
        croppedCanvas.width = width;
        croppedCanvas.height = height;

        const croppedContext = croppedCanvas.getContext('2d');

        if (croppedContext) {
          croppedContext.putImageData(imageData, 0, 0);

          const croppedImage = croppedCanvas.toDataURL();
          const response = await fetch(croppedImage);
          const blob = await response.blob();

          const file = new File([blob], 'avatar.png', { type: 'image/png' });

          const formData = new FormData();
          formData.append('image', file);

          saveAvatar(formData);
          setSelectedRegion(null);
        }
      }
    }
  };

  return (
    <Dialog
      open={isAvatarEditModalOpen}
      onClose={() => setIsAvatarEditModalOpen(false)}
      aria-labelledby="avatar-edit-modal"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="avatar-edit-modal">Виберіть область зображення на аватар</DialogTitle>
      <DialogContent>
        <div className={styles['avatar-inside']}>
          <AvatarEditor
            ref={editorRef}
            image={previewImage}
            width={250}
            height={250}
            borderRadius={125}
            color={[154, 160, 166, 0.6]}
            scale={1}
            rotate={0}
            position={selectedRegion}
            onPositionChange={(position) => setSelectedRegion(position)}
          />
          <div className={styles['change-btns']}>
            <Button color="primary" onClick={onSave}>
              Зберегти
            </Button>
            <Button color="secondary" onClick={() => setIsAvatarEditModalOpen(false)}>
              Скасувати
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AvatarEditModal;
