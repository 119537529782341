import React from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Sidebar from '../Sidebar/Sidebar';
import SnackBar from '../Common/SnackBar';
import HeaderBar from '../HeaderBar/HeaderBar';
import Content from '../Content/Content';
import styles from './Router.module.scss';
import { RootState } from '../../store/store';
import WithAxios from '../../api/withAxios';
import RouteSwitch from './RouteSwitch';
import Error from '../../pages/Error/Error';

const Router: React.FC = () => {
  const [isOpenSidebar, setOpenSidebar] = React.useState(true);
  const toggleSidebar = () => setOpenSidebar(!isOpenSidebar);
  const location = useLocation();

  const user = useSelector<RootState>((state) => state.user.user);

  return (
    <BrowserRouter>
      <WithAxios>
        {location.pathname === '/error' ? (
          <Route path="/error" component={Error} />
        ) : (
          <>
            <Route exact path="/">
              {user ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
            </Route>
            <Route exact path="/login">
              {user ? <Redirect to="/dashboard" /> : null}
            </Route>

            {user ? (
              <div className={styles.container}>
                <Sidebar isOpen={isOpenSidebar} onSidebarToggle={toggleSidebar} />
                <div className={isOpenSidebar ? styles.main : styles['main-expanded']}>
                  <HeaderBar onSidebarToggle={toggleSidebar} isShrink={isOpenSidebar} />
                  <Content>
                    <RouteSwitch />
                  </Content>
                </div>
              </div>
            ) : (
              <RouteSwitch />
            )}
          </>
        )}
        <SnackBar />
      </WithAxios>
    </BrowserRouter>
  );
};

export default Router;
