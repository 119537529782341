import { Button, Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { COLORS } from '../../../values/colors';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';

const useStyles = makeStyles({
  'formWrapper': {
    'display': 'flex',
    'flexDirection': 'column',
    'textAlign': 'center',
    'justifyContent': 'center',
  },
  'title': {
    'margin': '0 auto'
  },
  'buttonItem': {
    'marginTop': '200px',
    'width': '100%',
    'backgroundColor': COLORS.primaryGreen,
    'color': '#fff',
    'borderRadius': '30px',
    '&:hover': {
      backgroundColor: COLORS.secondaryGreen,
    },
  },
  'buttonItemDark': {
    'backgroundColor': COLORS.darkGreen,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkGreen,
    },
  },
  'button': {
    'margin': '0 auto',
    'width': '150px',
    'marginTop': '300px',
    'alignItems': 'center'
  },
  'label': {
    'marginBottom': '5px',
    'fontSize': '16px'
  },
  'datePickers': {
    'display': 'flex',
    'width': 'fit-content',
    'margin': '15px auto 0 auto',
    'gap': '15px',
    '& > div': {
      marginRight: '0',
    },
    '& .react-datepicker__day--selected ': {
      'background-color': COLORS.primaryGreen,
    },
  },
  'datePickersDark': {
    'display': 'flex',
    'width': 'fit-content',
    'margin': '15px auto 0 auto',
    'gap': '15px',
    '& > div': {
      'marginRight': '0',
    },
    '& .react-datepicker__header': {
      'background-color': COLORS.darkGreen
    },
    '& .react-datepicker__month-container': {
      'background-color': COLORS.secondaryDarkGray,
    },
    '& .react-datepicker__day--selected ': {
      'background-color': COLORS.darkGreen,
    },
  },
  'selectSection': {
    'outline': `1px solid ${COLORS.primaryGreen}`,
    'backgroundColor': COLORS.primaryGreen,
    'border': 'none',
    'borderRadius': '30px',
    'fontSize': '0.875rem',
    'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif',
    'fontWeight': 500,
    'lineHeight': '1.75',
    'letterSpacing': '0.02857em',
    'padding': '6px 16px',
    'color': COLORS.primaryLight,
    'boxShadow':
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: COLORS.secondaryGreen,
    },
  },
  'selectSectionDark': {
    'outline': `1px solid ${COLORS.darkGreen}`,
    'backgroundColor': COLORS.darkGreen,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkGreen,
    },
  },
})

const DateSelectionModal = ({ open, onClose, setCustomDate }) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const styles = useStyles();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const onSubmitHandle = (e) => {
    e.preventDefault();
    const startPeriod = DateTime.fromFormat(e.currentTarget.startDate.value, 'MM/dd/yyyy').toFormat('yyyy-MM-dd');
    const endPeriod = DateTime.fromFormat(e.currentTarget.endDate.value, 'MM/dd/yyyy').toFormat('yyyy-MM-dd');
    setCustomDate(startPeriod, endPeriod);
    onClose();
  };


  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="modal-title" className={styles.title}>Визначити період</DialogTitle>
      <DialogContent dividers>
        <form className={styles.formWrapper} onSubmit={e => onSubmitHandle(e)}>
          <div className={darkMode ? styles.datePickersDark : styles.datePickers}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              name='startDate'
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              name='endDate'
            />
          </div>
          <Button
            className={`${styles.buttonItem} ${styles.button} ${darkMode && styles.buttonItemDark}`}
            variant="contained"
            color="primary"
            type="submit"
          >
            Застосувати
          </Button>
        </form>
      </DialogContent>

    </Dialog>
  );
};

export default DateSelectionModal;