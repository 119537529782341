import { FormControl, Select, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import CancelIcon from '@material-ui/icons/Cancel';
import { failSnackBar } from '../../../store/actions/snackbar.actions';

interface OrdersSizeSelectorProps {
  disabled: boolean;
  value: string | null;
  handleChange: (e: any) => void;
  menuItems?: Array<string>;
}

function getIsEditable(loading: boolean, setEditable: React.Dispatch<React.SetStateAction<boolean>>) {
  return () => {
    if (loading) {
      return;
    }
    setEditable(true);
  };
}

function getOnQuantityChangeCancel(setEditable: React.Dispatch<React.SetStateAction<boolean>>) {
  return () => {
    setEditable(false);
  };
}

interface SizeDontExistProps {
  menuItems: string[];
  setEditable: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  value: string;
}

const SizeDontExist = ({ menuItems, loading, setEditable, value }: SizeDontExistProps) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(failSnackBar('Оберіть будь ласка розмір.'));
  }, [dispatch]);

  const isEditable = getIsEditable(loading, setEditable);
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70%', gap: '4px' }}
    >
      <h5 style={{ color: 'red', margin: 0 }}>{value}</h5>
      {menuItems.length > 0 && <EditIcon onClick={isEditable} fontSize="small" />}
    </div>
  );
};

interface EditableSizeProps {
  value: string;
  handleChange: (e: any) => void;
  disabled: boolean;
  isSizeExist: boolean;
  menuItems: string[];
  setEditable: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditableSize = ({
  value,
  disabled,
  menuItems,
  setEditable,
  handleChange,
  isSizeExist,
}: EditableSizeProps) => {
  const onQuantityChangedCancel = getOnQuantityChangeCancel(setEditable);

  return (
    <FormControl
      disabled={disabled}
      style={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'row' }}
    >
      <Select value={value} label={value} onChange={handleChange}>
        {menuItems.map((item, i) => {
          return (
            <MenuItem key={i + `${item}`} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
      {!isSizeExist && <CancelIcon onClick={onQuantityChangedCancel} />}
    </FormControl>
  );
};

const OrdersSizeSelector = ({ value, disabled, handleChange, menuItems }: OrdersSizeSelectorProps) => {
  const loading = useSelector((state: RootState) => state.orders.loading);
  const [editable, setEditable] = useState<boolean>(false);
  const isValueTypeString = typeof value === 'string';

  if (!isValueTypeString) {
    return null;
  }

  value = value ?? '';

  if (!menuItems) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70%' }}>
        <h5 style={{ margin: '0', fontWeight: 'normal' }}>{value}</h5>
      </div>
    );
  }

  const isSizeExist = menuItems.includes(value);
  if (!isSizeExist && !editable) {
    return <SizeDontExist menuItems={menuItems} loading={loading} setEditable={setEditable} value={value} />;
  }

  if (editable || isSizeExist) {
    return (
      <EditableSize
        value={value}
        handleChange={handleChange}
        disabled={disabled}
        isSizeExist={isSizeExist}
        menuItems={menuItems}
        setEditable={setEditable}
      />
    );
  }

  return null;
};

export default OrdersSizeSelector;
