import { withFormik } from 'formik';
import { Dispatch } from 'redux';
import * as Yup from 'yup';

import InnerForm from './Inner-form';
import { IFormAutoStartParsersValues } from '../../../../interfaces/widget-form';
import { fetchUpdateSettings } from '../../../../store/actions/settings.actions';

interface autoStartParsersFormProps {
  dispatch: Dispatch;
  handleClick: () => void;
  parameters: {
    feed: boolean;
    letsShop: boolean;
    whiteMandarin: boolean;
    pobedov: boolean;
    fashionGirl: boolean;
    sewel: boolean;
    toysi: boolean;
  };
  name: string;
}

const validationSchema = Yup.object({});

const AutoStartParsersForm = withFormik<autoStartParsersFormProps, IFormAutoStartParsersValues>({
  mapPropsToValues: ({
    parameters: { feed, letsShop, whiteMandarin, pobedov, fashionGirl, sewel, toysi },
  }) => {
    return {
      feed: feed,
      letsShop: letsShop,
      whiteMandarin: whiteMandarin,
      pobedov: pobedov,
      fashionGirl: fashionGirl,
      sewel: sewel,
      toysi: toysi,
    };
  },
  validationSchema: validationSchema,
  handleSubmit: (values: IFormAutoStartParsersValues, { setSubmitting, props }) => {
    setSubmitting(false);
    props.handleClick();

    const parameters: autoStartParsersFormProps['parameters'] = {
      feed: values.feed,
      letsShop: values.letsShop,
      whiteMandarin: values.whiteMandarin,
      pobedov: values.pobedov,
      fashionGirl: values.fashionGirl,
      sewel: values.sewel,
      toysi: values.toysi,
    };

    props.dispatch(fetchUpdateSettings(props.name, parameters));
  },
})(InnerForm);

export default AutoStartParsersForm;
