// TREE-CATEGORIES
export const GET_TREE_CATEGORIES_REQUEST = 'GET_TREE_CATEGORIES_REQUEST';
export const GET_TREE_CATEGORIES_SUCCESS = 'GET_TREE_CATEGORIES_SUCCESS';
export const GET_TREE_CATEGORIES_ERROR = 'GET_TREE_CATEGORIES_ERROR';

export const ADD_TREE_CATEGORY_REQUEST = 'ADD_TREE_CATEGORY_REQUEST';
export const ADD_TREE_CATEGORY_SUCCESS = 'ADD_TREE_CATEGORY_SUCCESS';
export const ADD_TREE_CATEGORY_ERROR = 'ADD_TREE_CATEGORY';

export const DELETE_TREE_CATEGORY = 'DELETE_TREE_CATEGORY';

export const UPDATE_TREE_CATEGORY_REQUEST = 'UPDATE_TREE_CATEGORY_REQUEST';
export const UPDATE_TREE_CATEGORY_SUCCESS = 'UPDATE_TREE_CATEGORY_SUCCESS';
export const UPDATE_TREE_CATEGORY_ERROR = 'UPDATE_TREE_CATEGORY_ERROR';

export const ADD_CHAR_TO_CATEGORY = 'ADD_CHAR_TO_CATEGORY';
export const EDIT_CHAR_TO_CATEGORY = 'EDIT_CHAR_TO_CATEGORY';
export const DELETE_CHAR_TO_CATEGORY = 'DELETE_CHAR_TO_CATEGORY';

export const GET_TREE_CATEGORIES_BY_ID_REQUEST = 'GET_TREE_CATEGORIES_BY_ID_REQUEST';
export const GET_TREE_CATEGORIES_BY_ID_SUCCESS = 'GET_TREE_CATEGORIES_BY_ID_SUCCESS';
export const GET_TREE_CATEGORIES_BY_ID_ERROR = 'GET_TREE_CATEGORIES_BY_ID_ERROR';

export const DISABLE_ENABLE_CATEGORY_SUCCESS = 'DISABLE_ENABLE_CATEGORY_SUCCESS';
export const DISABLE_ENABLE_CATEGORY_REQUEST = 'DISABLE_ENABLE_CATEGORY_REQUEST';
export const DISABLE_ENABLE_CATEGORY_ERROR = 'DISABLE_ENABLE_CATEGORY_ERROR';

export const SORT_TREE_CATEGORY_SUCCESS = 'SORT_TREE_CATEGORY_SUCCESS';
export const SORT_TREE_CATEGORY_ERROR = 'SORT_TREE_CATEGORY_ERROR';

// CATEGORY IMAGES

export const UPDATE_CATEGORY_IMAGES_REQUEST = 'UPDATE_CATEGORY_IMAGES_REQUEST';
export const UPDATE_CATEGORY_IMAGES_SUCCESS = 'UPDATE_CATEGORY_IMAGES_SUCCESS';
export const UPDATE_CATEGORY_IMAGES_ERROR = 'UPDATE_CATEGORY_IMAGES_ERROR';

export const CLEAR_UPDATE_CATEGORY_IMAGES_RESULT = 'CLEAR_UPDATE_CATEGORY_IMAGES_RESULT';

// PRODUCTS
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export const GET_PRODUCT_BY_ID_REQUEST = 'GET_PRODUCT_BY_ID_REQUEST';
export const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS';
export const GET_PRODUCT_BY_ID_ERROR = 'GET_PRODUCT_BY_ID_ERROR';

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_ERROR = 'ADD_PRODUCT_ERROR';

export const UPLOAD_MAIN_IMG_REQUEST = 'UPLOAD_MAIN_IMG_REQUEST';
export const UPLOAD_MAIN_IMG_SUCCESS = 'UPLOAD_MAIN_IMG_SUCCESS';
export const UPLOAD_MAIN_IMG_ERROR = 'UPLOAD_MAIN_IMG_ERROR';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';

export const UPDATE_AVAILABILITY_PRODUCT_REQUEST = 'UPDATE_AVAILABILITY_PRODUCT_REQUEST';
export const UPDATE_AVAILABILITY_PRODUCT_SUCCESS = 'UPDATE_AVAILABILITY_PRODUCT_SUCCESS';
export const UPDATE_AVAILABILITY_PRODUCT_ERROR = 'UPDATE_AVAILABILITY_PRODUCT_ERROR';

export const UPDATE_PRODUCT_DISCOUNTED_PRICE_REQUEST = 'UPDATE_PRODUCT_DISCOUNTED_PRICE_REQUEST';
export const UPDATE_PRODUCT_DISCOUNTED_PRICE_SUCCESS = 'UPDATE_PRODUCT_DISCOUNTED_PRICE_SUCCESS';
export const UPDATE_PRODUCT_DISCOUNTED_PRICE_ERROR = 'UPDATE_PRODUCT_DISCOUNTED_PRICE_ERROR';

export const DISABLE_PRODUCT_REQUEST = 'DISABLE_PRODUCT_REQUEST';
export const DISABLE_PRODUCT_SUCCESS = 'DISABLE_PRODUCT_SUCCESS';
export const DISABLE_PRODUCT_ERROR = 'DISABLE_PRODUCT_ERROR';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';

export const GET_PRODUCTS_SIZES_REQUEST = 'GET_PRODUCTS_SIZES_REQUEST';
export const GET_PRODUCTS_SIZES_SUCCESS = 'GET_PRODUCTS_SIZES_SUCCESS';
export const GET_PRODUCTS_SIZES_ERROR = 'GET_PRODUCTS_SIZES_ERROR';
export const SET_PRODUCT_SIZE = 'SET_PRODUCT_SIZE';
export const RESET_FILTER_VALUES = 'RESET_FILTER_VALUES';

export const GET_PRODUCT_STATISTIC_REQUEST = 'GET_PRODUCT_STATISTIC_REQUEST';
export const GET_PRODUCT_STATISTIC_SUCCESS = 'GET_PRODUCT_STATISTIC_SUCCESS';
export const GET_PRODUCT_STATISTIC_ERROR = 'GET_PRODUCT_STATISTIC_ERROR';

export const GET_DISCOUNT_INFO_REQUEST = 'GET_DISCOUNT_INFO_REQUEST';
export const GET_DISCOUNT_INFO_SUCCESS = 'GET_DISCOUNT_INFO_SUCCESS';
export const GET_DISCOUNT_INFO_ERROR = 'GET_DISCOUNT_INFO_ERROR';

export const GET_SHOP_LIST_REQUEST = 'GET_SHOP_LIST_REQUEST';
export const GET_SHOP_LIST_SUCCESS = 'GET_SHOP_LIST_SUCCESS';
export const GET_SHOP_LIST_ERROR = 'GET_SHOP_LIST_ERROR';

export const GET_PRICE_RANGE_REQUEST = 'GET_PRICE_RANGE_REQUEST';
export const GET_PRICE_RANGE_SUCCESS = 'GET_PRICE_RANGE_SUCCESS';
export const GET_PRICE_RANGE_ERROR = 'GET_PRICE_RANGE_ERROR';

export const SET_PRODUCTS_FIRST_RENDER = 'SET_PRODUCTS_FIRST_RENDER';

// USERS
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const GET_USERS_DATE_RANGE_REQUEST = 'GET_USERS_DATE_RANGE_REQUEST';
export const GET_USERS_DATE_RANGE_SUCCESS = 'GET_USERS_DATE_RANGE_SUCCESS';
export const GET_USERS_DATE_RANGE_ERROR = 'GET_USERS_DATE_RANGE_ERROR';

export const GET_USERS_ONLINE_REQUEST = 'GET_USERS_ONLINE_REQUEST';
export const GET_USERS_ONLINE_SUCCESS = 'GET_USERS_ONLINE_SUCCESS';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const UPDATE_USER_ROLE_REQUEST = 'UPDATE_USER_ROLE_REQUEST';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_ERROR = 'UPDATE_USER_ROLE_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const SET_USERS_FIRST_RENDER = 'SET_USERS_FIRST_RENDER';

// SETTINGS
export const REQUEST_SETTINGS = 'REQUEST_SETTINGS';
export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const REQUEST_UPDATE_SETTINGS = 'REQUEST_UPDATE_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const GET_PARSER_SETTINGS = 'GET_PARSER_SETTINGS';
export const GENERATE_FEED = 'GENERATE_FEED';
export const SET_FEED_PARAMETERS = 'SET_FEED_PARAMETERS';
export const SET_PARSER_SETTINGS = 'SET_PARSER_SETTINGS';
export const UPDATE_PARSER_SETTINGS = 'UPDATE_PARSER_SETTINGS';
export const REQUEST_UPDATE_PARSER_SETTINGS = 'REQUEST_UPDATE_PARSER_SETTINGS';
export const GET_CATEGORIES_WITH_PRIORITY = 'GET_CATEGORIES_WITH_PRIORITY';
export const SET_CATEGORIES_WITH_PRIORITY = 'SET_CATEGORIES_WITH_PRIORITY';
export const CHANGE_CATEGORY_PRIORITY = 'CHANGE_CATEGORY_PRIORITY';
export const SET_LOADING_SETTINGS = 'SET_LOADING_SETTINGS';

// SNACKBAR
export const SUCCESS_SNACKBAR = 'SUCCESS_SNACKBAR';
export const FAIL_SNACKBAR = 'FAIL_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

// SLIDER
export const LOAD_SLIDES = 'LOAD_SLIDES';
export const LOAD_SLIDES_ERROR = 'LOAD_SLIDES_ERROR';
export const REQUEST_SLIDES = 'REQUEST_SLIDES';

export const SET_SLIDES_FIRST_RENDER = 'SET_SLIDES_FIRST_RENDER';

export const REQUEST_ADD_SLIDES = 'REQUEST_ADD_SLIDES';
export const ADD_SLIDE = 'ADD_SLIDE';

export const REQUEST_DELETE_SLIDES = 'REQUEST_DELETE_SLIDES';
export const DELETE_SLIDE = 'DELETE_SLIDE';

export const REQUEST_UPDATE_SLIDES = 'REQUEST_UPDATE_SLIDES';
export const UPDATE_SLIDE = 'UPDATE_SLIDE';

export const REQUEST_UPDATE_SLIDE_VISIBILITY = 'REQUEST_UPDATE_SLIDE_VISIBILITY';

export const GET_SLIDES_BY_RANGE_REQUEST = 'GET_SLIDES_BY_RANGE_REQUEST';
export const GET_SLIDES_BY_RANGE_SUCCESS = 'GET_SLIDES_BY_RANGE_SUCCESS';
export const GET_SLIDES_BY_RANGE_ERROR = 'GET_SLIDES_BY_RANGE_ERROR';

//USER
export const USER_SIGN_IN_FETCHING = 'USER_SIGN_IN_FETCHING';
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS';
export const USER_SIGN_IN_ERROR = 'USER_SIGN_IN_ERROR';

export const USER_SIGN_OUT = 'USER_SIGN_OUT';

export const USER_FETCH_REQUEST = 'USER_FETCH_REQUEST';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_ERROR = 'USER_FETCH_ERROR';

export const UPDATE_PROFILE_USER_REQUEST = 'UPDATE_PROFILE_USER_REQUEST';
export const UPDATE_PROFILE_USER_SUCCESS = 'UPDATE_PROFILE_USER_SUCCESS';

export const ADD_AVATAR_REQUEST = 'ADD_AVATAR_REQUEST';
export const ADD_AVATAR_SUCCESS = 'ADD_AVATAR_SUCCESS';

export const DELETE_AVATAR_REQUEST = 'DELETE_AVATAR_REQUEST';
export const DELETE_AVATAR_SUCCESS = 'DELETE_AVATAR_SUCCESS';

export const CONFIRM_CHANGE_EMAIL_REQUEST = 'CONFIRM_CHANGE_EMAIL_REQUEST';
export const CONFIRM_CHANGE_EMAIL_ERROR = 'CONFIRM_CHANGE_EMAIL_ERROR';
export const CONFIRM_CHANGE_EMAIL_SUCCES = 'CONFIRM_CHANGE_EMAIL_SUCCES';

// THEME
export const SWITCH_DARK_MODE = 'SWITCH_MODE';

// ORDERS
export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';

export const GET_ORDER_AMOUNTS_REQUEST = 'GET_ORDER_AMOUNTS_REQUEST';
export const GET_ORDER_AMOUNTS_SUCCESS = 'GET_ORDER_AMOUNTS_SUCCESS';
export const GET_ORDER_AMOUNTS_ERROR = 'GET_ORDER_AMOUNTS_ERROR';

export const GET_ORDER_BY_ID_REQUEST = 'GET_ORDER_BY_ID_REQUEST';
export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS';
export const GET_ORDER_BY_ID_ERROR = 'GET_ORDER_BY_ID_ERROR';

export const UPDATE_ORDER_STATUS_REQUEST = 'UPDATE_ORDER_STATUS_REQUEST';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_ERROR = 'UPDATE_ORDER_STATUS_ERROR';

export const UPDATE_ORDER_PAYMENT_STATUS_REQUEST = 'UPDATE_ORDER_PAYMENT_STATUS_REQUEST';
export const UPDATE_ORDER_PAYMENT_STATUS_SUCCESS = 'UPDATE_ORDER_PAYMENT_STATUS_SUCCESS';
export const UPDATE_ORDER_PAYMENT_STATUS_ERROR = 'UPDATE_ORDER_PAYMENT_STATUS_ERROR';

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_PARCEL_NUMBER = 'UPDATE_ORDER_PARCEL_NUMBER';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR';

export const GER_ORDERS_BY_PARAMS_REQUEST = 'GER_ORDERS_BY_PARAMS_REQUEST';
export const GER_ORDERS_BY_PARAMS_SUCCESS = 'GER_ORDERS_BY_PARAMS_SUCCESS';
export const GER_ORDERS_BY_PARAMS_ERROR = 'GER_ORDERS_BY_PARAMS_ERROR';

export const GET_ORDERS_BY_RANGE_REQUEST = 'GET_ORDERS_BY_RANGE_REQUEST';
export const GET_ORDERS_STATUS_BY_RANGE_REQUEST = 'GET_ORDERS_STATUS_BY_RANGE_REQUEST';
export const GET_ORDERS_BY_RANGE_SUCCESS = 'GET_ORDERS_BY_RANGE_SUCCESS';
export const GET_ORDERS_STATUS_BY_RANGE_SUCCESS = 'GET_ORDERS_BY_RANGE_SUCCESS';
export const GET_ORDERS_BY_RANGE_ERROR = 'GET_ORDERS_BY_RANGE_ERROR';
export const GET_ORDERS_STATUS_BY_RANGE_ERROR = 'GET_ORDERS_BY_RANGE_ERROR';
export const GET_ORDERS_STATISTIC_BY_RANGE_REQUEST = 'GET_ORDERS_STATISTIC_BY_RANGE_REQUEST';
export const GET_ORDERS_STATISTIC_BY_RANGE_SUCCESS = 'GET_ORDERS_STATISTIC_BY_RANGE_SUCCESS';
export const GET_ORDERS_STATISTIC_BY_RANGE_ERROR = 'GET_ORDERS_STATISTIC_BY_RANGE_ERROR';

export const UPDATE_PRODUCT_IN_ORDER_REQUEST = 'UPDATE_PRODUCT_IN_ORDER_REQUEST';
export const UPDATE_PRODUCT_IN_ORDER_SUCCESS = 'UPDATE_PRODUCT_IN_ORDER_SUCCESS';
export const UPDATE_PRODUCT_IN_ORDER_ERROR = 'UPDATE_PRODUCT_IN_ORDER_ERROR';

export const DELETE_ORDER_BY_ID_REQUEST = 'DELETE_ORDER_BY_ID_REQUEST';
export const DELETE_ORDER_BY_ID_SUCCESS = 'DELETE_ORDER_BY_ID_SUCCESS';
export const DELETE_ORDER_BY_ID_ERROR = 'DELETE_ORDER_BY_ID_ERROR';

export const SET_ORDERS_FIRST_RENDER = 'SET_ORDERS_FIRST_RENDER';

export const SET_ORDERS_SEARCH_VALUE = 'SET_ORDERS_SEARCH_VALUE';

// COMMENTS
export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_ERROR = 'GET_COMMENTS_ERROR';

export const GET_COMMENTS_BY_RANGE_REQUEST = 'GET_COMMENTS_BY_RANGE_REQUEST';
export const GET_COMMENTS_BY_RANGE_SUCCESS = 'GET_COMMENTS_BY_RANGE_SUCCESS';
export const GET_COMMENTS_BY_RANGE_ERROR = 'GET_COMMENTS_BY_RANGE_ERROR';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_ERROR = 'DELETE_COMMENT_ERROR';

export const SET_COMMENTS_FIRST_RENDER = 'SET_COMMENTS_FIRST_RENDER';

// FEEDBACKS
export const GET_FEEDBACKS_REQUEST = 'GET_FEEDBACKS_REQUEST';
export const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS';
export const GET_FEEDBACKS_ERROR = 'GET_FEEDBACKS_ERROR';

export const SET_FEEDBACKS_FIRST_RENDER = 'SET_FEEDBACKS_FIRST_RENDER';

export const DELETE_FEEDBACK_REQUEST = 'DELETE_FEEDBACK_REQUEST';
export const DELETE_FEEDBACK_SUCCESS = 'DELETE_FEEDBACK_SUCCESS';
export const DELETE_FEEDBACK_ERROR = 'DELETE_FEEDBACK_ERROR';

// ROLES
export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

// SEARCH
export const GET_SEARCH_ITEMS_REQUEST = 'GET_SEARCH_ITEMS_REQUEST';
export const GET_SEARCH_ITEMS_SUCCESS = 'GET_SEARCH_ITEMS_SUCCESS';
export const GET_SEARCH_ITEMS_ERROR = 'GET_SEARCH_ITEMS_ERROR';

// SLIDER ANIMATIONS
export const GET_SLIDER_ANIMATIONS_REQUEST = 'GET_SLIDER_ANIMATIONS_REQUEST';
export const GET_SLIDER_ANIMATIONS = 'GET_SLIDER_ANIMATIONS';
export const GET_ACTIVE_SLIDER_ANIMATION = 'GET_ACTIVE_SLIDER_ANIMATION';
export const REQUEST_CHANGE_ACTIVE_SLIDER_ANIMATION = 'REQUEST_CHANGE_ACTIVE_SLIDER_ANIMATION';
export const CHANGE_ACTIVE_SLIDER_ANIMATION = 'CHANGE_ACTIVE_SLIDER_ANIMATION';

// INVOICE
export const GET_INVOICES_LIST_REQUEST = 'GET_INVOICES_LIST_REQUEST';
export const GET_INVOICES_LIST_SUCCESS = 'GET_INVOICES_LIST_SUCCESS';
export const GET_INVOICES_LIST_ERROR = 'GET_INVOICES_LIST_ERROR';
export const REMOVE_INVOICE_REQUEST = 'REMOVE_INVOICE_REQUEST';
export const REMOVE_INVOICE_SUCCESS = 'REMOVE_INVOICE_SUCCESS';
export const REMOVE_INVOICE_ERROR = 'REMOVE_INVOICE_ERROR';
export const GENERATE_INVOICE_REQUEST = 'GENERATE_INVOICE_REQUEST';
export const GENERATE_INVOICE_SUCCESS = 'GENERATE_INVOICE_SUCCESS';
export const GENERATE_INVOICE_ERROR = 'GENERATE_INVOICE_ERROR';

export const SET_INVOICES_FIRST_RENDER = 'SET_INVOICES_FIRST_RENDER';

//PARSERS
export const GET_PARSERS_STATUSES = 'GET_PARSERS_STATUSES';
export const SAVE_PARSERS_STATUSES = 'SAVE_PARSERS_STATUSES';
export const SET_LOADING = 'SET_LOADING';
export const CHANGE_PARSER = 'CHANGE_PARSER';
export const START_CONNECTING = 'START_CONNECTING';
export const STOP_CONNECTING = 'STOP_CONNECTING';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const START_PARSING = 'START_PARSING';
export const STOP_PARSING = 'STOP_PARSING';
export const UPDATE_ALL = 'UPDATE_ALL';
export const UPDATE_ONE = 'UPDATE_ONE';
export const SAVE_IN_PROGRESS_STATUS = 'SAVE_IN_PROGRESS_STATUS';
export const DELETE_ALL_PRODUCT_BY_KEY = 'DELETE_ALL_PRODUCT_BY_KEY';

//NOTIFICATIONS
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const GET_ALL_NOTIFICATIONS_ERROR = 'GET_ALL_NOTIFICATIONS_ERROR';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';

// DRAW
export const CREATE_DRAW_START = 'CREATE_DRAW_START';
export const CREATE_DRAW_SUCCESS = 'CREATE_DRAW_SUCCESS';
export const CREATE_DRAW_ERROR = 'CREATE_DRAW_ERROR';

export const GET_DRAW_REQUEST = 'GET_DRAW_REQUEST';
export const GET_DRAW_START = 'GET_DRAW_START';
export const GET_DRAW_SUCCESS = 'GET_DRAW_SUCCESS';
export const GET_DRAW_ERROR = 'GET_DRAW_ERROR';

export const EDIT_DRAW_START = 'EDIT_DRAW_START';
export const EDIT_DRAW_SUCCESS = 'EDIT_DRAW_SUCCESS';
export const EDIT_DRAW_ERROR = 'EDIT_DRAW_ERROR';

export const GET_ACTIVE_DRAW_START = 'GET_ACTIVE_DRAW_START';
export const GET_ACTIVE_DRAW_SUCCESS = 'GET_ACTIVE_DRAW_SUCCESS';
export const GET_ACTIVE_DRAW_ERROR = 'GET_ACTIVE_DRAW_ERROR';

export const GET_DRAW_USERS_START = 'GET_DRAW_USERS_START';
export const GET_DRAW_USERS_SUCCESS = 'GET_DRAW_USERS_SUCCESS';
export const GET_DRAW_USERS_ERROR = 'GET_DRAW_USERS_ERROR';

export const SET_DRAW_WINNER_START = 'SET_DRAW_WINNER_START';
export const SET_DRAW_WINNER_SUCCESS = 'SET_DRAW_WINNER_SUCCESS';
export const SET_DRAW_WINNER_ERROR = 'SET_DRAW_WINNER_ERROR';

export const SAVE_DRAW_START = 'SAVE_DRAW_START';
export const SAVE_DRAW_SUCCESS = 'SAVE_DRAW_SUCCESS';
export const SAVE_DRAW_ERROR = 'SAVE_DRAW_ERROR';
