import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { makeStyles, Theme, createStyles, ThemeOptions } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store/store';
import { getInvoicesListRequest } from '../../../../store/actions/invoice.actions';

interface TableFooterProps {
  rowsPerPage: number;
  page: number;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const useFooterStyles = makeStyles(
  (theme: Theme): ThemeOptions =>
    createStyles({
      root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
      },
      wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
    })
);

const InvoiceTableFooter: React.FC<TableFooterProps> = ({ rowsPerPage, page }) => {
  const classes = useFooterStyles();
  const { count, sort, sortDirect } = useSelector((state: RootState) => state.invoice);

  const dispatch: AppDispatch = useDispatch();

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newPage = Math.ceil(((page - 1) * rowsPerPage + 1) / parseInt(event.target.value, 10));
    dispatch(getInvoicesListRequest(newPage, parseInt(event.target.value, 10), sort, sortDirect));
  };

  function TablePaginationActions({ count, page, rowsPerPage: limit }: TablePaginationActionsProps) {
    const handleFirstPageButtonClick = () => {
      dispatch(getInvoicesListRequest(1, limit, sort, sortDirect));
    };

    const handleBackButtonClick = () => {
      dispatch(getInvoicesListRequest(page, limit, sort, sortDirect));
    };

    const handleNextButtonClick = () => {
      dispatch(getInvoicesListRequest(page + 2, limit, sort, sortDirect));
    };

    const handleLastPageButtonClick = () => {
      const lastPage = Math.ceil(count / limit);
      dispatch(getInvoicesListRequest(lastPage, limit, sort, sortDirect));
    };

    return (
      <div className={classes.root}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / limit) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / limit) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="nav"
        colSpan={3}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        labelRowsPerPage="Рядків на сторінці:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onPageChange={() => {}}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
};

export default InvoiceTableFooter;
