export const validate = (values: { [key: string]: string }) => {
  const filteredValues = Object.values(values).filter((value) => value);
  const uniqueValues = new Set(filteredValues);

  if (uniqueValues.size !== filteredValues.length) {
    return { _error: 'Значення повинні бути унікальними' };
  }

  const isNaNValues = filteredValues.filter((v) => isNaN(Number(v)));

  if (isNaNValues.length) {
    return { _error: 'Значення повинні бути числовими' };
  }
  return {};
};
