import React, { useLayoutEffect, useState, useEffect } from 'react';
import style from './OrderFilter.module.scss';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slider,
  Typography,
  MenuItem,
  InputLabel,
  InputAdornment,
} from '@material-ui/core';
import useModal from '../../../../hooks/useModal';
import { Field, Form, Formik } from 'formik';
import queryString from 'query-string';
import { TextField, Select } from 'formik-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../../store/store';
import { getOrdersRequest, getOrderAmountsRequest } from '../../../../store/actions/orders.actions';
import { COLORS } from '../../../../values/colors';
import classNames from 'classnames';
import { Status as enumStatus } from '../../../../enums/orderStatus';
import { PaymentStatus } from '../../../../enums/paymentStatus';
import { cleanObject } from '../../../../utils/cleanObject';
import { IOrderAmount, IOrderFilter } from '../../../../interfaces/IOrders';
import isEqual from 'lodash.isequal';
import DatePicker, { registerLocale } from 'react-datepicker';
import uk from 'date-fns/locale/uk';

registerLocale('uk', uk);

const useStyles = makeStyles({
  btn: {
    borderRadius: '30px',
    color: COLORS.primaryLight,
  },
  btnLight: {
    'backgroundColor': COLORS.primaryGreen,
    '&:hover': {
      backgroundColor: COLORS.secondaryGreen,
    },
  },
  btnDark: {
    'backgroundColor': COLORS.darkGreen,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkGreen,
    },
  },
  btnReset: {
    'backgroundColor': COLORS.primaryGray,
    '&:hover': {
      backgroundColor: COLORS.secondaryGray,
    },
  },
});

const checkPhone = (value: string) => /[0-9]{9}/.test(value);

const validatePhone = (value: string) => {
  if (!checkPhone(value)) {
    return 'Введіть номер телефону';
  }
};

const convertDateToString = (dateStart: Date | null, dateEnd: Date | null) =>{
  const start = dateStart ? new Date(dateStart.getTime() + 86400000) : new Date();
  const stop = dateEnd ? new Date(dateEnd.getTime() + 86400000) : new Date();
  return {
    startTosend: start!.toISOString().split('T')[0],
    endDateToSend: stop!.toISOString().split('T')[0]
  }
}

const defaultFilter: IOrderFilter = {
  status: '',
  paymentStatus: '',
  phoneNumber: '',
  startDate: null,
  endDate: null,
};

const OrderFilter = ({ disabledFilter }) => {
  const classes = useStyles();
  const { minAmount, maxAmount, reloadAmount, filter, limit, sortingInfo, searchValue } = useSelector(
    (state: RootState) => state.orders
  );
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const dispatch = useDispatch();
  const location = useLocation();
  const [amount, setAmount] = useState<number[] | null[]>([null, null]);
  const [isAmountChecked, setAmountChecked] = useState(false);
  const { handleClickOpen, isOpened, handleClose } = useModal();
  const [isFormDirty, setFormDirty] = useState(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [isDateChecked, setDateChecked] = useState(false);
  const isDateRangeDisabled = !isDateChecked;
  const colorLabelChecked = darkMode ? { color: 'rgba(255, 255, 255, 0.7)' } : { color: 'rgba(0, 0, 0, 0.54)' };
  const colorLabelDisabled = darkMode ? { color: 'rgba(255, 255, 255, 0.5)' } : { color: 'rgba(0, 0, 0, 0.38)' };

  const [selectedFilter, setSelectedFilter] = useState<IOrderFilter>(defaultFilter);
  const [isGetAmount, setGetAmount] = useState<boolean>(false);

  useLayoutEffect(() => {
    const parsed = queryString.parse(location.search);
    const actualStartDate = parsed.startDate || filter.startDate || null;
    const actualEndDate = parsed.endDate || filter.endDate || null;
    const actualFilter = {
      status: parsed.status || filter.status || '',
      paymentStatus: parsed.paymentStatus || filter.paymentStatus || '',
      phoneNumber: parsed.phoneNumber || filter.phoneNumber || '',
      startDay: actualStartDate,
      endDate: actualEndDate,
    };
    const actualMinAmount = Number(parsed.minAmount) || filter.minAmount || null;
    const actualMaxAmount = Number(parsed.maxAmount) || filter.maxAmount || null;

    //@ts-ignore
    setSelectedFilter({ ...selectedFilter, ...actualFilter });
    if (actualMinAmount && actualMaxAmount) {
      setAmount([actualMinAmount, actualMaxAmount]);
      setAmountChecked(true);
    }
    if (actualStartDate !== null && actualEndDate !== null) {
      setDateRange([new Date(actualStartDate), new Date(actualEndDate)]);
      setDateChecked(true);
    } else {
      setDateChecked(false);
    }

  }, []);

  useEffect(() => {
    if (isGetAmount) {
      getAmountRequest(selectedFilter);
      setGetAmount(false);
    }
  }, [isGetAmount]);

  const getAmountRequest = (filter) => {
    dispatch(getOrderAmountsRequest(cleanObject(filter), searchValue));
  };

  useLayoutEffect(() => {
    if (isAmountChecked && minAmount !== maxAmount && isOpened) {
      setAmount([minAmount, maxAmount]);
    }
    if (isAmountChecked && minAmount === maxAmount) {
      setAmountChecked(false);
    }
  }, [minAmount, maxAmount, isAmountChecked]);

  useEffect(() => {
    if (!isOpened) {
      const { minAmount: minAmountFlt, maxAmount: maxAmountFlt, ...otherFilter } = filter;
      if (!isEqual(selectedFilter, otherFilter)) {
        getAmountRequest(otherFilter);
        setSelectedFilter({ ...selectedFilter, ...otherFilter });
      }
      if ((minAmountFlt && maxAmountFlt && minAmountFlt !== amount[0]) || maxAmountFlt !== amount[1]) {
        setAmount([minAmountFlt, maxAmountFlt]);
        setAmountChecked(true);
      }
    }
  }, [isOpened]);

  const changeValue = (event, value) => {
    setAmount(value);
  };

  useEffect(()=>{
    if (dateRange[0] !== null && dateRange[1] !== null) {
     const { startTosend, endDateToSend } = convertDateToString(dateRange[0], dateRange[1]);
     setSelectedFilter((prevState) => {
       const { startDate: _, endDate: __, ...rest } = prevState;
       return {
         ...rest,
         startDate: startTosend, 
         endDate: endDateToSend, 
       };
     });
     setGetAmount(true);
    }
 }, [isDateChecked, dateRange])

  const handleReset = (setFieldValue: any, values: object) => {
    if (Object.keys(filter).some((key) => filter[key])) {
      dispatch(
        getOrdersRequest(
          1,
          limit,
          { ...defaultFilter, minAmount: null, maxAmount: null },
          sortingInfo,
          searchValue
        )
      );
    } else if (selectedFilter.status || selectedFilter.paymentStatus || selectedFilter.phoneNumber ||(selectedFilter.startDate && selectedFilter.endDate)) {
      setGetAmount(true);
    }
    setSelectedFilter(defaultFilter);
    setAmountChecked(false);
    setAmount([null, null]);
    setDateChecked(false);
    setDateRange([null, null]);
    Object.keys(values).forEach((key) => {
      setFieldValue(key, typeof values[key] === 'string' ? '' : false);
    });
  };

  const onSubmit = (values: any) => {
    const filterNow: IOrderFilter & IOrderAmount = {
      status: values.selectStatus && values.status ? values.status : '',
      paymentStatus: values.selectPaymentStatus && values.paymentStatus ? values.paymentStatus : '',
      phoneNumber:
        !searchValue && values.selectPhoneNumber && values.telPhoneNumber
          ? `380${values.telPhoneNumber}`
          : '',
      minAmount: isAmountChecked ? amount[0] : null,
      maxAmount: isAmountChecked ? amount[1] : null,
      startDate: isDateChecked ? selectedFilter.startDate : null,
      endDate: isDateChecked ? selectedFilter.endDate : null,
    };
    dispatch(getOrdersRequest(1, limit, filterNow, sortingInfo, searchValue));
    handleClose();
    setFormDirty(false);
  };

  const handleInputChange = (field: string, value: string, setFieldValue: any) => {
    setFieldValue(field, value);
    if (field === 'telPhoneNumber' && value && !checkPhone(value)) {
      return;
    }
    if (field === 'telPhoneNumber') {
      field = 'phoneNumber';
    }
    setSelectedFilter((prevState) => {
      if (value || prevState[field]) {
        setGetAmount(true);
      }
      return {
        ...prevState,
        [field]: field === 'phoneNumber' && checkPhone(value) ? `380${value}` : value,
      };
    });
  };

  const getAmountSelect = () => {
    if (isAmountChecked) {
      setAmount([null, null]);
    }
    return { isAmountChecked: !isAmountChecked };
  }; 

  const getDateRangeSelect = () => {
    if (isDateChecked) {
     setDateRange([null, null]);
      setSelectedFilter((prevState)=>{
        return {
          ...prevState,
          startDate: null,
          endDate:null
        }
      });
    }

    return { isDateChecked: !isDateChecked };
  };

  const handleCheckbox = (field, values) => {
    const { selectStatus, selectPaymentStatus, selectPhoneNumber } = values;
    const currentSelect = field === 'selectAmount' ? getAmountSelect() : { [field]: !values[field] };
    const selectDateRange =
      field === 'selectDateRange' ? getDateRangeSelect() : { [field]: !values[field] };
    const currentValues = {
      selectStatus,
      selectPaymentStatus,
      selectPhoneNumber,
      isAmountChecked,
      isDateChecked,
      ...currentSelect,
      ...selectDateRange,
    };
    if (
      Object.keys(filter).some((key) => filter[key]) &&
      Object.keys(currentValues).every((key) => !currentValues[key])
    ) {
      dispatch(
        getOrdersRequest(
          1,
          limit,
          { ...defaultFilter, minAmount: null, maxAmount: null },
          sortingInfo,
          searchValue
        )
      );
      setSelectedFilter(defaultFilter);
      setAmount([null, null]);
      setDateRange([null, null]);
    }
  };

  useLayoutEffect(() => {
    const isStartDateChanged = selectedFilter.startDate !== filter.startDate ;
    const isEndDateChanged = selectedFilter.endDate !== filter.endDate;
    if (
      selectedFilter.status !== filter.status ||
      selectedFilter.paymentStatus !== filter.paymentStatus ||
      selectedFilter.phoneNumber !== filter.phoneNumber ||
      amount[0] !== filter.minAmount ||
      amount[1] !== filter.maxAmount ||
      (isStartDateChanged && isEndDateChanged) ||
      !isEqual(selectedFilter.startDate, filter.startDate) ||
      !isEqual(selectedFilter.endDate, filter.endDate)
    ) {
      setFormDirty(true);
    } else {
      setFormDirty(false);
    }
  }, [selectedFilter, isAmountChecked, amount]);

  const getMarks = () => {
    if (minAmount && maxAmount && minAmount !== maxAmount) {
      return [
        {
          value: minAmount,
          label: `${minAmount}`,
        },
        {
          value: maxAmount,
          label: `${maxAmount}`,
        },
      ];
    } else {
      return [
        {
          value: 0,
          label: ' ',
        },
        {
          value: 1,
          label: ' ',
        },
      ];
    }
  };

  const isViewReset = (values) => {
    if (isAmountChecked || isDateChecked || values.status || values.paymentStatus || values.telPhoneNumber) {
      return false;
    }
    return true;
  };

  const marks = getMarks();

  return (
    <>
      <div>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
          disabled={disabledFilter}
        >
          Фільтри
        </Button>

        <Dialog
          open={isOpened}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Оберіть фільтри:</DialogTitle>
          <DialogContent dividers>
            <Formik
              initialValues={{
                status: filter.status,
                paymentStatus: filter.paymentStatus,
                telPhoneNumber: filter.phoneNumber?.slice(3) || '',
                selectStatus: !!filter.status,
                selectPaymentStatus: !!filter.paymentStatus,
                selectPhoneNumber: !!filter.phoneNumber,
              }}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue }) => {
                return (
                  <Form className={style.mainForm}>
                    <div className={style.box}>
                      <Field
                        className={style.checkbox}
                        onClick={() => {
                          handleInputChange('status', '', setFieldValue);
                          handleCheckbox('selectStatus', values);
                        }}
                        type="checkbox"
                        name="selectStatus"
                      />
                      <InputLabel className={style.inputLabel} disabled={!values.status}>
                        Статус:
                      </InputLabel>
                      <Field
                        fullWidth
                        component={Select}
                        type="select"
                        name="status"
                        variant="standard"
                        disabled={!values.selectStatus}
                        onChange={(event) => {
                          handleInputChange('status', event.target.value, setFieldValue);
                        }}
                      >
                        {Object.entries(enumStatus).map(([key, value]) => (
                          <MenuItem key={key} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Field>
                    </div>
                    <div className={style.box}>
                      <Field
                        className={style.checkbox}
                        onClick={() => {
                          handleInputChange('paymentStatus', '', setFieldValue);
                          handleCheckbox('selectPaymentStatus', values);
                        }}
                        type="checkbox"
                        name="selectPaymentStatus"
                      />
                      <InputLabel className={style.inputLabel} disabled={!values.paymentStatus}>
                        Статус оплати:
                      </InputLabel>
                      <Field
                        fullWidth
                        component={Select}
                        type="select"
                        name="paymentStatus"
                        variant="standard"
                        disabled={!values.selectPaymentStatus}
                        onChange={(event) => {
                          handleInputChange('paymentStatus', event.target.value, setFieldValue);
                        }}
                      >
                        {Object.entries(PaymentStatus).map(([value, label]) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Field>
                    </div>
                    <div className={style.box}>
                      <Field
                        className={style.checkbox}
                        onClick={() => {
                          handleInputChange('telPhoneNumber', '', setFieldValue);
                          handleCheckbox('selectPhoneNumber', values);
                        }}
                        type="checkbox"
                        name="selectPhoneNumber"
                        disabled={searchValue}
                      />
                      <InputLabel className={style.inputLabel} disabled={!values.selectPhoneNumber}>
                        Номер телефону:
                      </InputLabel>
                      <Field
                        fullWidth
                        validate={values.telPhoneNumber ? validatePhone : false}
                        component={TextField}
                        type="tel"
                        name="telPhoneNumber"
                        disabled={searchValue || !values.selectPhoneNumber}
                        onChange={(event) => {
                          handleInputChange('telPhoneNumber', event.target.value, setFieldValue);
                        }}
                        InputProps={{
                          startAdornment: (
                            //@ts-ignore
                            <InputAdornment disabled={!values.selectPhoneNumber} position="start">
                              380
                            </InputAdornment>
                          ),
                          inputProps: {
                            minLength: 9,
                            maxLength: 9,
                          },
                        }}
                      />
                    </div>
                    <div className={style.box}>
                      {reloadAmount && <div className={style.spinner}></div>}
                      <Field
                        className={style.checkboxSlider}
                        type="checkbox"
                        checked={isAmountChecked}
                        id="selectAmount"
                        onChange={() => {
                          setAmountChecked(!isAmountChecked);
                          handleCheckbox('selectAmount', values);
                        }}
                        disabled={reloadAmount || (!minAmount && !maxAmount) || minAmount === maxAmount}
                      />
                      <div className={style.sliderContainer}>
                        <Typography
                          className={style.inputSlider}
                          style={
                            isAmountChecked
                              ? colorLabelChecked
                              : colorLabelDisabled
                          }
                        >
                          Сума:
                        </Typography>
                        <Slider
                          min={marks[0].value}
                          max={marks[1].value}
                          value={amount[0] && amount[1] ? amount : [minAmount, maxAmount]}
                          onChange={changeValue}
                          valueLabelDisplay="auto"
                          name="amount"
                          marks={marks}
                          classes={{
                            root: style.root,
                            rail: style.rail,
                            track: style.track,
                            thumb: style.thumb,
                            markLabel: style.markLabel,
                          }}
                          disabled={reloadAmount || !isAmountChecked}
                        />
                      </div>
                    </div>
                    <div className={style.box}>
                    <Field
                        className={style.checkbox}
                        type="checkbox"
                        checked={isDateChecked}
                        id="selectDateRange"
                        onChange={() => {
                          setDateChecked(!isDateChecked);
                          handleCheckbox('selectDateRange', values);
                        }}
                      />
                      <div className={style.dateFieldWrapper}>
                      <Typography
                          className={style.labelPicker}
                          style={
                            isDateChecked
                              ? colorLabelChecked
                              : colorLabelDisabled
                          }
                        >
                          Діапазон дат:
                        </Typography>
                        <div className={style.datePickerWrapper}>
                          <DatePicker
                            locale="uk"
                            selected={dateRange[0]}
                            onChange={(date) => setDateRange([date, dateRange[1]])}
                            dateFormat="dd.MM.yyyy"
                            maxDate={new Date()}
                            disabled={isDateRangeDisabled}
                            className={style.inputPicker}
                          />
                          <DatePicker
                            locale="uk"
                            selected={dateRange[1]}
                            onChange={(date) => setDateRange([dateRange[0],date])}
                            dateFormat="dd.MM.yyyy"
                            minDate={dateRange[0]}
                            maxDate={new Date()}
                            disabled={isDateRangeDisabled}
                            className={style.inputPicker}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={style.btnContainer}>
                      <Button
                        variant="contained"
                        onClick={() => handleReset(setFieldValue, values)}
                        className={classNames(classes.btn, classes.btnReset)}
                        disabled={isViewReset(values)}
                      >
                        Очистити
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
                        disabled={!isFormDirty}
                      >
                        Примінити
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default OrderFilter;
