import { IActions } from '../../interfaces/actions';
import { IFeedback } from '../../interfaces/IFeedback';

import {
  GET_FEEDBACKS_REQUEST,
  GET_FEEDBACKS_SUCCESS,
  GET_FEEDBACKS_ERROR,
  DELETE_FEEDBACK_ERROR,
  DELETE_FEEDBACK_REQUEST,
  DELETE_FEEDBACK_SUCCESS,
  SET_FEEDBACKS_FIRST_RENDER
} from '../types';

export const getFeedbacksRequest = (page = 1, limit = 10, sort = 'id', sortDirect = 'asc'): IActions => ({
  type: GET_FEEDBACKS_REQUEST,
  data: { page, limit, sort, sortDirect },
});

export const getFeedbacksSuccess = (feedbacks: IFeedback[]): IActions => ({
  type: GET_FEEDBACKS_SUCCESS,
  data: feedbacks,
});

export const getFeedbacksError = (message: string): IActions => ({
  type: GET_FEEDBACKS_ERROR,
  data: message,
});

export const deleteFeedbackRequest = (
  id: number,
  page = 1,
  limit = 10,
  sort = 'id',
  sortDirect = 'asc'
): IActions => ({
  type: DELETE_FEEDBACK_REQUEST,
  data: { id, page, limit, sort, sortDirect },
});

export const deleteFeedbackSuccess = (data): IActions => ({
  type: DELETE_FEEDBACK_SUCCESS,
  data,
});

export const deleteFeedbackError = (message: string): IActions => ({
  type: DELETE_FEEDBACK_ERROR,
  data: message,
});

export const setFeedbacksFirstRender = (isRender: boolean): IActions => ({
  type: SET_FEEDBACKS_FIRST_RENDER,
  data: isRender,
});
