import React from 'react';
import { useDispatch } from 'react-redux';
import useSnackBar from '../../../hooks/useSnackbar';

import { AppDispatch } from '../../../store/store';
import CashbackFrom from './CashbackSettings/Cashback-form';
import WidgetForm from './WidgetsSettings/Widget-form';
import AutoStartParsersForm from './AutoStartParsersSettings/AutoStartParsersForm';
import CarouselForm from './CarouselSettings/Carousel-form';
import CategoriesPrioritySettings from './CategoriesPrioritySettings/CategoriesPrioritySettings';
import DrawForm from './DrawSettings/Draw-form';
import FeedSettingsForm from './FeedSettings/Feed-settings-form';

interface SettingsFormProps {
  name: string;
  parameters: any;
}

const SettingsForms: React.FC<SettingsFormProps> = ({ name, parameters }) => {
  const dispath: AppDispatch = useDispatch();

  const { handleClick } = useSnackBar();

  switch (name) {
    case 'widgets':
      return <WidgetForm name={name} parameters={parameters} dispatch={dispath} handleClick={handleClick} />;
    case 'cashback':
      return (
        <CashbackFrom name={name} parameters={parameters} dispatch={dispath} handleClick={handleClick} />
      );
    case 'draw':
      return <DrawForm name={name} parameters={parameters} dispatch={dispath} handleClick={handleClick} />;
    case 'carousel':
      return (
        <CarouselForm name={name} parameters={parameters} dispatch={dispath} handleClick={handleClick} />
      );
    case 'auto-start-parsers':
      return (
        <AutoStartParsersForm name={name} parameters={parameters} dispatch={dispath} handleClick={handleClick} />
      );
    case 'categories-priority':
      return <CategoriesPrioritySettings/>;
    case 'feed-settings':
      return (
        <FeedSettingsForm dispatch={dispath} />
    );

    default:
      return null;
  }
};

export default SettingsForms;
