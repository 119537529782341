import { put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { apiGetParsersStatus } from './services/parsers.service';
import { saveInProgressParser, saveParsersStatuses, setLoading } from '../actions/parsers.actions';
import { failSnackBar } from '../actions/snackbar.actions';
import { IActions } from '../../interfaces/actions';
import { IParserStatus } from '../../interfaces/IParsers';

export function* getParsersWorker({ data: { parserName } }: IActions): SagaIterator {
  try {
    yield put(setLoading(false))
    const parsers = yield call(apiGetParsersStatus);   
    
    const inProgressParserStatus: IParserStatus = parsers.find((element: IParserStatus) => { return element.parserStatus === 'STARTED'})
    let inProgressParser = ''
    if (inProgressParserStatus){
      inProgressParser = inProgressParserStatus.parserName
    }
     
    const neededParserStatus = parsers.filter((element: IParserStatus) => { return element.parserName === parserName })[0]    
    yield put(saveParsersStatuses(neededParserStatus));
    yield put(saveInProgressParser(inProgressParser));
    yield put(setLoading(true))
  } catch (error: any) {
    yield put(failSnackBar(error.message));
  }
}