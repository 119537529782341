// function currentUTCDate() {
//   const date = new Date();

//   const year = date.getFullYear();
//   const month = date.getMonth();
//   const day = date.getDate();
//   const hours = date.getHours();
//   const minutes = date.getMinutes();
//   const seconds = date.getSeconds();
//   const millisecond = date.getMilliseconds();
//   console.log(new Date(Date.UTC(year, month, day, hours, minutes, seconds, millisecond)));
//   return new Date(Date.UTC(year, month, day, hours, minutes, seconds, millisecond));
// }

// export default currentUTCDate;

function getHourWithOffset(hour: number) {
  return hour > 0 ? hour - 1 : 23;
}

function currentUTCDate(minusOneHourOffset?: boolean) {
  const date = new Date();

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const millisecond = date.getMilliseconds();

  const hoursWithOffset = minusOneHourOffset ? getHourWithOffset(hours) : hours;
  // console.log(new Date(Date.UTC(year, month, day, hoursWithOffset, minutes, seconds, millisecond)));
  return new Date(Date.UTC(year, month, day, hoursWithOffset, minutes, seconds, millisecond));
}

export default currentUTCDate;
