import { useEffect } from 'react';

interface Arg {
  handleClick: (event: MouseEvent) => void;
}

function useBodyClick({ handleClick }: Arg) {
  useEffect(() => {
    document.body.addEventListener('click', handleClick);

    return () => document.body.removeEventListener('click', handleClick);
  }, [handleClick]);
}

export default useBodyClick;
