
export const customStylesDataTable = {
    pagination: {
		style : {	
			'div > select ': {
				paddingRight: '20px'
			}
		}
	}
}
