import { IActions } from '../../interfaces/actions';
import { ISettingsData } from '../../interfaces/ISettings';
import {
  LOAD_SETTINGS,
  UPDATE_SETTINGS,
  SET_PARSER_SETTINGS,
  UPDATE_PARSER_SETTINGS,
  SET_CATEGORIES_WITH_PRIORITY,
  SET_FEED_PARAMETERS,
  SET_LOADING_SETTINGS,
} from '../types';

const data: ISettingsData = {
  list: [],
  parsers: {
    id: null,
    createdAt: null,
    updatedAt: null,
    name: null,
    settings: {
      fashionGirl: {
        updatePhoto: null,
        createNewProducts: null,
        updateOldCharacteristics: null,
      },
    },
  },
  categories: [],
  loading: false,
};

const settings = (state = data, action: IActions) => {
  switch (action.type) {
    case SET_LOADING_SETTINGS: {
      return { ...state, loading: true };
    }
    case LOAD_SETTINGS: {
      return { ...state, list: action.data };
    }
    case UPDATE_SETTINGS: {
      return { ...state, list: action.data };
    }
    case SET_PARSER_SETTINGS: {
      return {
        ...state,
        parsers: action.data.parsers,
      };
    }
    case UPDATE_PARSER_SETTINGS: {
      return {
        ...state,
        parsers: action.data.parsers,
      };
    }
    case SET_FEED_PARAMETERS: {
      return {
        ...state, list: state.list.filter(item => item.id === action.data.parameters.id).length ?
          [...state.list.map(item => {
            if (item.id === action.data.parameters.id) {
              return action.data.parameters;
            }
            return item;
          })]
          :
          [...state.list, action.data.parameters],
        loading: false,
      };
    }
    case SET_CATEGORIES_WITH_PRIORITY: {
      return {
        ...state,
        categories: action.data,
      };
    }
    default:
      return state;
  }
};

export default settings;
