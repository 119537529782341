import { INotification } from '../interfaces/INotification';
import isUserReadNotification from './is-user-read-notification';

const useCountUnreadNotifications = (notifications: INotification[], userId: number) => {
  return notifications.reduce((acc, notification) => {
    const isUserReadNotificationValue = isUserReadNotification(notification, userId);

    if (!isUserReadNotificationValue) {
      return acc + 1;
    }

    return acc;
  }, 0);
};

export default useCountUnreadNotifications;

// import { useSelector } from 'react-redux';
// import { INotification } from '../interfaces/INotification';
// import isUserReadNotification from './is-user-read-notification';
// import { RootState } from '../store/store';

// const useCountUnreadNotifications = (notifications: INotification[], userId: number) => {
//   const { user } = useSelector((state: RootState) => state.user);

//   return notifications.reduce((acc, notification) => {
//     const isUserReadNotificationValue = isUserReadNotification(notification, userId);

//     const isAdminAndNotNewOrder = user?.role.name === 'admin' && notification.type !== 'NEW_ORDER';
//     console.log(isAdminAndNotNewOrder);
//     console.log(isUserReadNotificationValue);
//     if (!isAdminAndNotNewOrder) {
//       return acc + 1;
//     }
//     if (!isUserReadNotificationValue) {
//       return acc + 1;
//     }

//     return acc;
//   }, 0);
// };

// export default useCountUnreadNotifications;
