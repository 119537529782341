import React from 'react';
import styles from '../ProductForm.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import { ImagePreview } from '../types/image-preview';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  img: ImagePreview;
  handleDeleteImg: (img: ImagePreview) => void;
}

const ProductImage = ({ img, handleDeleteImg, ...restContainerProps }: Props) => {
  let className = styles['img-wrapper'];

  const { className: containerPropsClassName } = restContainerProps;

  if (containerPropsClassName?.length) {
    className = `${className} ${containerPropsClassName}`;
  }

  const { url: src } = img;

  return (
    <div {...restContainerProps} className={className}>
      <button className={styles['delete-img']} type="button">
        <DeleteIcon onClick={() => handleDeleteImg(img)} />
      </button>
      <img key={src} src={src} alt="product" className={styles.image} />
    </div>
  );
};

export { ProductImage };
