import { IActions } from '../../interfaces/actions';
import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_ERROR,
  GET_ORDER_BY_ID_REQUEST,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ORDER_BY_ID_ERROR,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_ERROR,
  UPDATE_ORDER_STATUS_REQUEST,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_ERROR,
  GER_ORDERS_BY_PARAMS_REQUEST,
  GER_ORDERS_BY_PARAMS_SUCCESS,
  GER_ORDERS_BY_PARAMS_ERROR,
  UPDATE_PRODUCT_IN_ORDER_REQUEST,
  UPDATE_PRODUCT_IN_ORDER_SUCCESS,
  UPDATE_PRODUCT_IN_ORDER_ERROR,
  GET_ORDERS_BY_RANGE_REQUEST,
  GET_ORDERS_BY_RANGE_SUCCESS,
  GET_ORDERS_BY_RANGE_ERROR,
  UPDATE_ORDER_PAYMENT_STATUS_REQUEST,
  UPDATE_ORDER_PAYMENT_STATUS_SUCCESS,
  UPDATE_ORDER_PAYMENT_STATUS_ERROR,
  UPDATE_ORDER_PARCEL_NUMBER,
  GET_ORDERS_STATUS_BY_RANGE_REQUEST,
  GET_ORDERS_STATUS_BY_RANGE_SUCCESS,
  GET_ORDERS_STATUS_BY_RANGE_ERROR,
  GET_ORDERS_STATISTIC_BY_RANGE_REQUEST,
  GET_ORDERS_STATISTIC_BY_RANGE_SUCCESS,
  GET_ORDERS_STATISTIC_BY_RANGE_ERROR,
  GET_ORDER_AMOUNTS_REQUEST,
  GET_ORDER_AMOUNTS_SUCCESS,
  GET_ORDER_AMOUNTS_ERROR,
  SET_ORDERS_FIRST_RENDER,
  SET_ORDERS_SEARCH_VALUE,
  DELETE_ORDER_BY_ID_REQUEST,
  DELETE_ORDER_BY_ID_SUCCESS,
  DELETE_ORDER_BY_ID_ERROR,
} from '../types';
import { IOrdersData } from '../../interfaces/IOrders';

const initialState: IOrdersData = {
  loading: false,
  list: [],
  currentOrder: null,
  rangeOrders: null,
  rangeOrdersStatus: null,
  rangeOrdersStatistic: null,
  error: null,
  count: 0,
  totalPages: 0,
  searchValue: null,
  page: 1,
  limit: 10,
  minAmount: 1,
  maxAmount: 100,
  reloadAmount: false,
  filter: {
    status: '',
    paymentStatus: '',
    phoneNumber: '',
    minAmount: null,
    maxAmount: null,
    startDate: null,
    endDate: null,
  },
  sortingInfo: {
    sort: 'createdAt',
    sortDirect: 'desc',
  },
  isFirstRender: true,
};

const orders = (state = initialState, { type, data }: IActions) => {
  switch (type) {
    case GET_ORDERS_REQUEST: {
      return {
        ...state,
        page: data.page,
        limit: data.limit,
        filter: { ...state.filter, ...data.filter },
        sortingInfo: { ...state.sortingInfo, ...data.sortingInfo },
        loading: true,
        error: null,
        searchValue: data.searchValue,
      };
    }

    case GET_ORDERS_SUCCESS: {
      return {
        ...state,
        list: state.sortingInfo.sort === "amountWithoutDiscount"
        ? state.sortingInfo.sortDirect === "asc"
          ? data.data.sort((a, b) => a.amount - b.amount)
          : data.data.sort((a, b) => b.amount - a.amount)
        : data.data,
        count: data.count,
        totalPages: data.totalPages,
        loading: false,
        minAmount: data.amount.minAmount,
        maxAmount: data.amount.maxAmount,
        isFirstRender: false,
        currentOrder: null,
      };
    }

    case GET_ORDERS_ERROR: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }

    case SET_ORDERS_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: data,
      };
    }

    case GET_ORDER_AMOUNTS_REQUEST: {
      return {
        ...state,
        reloadAmount: true,
      };
    }

    case GET_ORDER_AMOUNTS_SUCCESS: {
      return {
        ...state,
        minAmount: data.amount.minAmount,
        maxAmount: data.amount.maxAmount,
        reloadAmount: false,
      };
    }

    case GET_ORDER_AMOUNTS_ERROR: {
      return {
        ...state,
        error: data,
      };
    }

    case GET_ORDER_BY_ID_REQUEST: {
      return {
        ...state,
        currentOrder: null,
        loading: true,
        error: null,
      };
    }

    case GET_ORDER_BY_ID_SUCCESS: {
      return {
        ...state,
        currentOrder: data,
        loading: false,
      };
    }

    case GET_ORDER_BY_ID_ERROR: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }

    case GET_ORDERS_BY_RANGE_REQUEST: {
      return {
        ...state,
        rangeOrders: null,
        loading: true,
        error: null,
      };
    }

    case GET_ORDERS_STATUS_BY_RANGE_REQUEST: {
      return {
        ...state,
        rangeOrdersStatus: null,
        loading: true,
        error: null,
      };
    }
    case GET_ORDERS_STATUS_BY_RANGE_SUCCESS: {
      return {
        ...state,
        rangeOrdersStatus: data,
        loading: false,
      };
    }
    case GET_ORDERS_BY_RANGE_SUCCESS: {
      return {
        ...state,
        rangeOrders: data,
        loading: false,
      };
    }

    case GET_ORDERS_BY_RANGE_ERROR: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }
    case GET_ORDERS_STATUS_BY_RANGE_ERROR: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }
    
    case GET_ORDERS_STATISTIC_BY_RANGE_REQUEST: {
      return {
        ...state,
        rangeOrdersStatistic: null,
        loading: true,
        error: null,
      };
    }
    case GET_ORDERS_STATISTIC_BY_RANGE_SUCCESS: {
      return {
        ...state,
        rangeOrdersStatistic: data,
        loading: false,
      };
    }
    case GET_ORDERS_STATISTIC_BY_RANGE_ERROR: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }
    case UPDATE_ORDER_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case UPDATE_ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        list: [
          ...state.list.map((item) => {
            if (item.id === data.id) return { ...item, status: data.status.status };
            return item;
          }),
        ],
        loading: false,
        error: null,
      };
    }

    case UPDATE_ORDER_STATUS_ERROR: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }

    case UPDATE_ORDER_PAYMENT_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case UPDATE_ORDER_PAYMENT_STATUS_SUCCESS: {
      return {
        ...state,
        list: [
          ...state.list.map((item) => {
            if (item.id === data.id) return { ...item, paymentStatus: data.paymentStatus.paymentStatus };
            return item;
          }),
        ],
        loading: false,
        error: null,
      };
    }

    case UPDATE_ORDER_PAYMENT_STATUS_ERROR: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }

    case UPDATE_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case UPDATE_ORDER_PARCEL_NUMBER: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case UPDATE_ORDER_SUCCESS: {
      if (state.currentOrder) {
        return {
          ...state,
          loading: false,
          error: null,
          currentOrder: {
            ...state.currentOrder,
            productToOrder: state.currentOrder.productToOrder.map((item) =>
              item.id === data.id ? data : item
            ),
          },
        };
      }
      return state;
    }

    case UPDATE_ORDER_ERROR: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }

    case GER_ORDERS_BY_PARAMS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
        searchValue: data.searchValue,
      };
    }

    case GER_ORDERS_BY_PARAMS_SUCCESS: {
      return {
        ...state,
        list: data.data,
        count: data.count,
        totalPages: data.totalPages,
        loading: false,
      };
    }

    case GER_ORDERS_BY_PARAMS_ERROR: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }

    case UPDATE_PRODUCT_IN_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
        searchValue: data.searchValue,
      };
    }

    case UPDATE_PRODUCT_IN_ORDER_SUCCESS: {
      return {
        ...state,
        currentOrder: data,
        loading: false,
      };
    }

    case UPDATE_PRODUCT_IN_ORDER_ERROR: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }

    case SET_ORDERS_FIRST_RENDER: {
      return {
        ...state,
        isFirstRender: data,
      };
    }
    
    case DELETE_ORDER_BY_ID_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case DELETE_ORDER_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: state.list.filter((order) => order.id !== data.id),
        count: state.count - 1,
        totalPages: data.totalPages,
        paginationPage: data.page,
      };
    }

    case DELETE_ORDER_BY_ID_ERROR: {
      return {
        ...state,
        loading: false,
        error: data,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default orders;
