import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../values/colors';

const makeFilterStyles = makeStyles({
  btn: {
    borderRadius: '30px',
    color: COLORS.primaryLight,
    marginLeft: 'auto',
  },
  btnLight: {
    'backgroundColor': COLORS.primaryGreen,
    '&:hover': {
      backgroundColor: COLORS.secondaryGreen,
    },
  },
  btnDark: {
    'backgroundColor': COLORS.darkGreen,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkGreen,
    },
  },
  btnReset: {
    'backgroundColor': COLORS.primaryGray,
    '&:hover': {
      backgroundColor: COLORS.secondaryGray,
    },
  },
});

export default makeFilterStyles;
