import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

import { Button, Divider, makeStyles } from '@material-ui/core'
import { COLORS } from '../../values/colors';

interface IDrawWinnerRandomizer {
   data: any,
   result: number,
   setResult: (data: number) => void
}

const useStyles = makeStyles({
   'wrapper': {
      'margin': '25px 15px 35px 15px',
      'padding': '5px 15px',
      'display': 'flex',
      'flexDirection': 'row',
      'justifyContent': 'space-between',
      'alignItems': 'center',
      'border': '1px solid #000',
      'boxShadow': 'rgba(0, 0, 0, 0.35) 0px 5px 15px;',
      'borderRadius': '5px',
      'fontSize': '18px'
   },
   'title': {
      'fontSize': '20px'
   },
   'result': {
      'width': '350px',
      'height': '450px',
      'padding': '20px',
      'marginRight': '25px',
      'display': 'flex',
      'flexDirection': 'column',
      'gap': '10px',
      'alignItems': 'center',
      'justifyContent': 'center',
      'border': '1px solid #000',
      'boxShadow': 'rgba(0, 0, 0, 0.35) 0px 5px 15px;',
      'borderRadius': '5px',
   },
   'number': {
      'fontSize': '50px',
   },
   'winner': {
      'textAlign': 'center',
      
   },
   'button': {
      'color': COLORS.primaryLight,
      'width': 'auto',
      'marginTop': '20px',
      'marginBottom': '20px',
      'border': 'none',
    },
    'buttonItem': {
      'width': '200px',
      'backgroundColor': COLORS.primaryGreen,
      'borderRadius': '30px',
      '&:hover': {
        'backgroundColor': COLORS.secondaryGreen,
      },
    },
    'buttonItemDark': {
      'backgroundColor': COLORS.darkGreen,
      '&:hover': {
        'backgroundColor': COLORS.secondaryDarkGreen,
      },
    }
})

const DrawWinnerRandomizer = ({data, result, setResult}: IDrawWinnerRandomizer) => {

   const darkMode = useSelector((state: RootState) => state.theme.darkMode);
   const styles = useStyles();

   const random = (max: number) => {
      return Math.floor(Math.random() * (max)) + 1
   }

  const handleResult = () => setResult(random(data.length))

  return (
    <div className={styles.result}>
      <span className={styles.title}>Розіграш:</span>
      <div className={styles.number}>
         {result !== 0 ? result : '-'}
      </div>
      <Button
         className={`${styles.buttonItem} ${styles.button} ${darkMode && styles.buttonItemDark}`}
         variant="contained"
         type="button"
         onClick={handleResult}
         disabled={data.length ? false: true}
         >
         Розіграти
         </Button>
         <Divider variant="middle" color='#26bd62' width='200px'/>
         {result !== 0 && (<div className={styles.winner}>
            <p className={styles.title}>Переможець:</p>
            {data[result-1].user.firstName} {data[result-1].user.lastName} <br/><br/>
            <p className={styles.title}>Instagram:</p>
            {data[result-1].instagram ? data[result-1].instagram : '-'}
         </div>)}
   </div>
  )
}

export default DrawWinnerRandomizer