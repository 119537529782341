import React, {FC, useState, useEffect} from 'react';
import { Tooltip } from '@material-ui/core';

interface OrderCopyTipsItemProps {
    handleCopyTips: (tipText: string | undefined) => void;
    cellText: string | undefined;
    showMessageDelay?: number;
  }
  
  const OrderCopyTipsItem: FC<OrderCopyTipsItemProps> = ({handleCopyTips, cellText, showMessageDelay = 1500}) => {
    const [open, setOpen] = useState<boolean>(false);
  
    const handleClick = () => {
      handleCopyTips(cellText);
      console.log('click')
      setOpen(true);
    }
  
    useEffect(() => {
      if(!open) return;
  
      setTimeout(() => {
        setOpen(false)
      }, showMessageDelay)
    }, [open])
  
    return (
      <>
        <Tooltip 
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          leaveDelay={500}
          placement='top'
          title={'скопійовано'}
        >
          <div className="cell" onClick={() => handleClick()}>
            {cellText ? cellText : '-'}
            <div>{cellText ? cellText : '-'}</div>
          </div>
        </Tooltip>
      </>
    )
}

export default OrderCopyTipsItem;