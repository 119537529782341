import { DateTime } from 'luxon';

const usePreviousMonthDateRange = () => {

  const currentDate = DateTime.now().setZone('Europe/Kiev');

  const startOfPreviousMonth = currentDate.minus({ months: 1 }).startOf('month');
  const endOfPreviousMonth = startOfPreviousMonth.endOf('month');

  const dateRange = {
    start: startOfPreviousMonth!.toISODate().split('T')[0],
    end: endOfPreviousMonth!.toISODate().split('T')[0],
  }

  return dateRange;
};

export default usePreviousMonthDateRange;