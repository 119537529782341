import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { saveDrawStart } from "../../store/actions/draw.actions";

import { Dialog, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { COLORS } from "../../values/colors";
import { Button } from "reactstrap";

interface ISaveCurrentDraw {
   showHandler: () => void;
}

const SaveCurrentDraw: React.FC<ISaveCurrentDraw> = ({ showHandler }) => {
   
   const dispatch = useDispatch()
   
   const darkMode = useSelector((state: RootState) => state.theme.darkMode);
   const { dateRaffle, prizeFund, id } = useSelector((state: RootState) => state.drawsReducer )
   const participants = useSelector((state: RootState) => state.drawsReducer.drawUsers)
   
   const winner = participants?.filter(user => user.winner === true)[0]
   const winnerFullName = `${winner.user.firstName} ${winner.user.lastName}`

   const [isShow, setShow] = useState(true)

   const useStyles = makeStyles({
      'formWrapper': {
         'display': 'flex',
         'flexDirection': 'column',
         'textAlign': 'center',
         'justifyContent': 'center',
         'margin': '0 auto'
      },
      'title': {
         'margin': '0 auto'
      },
      'buttonItem': {
         'position': 'relative',
         'left': '50%',
         'transform': 'translate(-50%, 0)',   
         'width': '100%',
         'backgroundColor': COLORS.primaryGreen,
         'color': '#fff',
         'borderRadius': '30px',
         '&:hover': {
            backgroundColor: COLORS.secondaryGreen,
         },
      },
      'buttonItemDark': {
         'backgroundColor': COLORS.darkGreen,
         '&:hover': {
            backgroundColor: COLORS.secondaryDarkGreen,
         },
      },
      'button': {
         'width': '150px',
         'marginTop': '20px',
         'alignItems': 'center',
         'border': 'none'
      },
      'winner': {
         'paddingLeft': '40px'
      }
   })
   const styles = useStyles();
   
   const save = () => {
      dispatch(saveDrawStart({name: winnerFullName, isActive: false, id }))
      showHandler()
   }
   
      const dateToday = new Date()
      const today = new Date(dateToday.toISOString().split('T')[0]);
      const drawDate = new Date(dateRaffle);
      let deadLine
         if (today > drawDate) {
            deadLine = 'Розіграш повинен був завершитися раніше'
         } else if (today < drawDate) {
            deadLine = 'Розіграш повинен був завершитися пізніше'
         } else {
            deadLine = ''
         }

   return (
      <div>
         <Dialog
         open={isShow}
         onClose={showHandler}
         aria-labelledby="form-dialog-title"
         fullWidth
         maxWidth="xs"
         >
            <DialogTitle id="form-dialog-title" className={ styles.title }> Результати розіграшу: </DialogTitle>
            <DialogContent dividers>

               <section>
                  <p> Дата: {dateRaffle} </p>
                  <p style={{color: 'red'}} > { deadLine } </p>
                  <p> Сума: {prizeFund} грн </p>
                  <div> Переможець: 
                     <br /> 
                     {winner 
                        ? 
                           <div className={styles.winner}>
                              <span> {winner.user.firstName} </span> 
                              <span> {winner.user.lastName ? winner.user.lastName : ''}</span>
                              <br />
                              <span> Instagram: {winner.instagram}</span>
                              <br />
                              <span> Mob: {winner.user.phoneNumber}</span>
                              <br />
                              <span> Telegram: {winner.user.telegramId ? winner.user.telegramId : '-'}</span>
                              <br />
                              <span> Дата реєстрації на сайті: {winner.user.createdAt.toString().split('T')[0]}</span>                        
                           </div>
                        : 
                           ' Ще не визначено. Проведіть розіграш'}
                  </div>
               </section>

                  <Button
                     className={`${styles.buttonItem} ${styles.button} ${darkMode && styles.buttonItemDark}`}
                     variant="contained"
                     color="primary"
                     type="submit"
                     disabled={winner ? false : true }
                     onClick={save}
                  >
                     Зберегти
                  </Button>  

            </DialogContent>
         </Dialog>
      </div>
   )
}

export default SaveCurrentDraw