import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { App } from './App';
import { Provider } from 'react-redux';
import store from './store/store';
import ErrorBoundary from './ErrorBoundary';

ReactDOM.render(
  <>
    <React.StrictMode>
      <CssBaseline />
      <Provider store={store}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>
    </React.StrictMode>
  </>,
  document.getElementById('root')
);
