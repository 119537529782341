import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './../../store/store';

import ProductsTable from '../../components/Tables/Products/ProductsTable';
import AddBtn from '../../components/AddBtn/AddBtn';
import ColumnsBtn from '../../components/ColumnsBtn/ColumnsBtn';
import ColumnsMenu from '../../components/ColumnsMenu/ColumnsMenu';
import styles from './ProductsPage.module.scss';
import queryString from 'query-string';
import {
  getProductsRequest,
  getDiscountInfoRequest,
  setProductsFirstRender,
} from '../../store/actions/products.actions';
import { IProductsData } from '../../interfaces/IProducts';
import Preloader from '../../components/Preloader/Preloader';
import ProductFilter from '../../components/Tables/Products/Filter/ProductFilter';
import LightScreenLinearProgress from '../../components/LightScreen/LightScreenLinearProgress';

export enum cols {
  id = 'ID',
  mainImg = 'Головне зображення',
  name = 'Назва',
  price = 'Ціна',
  description = 'Опис',
  category = 'Категорія',
  key = 'URL ключ',
  shopKey = 'Магазин',
  createdAt = 'Створено',
  updatedAt = 'Оновлено',
}

let initialActiveColums: string[] = [
  cols.id,
  cols.mainImg,
  cols.name,
  cols.price,
  cols.description,
  cols.category,
  cols.key,
  cols.shopKey,
  cols.createdAt,
  cols.updatedAt,
];

if (localStorage.getItem('PRODUCTS_SETTINGS')) {
  initialActiveColums = localStorage.getItem('PRODUCTS_SETTINGS')!.split(',');
}

const Products: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { count, paginationPage, paginationLimit, sort, sortDirect, filter, discountInfo } = useSelector(
    (state: RootState) => state.products
  );

  const [showColumnsMenu, setShowColumnsMenu] = useState<boolean>(false);
  const [activeColumns, setActiveColumns] = useState<string[]>(initialActiveColums);

  const wrapper = document.querySelector('#wrapper');

  useEffect(() => {
    const parsed = queryString.parse(location.search) as QueryTypes;

    let actualPage = paginationPage;
    if (parsed.page) actualPage = Number(parsed.page);
    let actualLimit = paginationLimit;
    if (parsed.limit) actualLimit = Number(parsed.limit);
    let actualSort = sort;
    if (parsed.sort) actualSort = parsed.sort;
    let actualSortDirect = sortDirect;
    if (parsed.sortDirect) actualSortDirect = parsed.sortDirect;
    const actualFilter = {
      id: parsed.filterId ? parsed.filterId : filter.id,
      name: parsed.filterName ? parsed.filterName : filter.name,
      category: parsed.filterCategory ? parsed.filterCategory : filter.category,
      shop: parsed.filterShop ? parsed.filterShop : filter.shop,
      price: [
        parsed.filterPriceMin ? +parsed.filterPriceMin : filter.price[0],
        parsed.filterPriceMax ? +parsed.filterPriceMax : filter.price[1],
      ],
      size: parsed.size ? parsed.size : filter.size,
      disabled: parsed.filterDisabled ? parsed.filterDisabled : filter.disabled,
      discounted: parsed.filterDiscounted ? parsed.filterDiscounted : filter.discounted,
      availability: parsed.filterAvailability ? parsed.filterAvailability : filter.availability,
    };

    dispatch(getProductsRequest(actualPage, actualLimit, actualSort, actualSortDirect, actualFilter));
    dispatch(getDiscountInfoRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(setProductsFirstRender(true));
    };
  }, []);

  useEffect(() => {
    const querySearch = {} as QueryTypes;
    if (!!paginationPage && paginationPage !== 1) querySearch.page = String(paginationPage);
    if (!!paginationLimit && paginationLimit !== 10) querySearch.limit = String(paginationLimit);
    if (!!sort && sort !== 'id') querySearch.sort = sort;
    if (!!sortDirect && sortDirect !== 'asc') querySearch.sortDirect = sortDirect;
    if (!!filter.id && filter.id !== null) querySearch.filterId = filter.id;
    if (!!filter.name && filter.name !== '') querySearch.filterName = filter.name;
    if (!!filter.shop && filter.shop !== '') querySearch.filterShop = filter.shop;
    if (!!filter.category && filter.category !== '') querySearch.filterCategory = filter.category;
    if (!!filter.disabled && filter.disabled !== '') querySearch.filterDisabled = filter.disabled;
    if (!!filter.discounted && filter.discounted !== '') querySearch.filterDiscounted = filter.discounted;
    if (!!filter.availability && filter.availability !== '')
      querySearch.filterAvailability = filter.availability;
    if (!!filter.price[0] && !!filter.price[1]) {
      querySearch.filterPriceMin = filter.price[0];
      querySearch.filterPriceMax = filter.price[1];
    }
    if (filter?.size) querySearch.size = filter.size;

    history.push({
      pathname: '/products',
      search: queryString.stringify(querySearch),
      state: { update: true },
    });
  }, [paginationPage, paginationLimit, sort, sortDirect, filter]);

  if (wrapper) {
    wrapper.scrollTo(0, 0);
  }

  const { list, loading, isSearch, isFirstRender }: Partial<IProductsData> = useSelector(
    (state: RootState) => state.products
  );
  localStorage.setItem('PRODUCTS_SETTINGS', activeColumns.toString());

  const handleColumns = (column: string) =>
    activeColumns.includes(column)
      ? setActiveColumns(activeColumns.filter((col) => col !== column))
      : setActiveColumns([...activeColumns, column]);

  return (
    <>
      {loading && <LightScreenLinearProgress />}
      <div className={styles.container}>
        {showColumnsMenu && (
          <ColumnsMenu
            allColumns={cols}
            activeColumns={activeColumns}
            showColumnsMenu={showColumnsMenu}
            setShowColumnsMenu={setShowColumnsMenu}
            handleColumns={handleColumns}
          />
        )}
        <div className={styles['header-btn-wrapper']}>
          <div className={styles.discountInfo}>
            <div className={styles.countContainer}>
              {discountInfo.totalDiscount !== null && (
                <p>
                  Кількість діючих акційних товарів: <span>{discountInfo.totalDiscount}</span>
                </p>
              )}
              {discountInfo.totalNextDiscount !== null && (
                <p>
                  Кількість запланованих акційних товарів: <span>{discountInfo.totalNextDiscount}</span>
                </p>
              )}
            </div>
            <div className={styles.countContainer}>
              {discountInfo.discountPercentage !== null && (
                <p>
                  Відсоток діючих акційних товарів: <span>{discountInfo.discountPercentage} %</span>
                </p>
              )}
            </div>
          </div>
          <div className="product-header-buttons">
            <ProductFilter />
            <Link
              to={{
                pathname: '/product/add',
                state: { from: `${location.pathname}` },
              }}
            >
              <AddBtn title="Додати" handleAdd={undefined} />
            </Link>
            <ColumnsBtn handleClick={() => setShowColumnsMenu(true)} />
          </div>
        </div>
        <div className={styles['table-wrapper']}>
          {isFirstRender ? (
            <Preloader />
          ) : (
            list && (
              <ProductsTable
                list={list}
                activeColumns={activeColumns}
                isSearch={isSearch}
                count={count}
                paginationPage={paginationPage}
                paginationLimit={paginationLimit}
                sort={sort}
                sortDirect={sortDirect}
                filter={filter}
              />
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Products;

type QueryTypes = {
  page?: string;
  limit?: string;
  sort?: string;
  sortDirect?: string;
  filterId?: string;
  filterName?: string;
  filterCategory?: string;
  filterShop?: string;
  filterDisabled?: string;
  filterDiscounted?: string;
  filterAvailability?: string;
  filterPriceMin?: number[];
  filterPriceMax?: number[];
  size?: string;
};
