type ArrayType = Record<string, unknown> & { id: string | number };

export default function mergeArrayOfObjects<T extends ArrayType>(array1: T[], array2: T[]): T[] {
  const mergedArray = [...array1, ...array2];

  const uniqueObjects = new Map();

  mergedArray.forEach((obj) => {
    uniqueObjects.set(obj.id, obj);
  });

  return Array.from(uniqueObjects.values());
}
