import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import useModal from '../../../../hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import {
  getProductsRequest,
  getShopListRequest,
  getPriceRangeRequest,
} from '../../../../store/actions/products.actions';
import classNames from 'classnames';
import useQueryDelay from '../../../../hooks/useQueryDelay';
import { IBasicProductsFilter } from '../../../../interfaces/IProducts';
import Badge from '@material-ui/core/Badge';
import { cleanObject } from '../../../../utils/cleanObject';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import ModalProductFilter from './ModalProductFilter';
import makeFilterStyles from './makeFilterStyles';

const defaultFilter = {
  category: '',
  shop: '',
  size: '',
  disabled: '',
  discounted: '',
  availability: '',
};

const defaultPrice = [null, null];

const ProductFilter: React.FC = () => {
  const { paginationLimit, sort, sortDirect, filter, priceMin, priceMax, shopList, isFirstRender } =
    useSelector((state: RootState) => state.products);

  const classes = makeFilterStyles();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const dispatch = useDispatch();
  const location = useLocation();

  const [price, setPrice] = useState<number[] | null[]>(defaultPrice);
  const [isPriceChecked, setPriceChecked] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<IBasicProductsFilter>(defaultFilter);
  const [rejectRequest, setRejectRequest] = useState<boolean>(true);
  const { handleClickOpen, isOpened, handleClose } = useModal();

  const { id, name, price: filterPrice, ...otherFilter } = filter;
  const [filterPriceMin, filterPriceMax] = filterPrice;

  const getPriceRange = (filter: object) => {
    dispatch(getPriceRangeRequest({ id, name, ...filter }));
  };

  useQueryDelay(selectedFilter, getPriceRange, rejectRequest, 300);

  useEffect(() => {
    if (rejectRequest && !isFirstRender) setRejectRequest(false);
  }, [rejectRequest, isFirstRender]);

  useEffect(() => {
    if (isPriceChecked && priceMin !== priceMax && isOpened) {
      setPrice([priceMin, priceMax]);
    }
    if (isPriceChecked && priceMin === priceMax) {
      setPriceChecked(false);
    }
  }, [priceMin, priceMax, isPriceChecked]);

  useEffect(() => {
    if (!isOpened) {
      if (!isEqual(selectedFilter, otherFilter)) {
        getPriceRange(otherFilter);
        setSelectedFilter({ ...selectedFilter, ...otherFilter });
      }
      if (filterPriceMin + filterPriceMax) {
        if (filterPriceMin !== price[0] || filterPriceMax !== price[1]) {
          setPrice([filterPriceMin, filterPriceMax]);
          setPriceChecked(true);
        }
      }
    }
  }, [isOpened]);

  useLayoutEffect(() => {
    const parsed = queryString.parse(location.search);
    const actualFilter: IBasicProductsFilter = {
      category: parsed.filterCategory ?? filter.category,
      shop: parsed.filterShop ?? filter.shop,
      size: parsed.filterSize ?? filter.size,
      disabled: parsed.filterDisabled ?? filter.disabled,
      discounted: parsed.filterDiscounted ?? filter.discounted,
      availability: parsed.filterAvailability ?? filter.availability,
    };
    const actualMinPrice = Number(parsed.filterPriceMin) ?? filterPriceMin;
    const actualMaxPrice = Number(parsed.filterPriceMax) ?? filterPriceMax;

    setSelectedFilter({ ...selectedFilter, ...actualFilter });
    if (actualMinPrice + actualMaxPrice) {
      setPrice([actualMinPrice, actualMaxPrice]);
      setPriceChecked(true);
    }
  }, []);

  useLayoutEffect(() => {
    if (!shopList) dispatch(getShopListRequest());
  }, []);

  const onSubmit = (values: any) => {
    filter.shop = values.shop;
    filter.category = values.category;
    filter.size = values.size;
    filter.disabled = values.disabled;
    filter.discounted = values.discounted;
    filter.availability = values.availability;
    filter.price = isPriceChecked ? price : defaultPrice;
    dispatch(getProductsRequest(1, paginationLimit, sort, sortDirect, filter));
    handleClose();
  };

  const handleInputChange = (field: string, value: string, setFieldValue: any) => {
    setFieldValue(field, value);

    if ((!value.length && value !== selectedFilter[field]) || value.length) {
      setSelectedFilter((prevState) => {
        return {
          ...prevState,
          [field]: value,
        };
      });
    }
  };

  const restoreDefaultState = () => {
    setPriceChecked(false);
    setSelectedFilter(defaultFilter);
    setPrice(defaultPrice);
  };

  const handleCheckbox = (field, values) => {
    const currentSelectValues = Object.fromEntries(
      Object.entries(values).filter(([key]) => key.startsWith('select'))
    );
    const updateSelectValues = { ...currentSelectValues, [field]: !values[field] };

    if (
      (Object.keys(otherFilter).some((key) => otherFilter[key]) || filterPriceMin || filterPriceMax) &&
      Object.keys(updateSelectValues).every((key) => !updateSelectValues[key])
    ) {
      getDefaultPage();
    }
  };

  const handleReset = (setFieldValue: any, values: object) => {
    Object.keys(values).forEach((key) => {
      setFieldValue(key, typeof values[key] === 'string' ? '' : false);
    });
    if (Object.keys(otherFilter).some((key) => otherFilter[key]) || filterPriceMin + filterPriceMax) {
      getDefaultPage();
    } else {
      restoreDefaultState();
    }
  };

  const getDefaultPage = () => {
    setRejectRequest(true);
    dispatch(
      getProductsRequest(1, paginationLimit, sort, sortDirect, {
        id,
        name,
        ...defaultFilter,
        price: defaultPrice,
      })
    );
    restoreDefaultState();
  };

  const countActiveFilter = () => {
    const priceChecked = filterPriceMin + filterPriceMax ? { isPriceChecked } : {};

    return Object.keys({ ...cleanObject(otherFilter), ...priceChecked }).length;
  };

  return (
    <>
      <div>
        <Badge
          overlap="rectangular"
          color="error"
          badgeContent={countActiveFilter()}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Button
            variant="contained"
            onClick={handleClickOpen}
            className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
          >
            Фільтри
          </Button>
        </Badge>

        <ModalProductFilter
          selectedFilter={selectedFilter}
          isOpened={isOpened}
          handleClose={handleClose}
          isPriceChecked={isPriceChecked}
          onSubmit={onSubmit}
          handleInputChange={handleInputChange}
          handleCheckbox={handleCheckbox}
          setPriceChecked={setPriceChecked}
          setPrice={setPrice}
          price={price}
          handleReset={handleReset}
        />
      </div>
    </>
  );
};

export default ProductFilter;
