import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import { updateCategoryImages } from './services/categoryImages';

import { successSnackBar, failSnackBar } from '../actions/snackbar.actions';
import { updateCategoryImagesSuccess, updateCategoryImagesError } from '../actions/categoryImages';
import { IActions } from '../../interfaces/actions';

export function* updateCategoryImagesWorker({ data }: IActions): SagaIterator {
  try {
    const { data: responseData } = yield call(updateCategoryImages, data);

    if (responseData.success) {
      yield put(successSnackBar(responseData.message));
    } else {
      yield put(failSnackBar(responseData.message));
    }

    yield put(updateCategoryImagesSuccess(responseData));
  } catch (error: any) {
    yield put(failSnackBar(error.message));
    yield put(updateCategoryImagesError(error.errors));
  }
}
