import { IActions } from '../../interfaces/actions';
import { IDraw } from '../../interfaces/IDraw';

import {
  GET_DRAW_REQUEST,
  CREATE_DRAW_START,
  CREATE_DRAW_SUCCESS,
  CREATE_DRAW_ERROR,
  GET_DRAW_ERROR,
  GET_DRAW_START,
  GET_DRAW_SUCCESS,
  EDIT_DRAW_ERROR,
  EDIT_DRAW_START,
  EDIT_DRAW_SUCCESS,
  GET_ACTIVE_DRAW_START,
  GET_ACTIVE_DRAW_SUCCESS,
  GET_ACTIVE_DRAW_ERROR,
  GET_DRAW_USERS_START,
  GET_DRAW_USERS_SUCCESS,
  GET_DRAW_USERS_ERROR,
  SET_DRAW_WINNER_START,
  SET_DRAW_WINNER_SUCCESS,
  SET_DRAW_WINNER_ERROR,
  SAVE_DRAW_START,
  SAVE_DRAW_SUCCESS,
  SAVE_DRAW_ERROR,
} from '../types';

export const getDrawRequest = (page: number, searchValue: string | null): IActions => {
  return {
    type: GET_DRAW_REQUEST,
    data: { page, searchValue },
  };
};

export const createDrawStart = (initialInfo): IActions => {
  console.log(initialInfo);
  return {
    type: CREATE_DRAW_START,
    data: initialInfo,
  };
};

export const createDrawSuccess = (data): IActions => {
  return {
    type: CREATE_DRAW_SUCCESS,
    data: data,
  };
};

export const createDrawError = (error: any): IActions => {
  return {
    type: CREATE_DRAW_ERROR,
    data: error,
  };
};

export const getActiveDrawStart = (): IActions => {
  return {
    type: GET_ACTIVE_DRAW_START,
  };
};

export const getActiveDrawSuccess = (draw: IDraw): IActions => {
  return {
    type: GET_ACTIVE_DRAW_SUCCESS,
    data: draw,
  };
};

export const getActiveDrawError = (error: any): IActions => {
  return {
    type: GET_ACTIVE_DRAW_ERROR,
    data: error,
  };
};

export const getDrawStart = (): IActions => {
  return {
    type: GET_DRAW_START,
  };
};

export const getDrawSuccess = (draw: IDraw): IActions => {
  return {
    type: GET_DRAW_SUCCESS,
    data: draw,
  };
};

export const getDrawError = (error: any): IActions => {
  return {
    type: GET_DRAW_ERROR,
    data: error,
  };
};

export const editDrawStart = (drawInfo): IActions => {
  return {
    type: EDIT_DRAW_START,
    data: drawInfo,
  };
};

export const editDrawSuccess = (): IActions => {
  return {
    type: EDIT_DRAW_SUCCESS,
  };
};

export const editDrawError = (error: any): IActions => {
  return {
    type: EDIT_DRAW_ERROR,
    data: error,
  };
};

export const getDrawUsersByIdStart = (id: number): IActions => {
  return {
    type: GET_DRAW_USERS_START,
    data: id,
  };
};

export const getDrawUsersByIdSuccess = (draw: IDraw): IActions => {
  return {
    type: GET_DRAW_USERS_SUCCESS,
    data: draw,
  };
};

export const getDrawUsersByIdError = (error: any): IActions => {
  return {
    type: GET_DRAW_USERS_ERROR,
    data: error,
  };
};

export const setDrawWinnerStart = (raffleId: string, userId: string): IActions => {
  return {
    type: SET_DRAW_WINNER_START,
    data: { raffleId, userId },
  };
};

export const setDrawWinnerSuccess = (userId: string, winner: boolean): IActions => {
  return {
    type: SET_DRAW_WINNER_SUCCESS,
    data: { userId, winner },
  };
};

export const setDrawWinnerError = (error: any): IActions => {
  return {
    type: SET_DRAW_WINNER_ERROR,
    data: error,
  };
};

export const saveDrawStart = (winnerInfo): IActions => {
  return {
    type: SAVE_DRAW_START,
    data: winnerInfo,
  };
};

export const saveDrawSuccess = (data): IActions => {
  return {
    type: SAVE_DRAW_SUCCESS,
    data: data,
  };
};

export const saveDrawError = (error: any): IActions => {
  return {
    type: SAVE_DRAW_ERROR,
    data: error,
  };
};
