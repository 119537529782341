import { INotification } from '../../interfaces/INotification';
import { IActions } from '../../interfaces/actions';
import {
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_ERROR,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATION,
  READ_NOTIFICATION_SUCCESS,
} from '../types';

export const getAllNotification = (userId: number): IActions => ({
  type: GET_ALL_NOTIFICATIONS,
  data: userId,
});

export const getAllNotificationSuccess = (notifications: INotification[]): IActions => ({
  type: GET_ALL_NOTIFICATIONS_SUCCESS,
  data: notifications,
});

export const getAllNotificationError = () => ({
  type: GET_ALL_NOTIFICATIONS_ERROR,
});

export const notificationRead = (notificationId: number) => {
  return {
    type: READ_NOTIFICATION,
    data: notificationId,
  };
};

export const notificationReadSuccess = (updatedNotification: INotification) => {
  return {
    type: READ_NOTIFICATION_SUCCESS,
    data: updatedNotification,
  };
};
