import React from 'react';
import style from './Paginator.module.scss';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useDispatch, useSelector } from 'react-redux';
import { getCommentsRequest } from '../../store/actions/comments.actions';
import { RootState } from '../../store/store';

const getNumberOfPages = (count: number, rowsPerPage: number): number => {
  return Math.ceil(count / rowsPerPage);
};

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const dispatch = useDispatch();
  const { sort, sortDirect } = useSelector((state: RootState) => state.comments);

  const handleFirstPageButtonClick = () => {
    onPageChange(1);
    dispatch(getCommentsRequest(1, rowsPerPage, sort, sortDirect));
  };

  const handleBackButtonClick = () => {
    onPageChange(page);
    dispatch(getCommentsRequest(page, rowsPerPage, sort, sortDirect));
  };

  const handleNextButtonClick = () => {
    onPageChange(page + 2);
    dispatch(getCommentsRequest(page + 2, rowsPerPage, sort, sortDirect));
  };

  const handleLastPageButtonClick = () => {
    const lastPage = getNumberOfPages(count, rowsPerPage);
    onPageChange(lastPage);
    dispatch(getCommentsRequest(lastPage, rowsPerPage, sort, sortDirect));
  };

  return (
    <>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

const CustomCommentsTablePaginator = ({
  paginationRowsPerPageOptions,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const { paginationLimit, paginationPage } = useSelector((state: RootState) => state.comments);

  return (
    <TablePagination
      className={style.paginator}
      component="nav"
      count={rowCount}
      rowsPerPage={paginationLimit}
      page={paginationPage - 1}
      onPageChange={onChangePage}
      onRowsPerPageChange={({ target }) => onChangeRowsPerPage(Number(target.value))}
      ActionsComponent={TablePaginationActions}
      labelRowsPerPage="Рядків на сторінці:"
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
      rowsPerPageOptions={paginationRowsPerPageOptions}
    />
  );
};

export default CustomCommentsTablePaginator;
