import React, { ChangeEvent, FC, useState, useRef, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import Close from '@material-ui/icons/Close';
import DeleteForever from '@material-ui/icons/DeleteForever';

import styles from './AddPhotosForCategoryModal.module.scss';
import ImageCardList from '../../../pages/TreeCategories/ImageCardList/ImageCardList';
import { ICategoryPhoto, ICategoryPhotoError } from '../../../interfaces/ICategoryPhoto';
import {
  updateCategoryImagesRequest,
  clearUpdateCategoryImagesResult,
} from '../../../store/actions/categoryImages';
import { RootState } from '../../../store/store';

interface IAddPhotosForCategoryModal {
  isModalOpen: boolean;
  closeModal: () => void;
}

const maxFiles = 10;

const AddPhotosForCategoryModal: FC<IAddPhotosForCategoryModal> = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const uploadResult = useSelector((state: RootState) => state.categoryImages.result);
  const errors = useSelector((state: RootState) => state.categoryImages.result?.errors);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [images, setImages] = useState<ICategoryPhoto[]>([]);
  const [uploaded, setUploaded] = useState<boolean>(false);

  useEffect(() => {
    setUploaded(true);
    if (uploadResult?.success) {
      setImages((prevImages) => prevImages.map((img) => ({ ...img, success: uploadResult.success })));
      dispatch(clearUpdateCategoryImagesResult());
    }
  }, [dispatch, uploadResult]);

  useEffect(() => {
    if (errors?.length) {
      setImages((prevImages) =>
        prevImages.map((img) => {
          const hasErrors = errors.filter((er: ICategoryPhotoError) => er.file === img.file.name);
          if (!hasErrors.length) {
            return { ...img, success: false };
          }
          const errorMessage = hasErrors.map((er: ICategoryPhotoError) => er.message).join('. ');
          return {
            ...img,
            errorMessage,
            success: false,
          };
        })
      );
    }
  }, [errors]);

  const imagesHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    setUploaded(false);

    const existingFileNames = images.map((image) => image.file.name);
    const newFiles = Array.from(files).filter(
      (file, i) => i + images.length < maxFiles && !existingFileNames.includes(file.name)
    );

    const newImages = newFiles.map((file) => ({
      url: URL.createObjectURL(file),
      file,
      category: file.name.split('.')[0],
    }));

    setImages((prevImages) => [...prevImages, ...newImages]);
    e.target.value = '';
  };

  const uploadImagesHandler = () => {
    const formData = new FormData();
    images.forEach((image) => formData.append('images', image.file, image.file.name));

    dispatch(updateCategoryImagesRequest(formData));
  };

  const deleteImagesHandler = (fileName: string) => {
    setImages((prevImages) => prevImages.filter((image) => image.file.name.localeCompare(fileName) !== 0));
    setUploaded(false);
  };

  const cleanForm = () => {
    setImages([]);
    setUploaded(false);
  };

  const isUploadButtonDisabled = !images.length;

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <div className={styles.closeBtn}>
        {images.length > 0 && (
          <IconButton onClick={cleanForm}>
            <DeleteForever color="error" />
          </IconButton>
        )}
        <IconButton onClick={closeModal}>
          <Close />
        </IconButton>
      </div>
      <DialogTitle id="form-dialog-title">Завантаження фото</DialogTitle>
      <DialogContent>
        {images.length > 0 && <ImageCardList imageList={images} onDelete={deleteImagesHandler} />}
        <div className={styles['form-btn-wrapper']}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => fileInputRef.current?.click()}
            disabled={images.length >= maxFiles}
          >
            Додати фото
            <input
              hidden
              id="file-input"
              ref={fileInputRef}
              type="file"
              multiple
              accept="image/png, image/jpeg"
              onChange={imagesHandler}
            />
          </Button>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            disabled={isUploadButtonDisabled || uploaded}
            onClick={uploadImagesHandler}
          >
            Завантажити
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddPhotosForCategoryModal;
