import React from 'react';
import { Form, FormikProps, Field } from 'formik';
import { makeStyles, ThemeOptions } from '@material-ui/core/styles';
import { Button, createStyles, DialogActions, Theme, Typography } from '@material-ui/core';

import { IFormCarouselValues } from '../../../../interfaces/widget-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { COLORS } from '../../../../values/colors';
import classNames from 'classnames';
import { TextField } from 'formik-material-ui';

const useStyles = makeStyles(
  (theme: Theme): ThemeOptions =>
    createStyles({
      form: {
        width: '100%',
      },
      inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
      },
      title: {
        width: '210px',
        marginRight: '10px',
      },
      input: {
        width: '150px',
        textAlign: 'center',
      },
      btn: {
        padding: '6px 15px 6px 15px',
        borderRadius: '30px',
        color: COLORS.primaryLight,
      },
      secondaryHeading: {
        flexGrow: 1,
        marginLeft: '30px',
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
      btnLight: {
        'backgroundColor': COLORS.primaryGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryGreen,
        },
      },
      btnDark: {
        'backgroundColor': COLORS.darkGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryDarkGreen,
        },
      },
    })
);

const InnerForm: React.FC<FormikProps<IFormCarouselValues>> = (props) => {
  const classes = useStyles();

  const { darkMode } = useSelector((state: RootState) => state.theme);

  const [state, setState] = React.useState({
    defaultProductsShow: props.values.defaultProductsShow,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
    props.setFieldValue(event.target.name, event.target.value);
  };

  return (
    <Form className={classes.form}>
      <div className={classes.inputContainer}>
        <Typography className={classes.title}>
          Кількість товару, який відображається в каруселі товарів
        </Typography>
        <Field
          className={darkMode ? classes.switchDark : classes.input}
          component={TextField}
          value={state.defaultProductsShow}
          type="number"
          onChange={handleChange}
          id="defaultProductsShow"
          name="defaultProductsShow"
          label="Кількість"
        />
        <Typography className={classes.secondaryHeading}>
          Значення більше 300 призведуть до значного уповільнення завантаження головної сторінки
        </Typography>
      </div>
      <DialogActions>
        <Button
          className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
          variant="contained"
          type="submit"
          disabled={!props.isValid}
        >
          Зберегти
        </Button>
      </DialogActions>
    </Form>
  );
};

export default InnerForm;
