import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle, Button, Chip, Divider, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';

import { AppDispatch, RootState } from '../../../store/store';
import {
  getProductsSizesRequest,
  setProductSize,
  resetFilterValues,
} from '../../../store/actions/products.actions';
import styles from './ProductsSizesModal.module.scss';
import useSettings from '../../../hooks/useSettings';
import { fetchUpdateSettings } from '../../../store/actions/settings.actions';
import SizeInput from '../../SizeInput/SizeInput';
import { IProductSize } from '../../../interfaces/IProductSize';

interface IProductsSizesModal {
  isModalOpen: boolean;
  closeModal: () => void;
}

const settingsName = 'size-priority';

const ProductsSizesModal: FC<IProductsSizesModal> = ({ isModalOpen, closeModal }) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const settings = useSettings();
  const { sizes } = useSelector((state: RootState) => state.products);

  const [sizePriority, setSizePriority] = useState<IProductSize | undefined>();
  const [showEditForm, setShowEditForm] = useState<boolean>(false);

  useEffect(() => {
    if (settings.data) {
      const sizePrioritySettings = settings.data.find((st) => st.name === settingsName);
      if (sizePrioritySettings) {
        setSizePriority(sizePrioritySettings.settings);
      }
    }
  }, [settings]);

  useEffect(() => {
    dispatch(getProductsSizesRequest());
  }, [dispatch]);

  const selectSizeHandler = (size: string) => {
    dispatch(resetFilterValues());
    dispatch(setProductSize(size));
    history.push('/products');
  };

  const toggleEditForm = () => setShowEditForm((prev) => !prev);

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(sizes.join(' '));
    }
  };

  const saveHandler = (data: { [key: string]: string }) => {
    dispatch(fetchUpdateSettings(settingsName, data));
  };
  
  const filteredItems = useMemo(() => {
    const isValidItem = (item: string):boolean => !item.includes('\n');
  
    return sizes.filter(isValidItem);
  }, [sizes]);

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <div className={styles.closeBtn}>
        <IconButton onClick={closeModal}>
          <Close />
        </IconButton>
      </div>

      <DialogTitle id="form-dialog-title">Розміри продукції</DialogTitle>
      <DialogContent>
        {sizes.length > 0 && (
          <div className={styles.sizesContainer}>
            {filteredItems.map((size: string, i: number) => (
              <Chip key={i} label={size} variant="outlined" onClick={() => selectSizeHandler(size)} />
            ))}
          </div>
        )}
        <div className={styles.buttonContainer}>
          {showEditForm && (
            <Button color="primary" variant="contained" onClick={copyToClipboard}>
              Копіювати розміри
            </Button>
          )}
          <Button variant="contained" onClick={toggleEditForm}>
            Редагувати пріорітети
          </Button>
        </div>
        {showEditForm && (
          <>
            <Divider />
            <div className={styles.sizeInputContainer}>
              <SizeInput sizes={sizes} initialValues={sizePriority} onSubmit={saveHandler} />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ProductsSizesModal;
