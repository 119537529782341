import React from 'react'
import styles from './DrawsPage.module.scss'
import CurrentDraw from '../../components/Draws/CurrentDraw';
import TotalDraws from '../../components/Draws/TotalDraws';


const DrawsPage: React.FC = () => {

   return (
      <div className={styles.container}>
            <CurrentDraw />
            <TotalDraws />
      </div>
  )
}

export default DrawsPage