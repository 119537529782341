import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { ChangeEvent } from 'react';

import {
  deleteOrderByIdRequest,
  updateOrderPaymentStatusRequest,
  updateOrderStatusRequest,
} from '../../../store/actions/orders.actions';
import AppOrdersDataTable from '../../AppDataTable/AppOrdersDataTable';
import { getOrdersRequest, getOrdersByParamsRequest } from '../../../store/actions/orders.actions';
import { Status as enumStatus } from '../../../enums/orderStatus';
import { AppDispatch, RootState } from '../../../store/store';
import { IGetOrders } from '../../../interfaces/IOrders';
import OrdersSelector from './OrdersSelector';
import { PaymentStatus } from '../../../enums/paymentStatus';
import { SearchResultHighlight } from '../../Common/SearchResultHighlight';
import DeleteIcon from '@material-ui/icons/Delete';
import { COLORS } from '../../../values/colors';
import styles from './OrdersTable.module.scss';

interface OrdersTableProps {
  list: IGetOrders[];
  activeColumns: Array<string>;
}

const OrdersTable: React.FC<OrdersTableProps> = ({ list, activeColumns }) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { count, searchValue, page, limit, filter, sortingInfo } = useSelector(
    (state: RootState) => state.orders
  );
  const { sort, sortDirect } = sortingInfo;
  const loading = useSelector((state: RootState) => state.orders.loading);

  const onChangeStatus = (orderdId: number) => (e: ChangeEvent<{ value: unknown }>) => {
    e.stopPropagation();
    dispatch(updateOrderStatusRequest(orderdId, { status: `${e.target.value}` }));
  };

  const onChangePaymentStatus = (orderdId: number) => (e: ChangeEvent<{ value: unknown }>) => {
    e.stopPropagation();
    dispatch(
      updateOrderPaymentStatusRequest(orderdId, {
        paymentStatus: e.target.value === PaymentStatus.true ? true : false,
      })
    );
  };

  const onChangeLimit = (newLimit) => {
    const newPage = Math.ceil(((page - 1) * limit + 1) / newLimit);
    dispatch(getOrdersRequest(newPage, newLimit, filter, sortingInfo, searchValue));
  };

  const setSortColumn = (column: any, sortDirect: any) => {
    const sort = column.id;
    dispatch(getOrdersRequest(page, limit, filter, { sort, sortDirect }, searchValue));
  };

  const regExp = searchValue ? new RegExp(searchValue, 'ig') : null;

  const [selectedOrders, setSelectedOrders] = React.useState<number[]>([]);

  const handleSelectOrder = (orderId: number) => {
    setSelectedOrders((prevSelected) => {
      if (prevSelected.includes(orderId)) {
        return prevSelected.filter((id) => id !== orderId);
      } else {
        return [...prevSelected, orderId];
      }
    });
  };

  const handleSelectAllOrders = () => {
    if (selectedOrders.length === list.length) {
      setSelectedOrders([]);
    } else {
      const allOrderIds = list.map((order) => order.id);
      setSelectedOrders(allOrderIds);
    }
  };

  const handleDeleteSelected = () => {
    selectedOrders.forEach((orderId) => {
      dispatch(deleteOrderByIdRequest(orderId));
    });
    setSelectedOrders([]);
  };

  const ordersColumns = [
    {
      name: (
        <input
          type="checkbox"
          onChange={handleSelectAllOrders}
          checked={selectedOrders.length === list.length}
        />
      ),
      maxWidth: '60px',
      id: 'select',
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedOrders.includes(row.id)}
          onChange={() => handleSelectOrder(row.id)}
        />
      ),
    },
    {
      name: 'OrderID',
      selector: (row) => row.id,
      sortable: true,
      maxWidth: '80px',
      minWidth: '60px',
      id: 'id',
      cell: (row) => {
        let newData: React.JSX.Element | null = null;
        if (searchValue && regExp) {
          newData = <SearchResultHighlight data={row.id} filter={searchValue} regExp={regExp} />;
        }
        return <>{newData || row.id}</>;
      },
      omit: !activeColumns.includes('OrderID'),
    },
    // {
    //   name: 'UserID',
    //   selector: (row) => row.user?.id,
    //   sortable: true,
    //   maxWidth: '80px',
    //   minWidth: '60px',
    //   id: 'userId',
    //   cell: (row) => {
    //     let phone = row.additionalNumber ? row.additionalNumber : row.user ? row.user.phoneNumber : '';
    //     let newData: React.JSX.Element | null = null;
    //     if (searchValue && regExp) {
    //       newData = <SearchResultHighlight data={phone} filter={searchValue} regExp={regExp} />;
    //     }
    //     return <>{newData || phone}</>;
    //   },
    //   omit: !activeColumns.includes('UserID'),
    // },
    {
      name: 'UserID',
      selector: (row) => row.user?.id || '',
      sortable: true,
      maxWidth: '80px',
      minWidth: '60px',
      id: 'userId',
      cell: (row) => {
        let userId = row.user?.id || '';
        let newData: React.JSX.Element | null = null;
        if (searchValue && regExp) {
          newData = <SearchResultHighlight data={userId} filter={searchValue} regExp={regExp} />;
        }
        return <>{newData || userId}</>;
      },
      omit: !activeColumns.includes('UserID'),
    },
    {
      name: 'Створено',
      selector: (row) => row.createdAt,
      sortable: true,
      maxWidth: '100px',
      id: 'createdAt',
      format: (row) => {
        return new Date(row.createdAt).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        });
      },
      omit: !activeColumns.includes('Створено'),
    },
    {
      name: 'Оновлено',
      selector: (row) => row.updatedAt,
      sortable: true,
      maxWidth: '100px',
      id: 'updatedAt',
      format: (row) => {
        return new Date(row.updatedAt).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        });
      },
      omit: !activeColumns.includes('Оновлено'),
    },
    {
      name: 'Телефон',
      selector: (row) => (row.additionalNumber ? row.additionalNumber : row.user ? row.user.phoneNumber : ''),
      sortable: true,
      maxWidth: '140px',
      id: 'additionalNumber',
      cell: (row) => {
        let phone = row.additionalNumber ? row.additionalNumber : row.user ? row.user.phoneNumber : '';
        let newData: React.JSX.Element | null = null;
        if (searchValue && regExp) {
          newData = <SearchResultHighlight data={phone} filter={searchValue} regExp={regExp} />;
        }
        return <>{newData || phone}</>;
      },
      omit: !activeColumns.includes('Телефон'),
    },
    {
      name: 'Email',
      maxWidth: '250px',
      selector: (row) => (row.additionalEmail ? row.additionalEmail : row.user ? row.user.email : ''),
      omit: !activeColumns.includes('Email'),
    },
    {
      name: "Ім'я",
      maxWidth: '150px',
      selector: (row) =>
        `${row.additionalFirstName ? row.additionalFirstName : row.user ? row.user.firstName : ''} 
         ${row.additionalLastName ? row.additionalLastName : row.user ? row.user.lastName : ''}`,
      sortable: true,
      id: 'additionalFirstName',
      omit: !activeColumns.includes("Ім'я"),
    },
    {
      name: 'Відділення',
      selector: (row) => (row.delivery ? row.delivery.streetName : '-'),
      maxWidth: '700px',
      omit: !activeColumns.includes('Відділення'),
    },
    {
      name: 'Коментар',
      selector: (row) => `${row.comment ? row.comment : 'Відсутній'}`,
      maxWidth: '600px',
      sortable: true,
      id: 'comment',
      omit: !activeColumns.includes('Коментар'),
    },
    {
      name: 'Не передзвонювати',
      selector: (row) => `${row.notcall ? 'Так' : 'Ні'}`,
      maxWidth: '300px',
      sortable: true,
      id: 'notcall',
      omit: !activeColumns.includes('Не передзвонювати'),
    },
    {
      name: 'Спосіб доставки',
      selector: (row) => (row.delivery ? row.delivery.deliveryMethod : '-'),
      maxWidth: '300px',
      omit: !activeColumns.includes('Спосіб доставки'),
    },
    {
      name: 'Адреса для доставки',
      selector: (row) => (row.delivery ? row.delivery.courierDeliveryAddress : '-'),
      maxWidth: '400px',
      omit: !activeColumns.includes("Адреса для кур'єрської доставки"),
    },
    {
      name: 'Сума',
      selector: (row) => row.amount,
      cell: (row) => {
        if (row.amountWithoutDiscount && row.amountWithoutDiscount !== row.amount) {
          return (
            <div style={{ lineHeight: '40px' }}>
              {row.amount}
              <sup style={{ color: 'red', fontWeight: 'bold', fontSize: '18px' }}>%</sup>
            </div>
          );
        } else {
          return row.amount;
        }
      },
      maxWidth: '100px',
      id: 'amount',
      sortable: true,
      omit: !activeColumns.includes('Сума'),
    },
    {
      name: 'Спосіб оплати',
      selector: (row) => {
        return `${
          row.liqpayPaymentStatus && row.liqpayPaymentStatus === 'success' ? 'liqPay' : 'Оплата при отриманні'
        }`;
      },
      maxWidth: '300px',
      omit: !activeColumns.includes('Спосіб оплати'),
    },
    {
      name: 'Статус',
      selector: (row) => row.status,
      sortable: true,
      maxWidth: '150px',
      id: 'status',
      cell: (row) => {
        return (
          <OrdersSelector
            disabled={loading}
            handleChange={onChangeStatus(row.id)}
            value={row.status}
            menuItems={Object.values(enumStatus)}
          />
        );
      },
      omit: !activeColumns.includes('Статус'),
    },
    {
      name: 'Статуc оплати',
      selector: (row) => row.paymentStatus,
      maxWidth: '200px',
      cell: (row) => {
        if (row.liqpayPaymentStatus === 'success') {
          return 'оплачений';
        }
        return (
          <OrdersSelector
            disabled={loading}
            handleChange={onChangePaymentStatus(row.id)}
            value={PaymentStatus[row.paymentStatus]}
            menuItems={Object.values(PaymentStatus)}
          />
        );
      },
      omit: !activeColumns.includes('Статуc оплати'),
    },
  ];

  return (
    <>
      <div className={styles.ordersWrapp}>
        {selectedOrders.length === 0 ? null : (
        <DeleteIcon className={styles.deleteBtn} onClick={handleDeleteSelected} style={{ color: COLORS.primaryRed }}/>
      )}
      <AppOrdersDataTable
        data={list}
        columns={ordersColumns}
        title="Замовлення"
        onRowClicked={(row) => {
          history.push(`/order/${row.id}`);
        }}
        count={count}
        setLimit={(e) => onChangeLimit(e)}
        setSortColumn={(column, direction) => setSortColumn(column, direction)}
        paginationServer={true}
        paginationPage={page}
        limit={limit}
        defaultSortFieldId={sort}
        sortDirect={sortDirect}
      />
      </div>
    </>
  );
};

export default OrdersTable;
