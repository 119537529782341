import { SagaIterator } from '@redux-saga/types';
import { call, put } from 'redux-saga/effects';
import { api } from '../../api/api';

import { failSnackBar, successSnackBar } from '../actions/snackbar.actions';

import {
  createDrawError,
  createDrawSuccess,
  editDrawError,
  editDrawSuccess,
  getActiveDrawError,
  getActiveDrawSuccess,
  getDrawError,
  getDrawSuccess,
  getDrawUsersByIdError,
  getDrawUsersByIdSuccess,
  saveDrawError,
  saveDrawSuccess,
  setDrawWinnerError,
  setDrawWinnerSuccess,
} from '../actions/draw.actions';

// Saga Workers

export function* createDrawWorker(data): SagaIterator {
  try {
    console.log(data);
    const response = yield call(api.draw.createDraw, data.data);
    console.log(response);
    yield put(createDrawSuccess(response));
    yield put(successSnackBar('Новий розіграш успішно створено!'));
  } catch (error: any) {
    yield put(createDrawError(error));
    yield put(failSnackBar(error.message));
  }
}

export function* getDrawWorker(): SagaIterator {
  try {
    const draws = yield call(api.draw.getDraws);
    const completedDraw = draws.data.filter((dr) => dr.isActive !== true); // получение завершенных розыгрышей
    yield put(getDrawSuccess(completedDraw)); // сохранение в redux store
  } catch (error: any) {
    yield put(getDrawError(error));
    yield put(failSnackBar(error.message));
  }
}

export function* editDrawWorker(data): SagaIterator {
  try {
    console.log(data);
    yield call(api.draw.editDraw, data.data);
    yield put(editDrawSuccess());
    yield put(successSnackBar('Умови розіграшу успішно змінено!'));
  } catch (error: any) {
    yield put(editDrawError(error));
    yield put(failSnackBar(error.message));
  }
}

export function* getActiveDrawWorker(): SagaIterator {
  try {
    const draw = yield call(api.draw.getActiveDraw);
    yield put(getActiveDrawSuccess(draw.data));
  } catch (error: any) {
    yield put(getActiveDrawError(error));
    yield put(failSnackBar(error.message));
  }
}

export function* getDrawUsersByIdWorker(data): SagaIterator {
  try {
    const users = yield call(api.draw.getDrawUsersById, data.data);
    console.log(users);
    yield put(getDrawUsersByIdSuccess(users.data));
  } catch (error: any) {
    yield put(getDrawUsersByIdError(error));
  }
}

export function* setDrawWinnerWorker(data): SagaIterator {
  try {
    const response = yield call(api.draw.setDrawWinner, data.data);
    yield put(setDrawWinnerSuccess(response.data.userId, response.data.winner));
    yield put(successSnackBar('Переможця розіграшу успішно обрано!'));
  } catch (error: any) {
    yield put(setDrawWinnerError(error));
    yield put(failSnackBar(error.message));
  }
}

export function* saveDrawWorker(data): SagaIterator {
  try {
    yield call(api.draw.editDraw, data.data);
    yield put(saveDrawSuccess(data.data));
    yield put(successSnackBar('Розіграш збережено!'));
  } catch (error: any) {
    yield put(saveDrawError(error));
    yield put(failSnackBar(error.message));
  }
}
