import React from 'react';
import style from './ProductFilter.module.scss';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import { IBasicProductsFilter } from '../../../../interfaces/IProducts';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import classNames from 'classnames';
import { IShopList } from '../../../../interfaces/IProducts';
import { convertCamelCaseToNormal } from '../../../../utils/convertCamelCaseToNormal';
import isEqual from 'lodash.isequal';
import makeFilterStyles from './makeFilterStyles';
import InputCategories from './InputCategories';

const isActiveReset = (selectedFilter, isPriceChecked) =>
  !Object.values({ ...selectedFilter, isPriceChecked }).some((value) => value);

interface IModalProductFilter {
  selectedFilter: IBasicProductsFilter;
  isOpened: boolean;
  handleClose: () => void;
  isPriceChecked: boolean;
  onSubmit: (values: any) => void;
  handleInputChange: (field: string, value: string, setFieldValue: any) => void;
  handleCheckbox: (field, values) => void;
  setPriceChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setPrice: React.Dispatch<React.SetStateAction<number[] | null[]>>;
  price: number[] | null[];
  handleReset: (setFieldValue: any, values: object) => void;
}

const ModalProductFilter = ({
  selectedFilter,
  isOpened,
  handleClose,
  isPriceChecked,
  onSubmit,
  handleInputChange,
  handleCheckbox,
  setPriceChecked,
  setPrice,
  price,
  handleReset,
}: IModalProductFilter) => {
  const classes = makeFilterStyles();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const { filter, priceMin, priceMax, shopList, reloadPrice } = useSelector(
    (state: RootState) => state.products
  );

  const { id, name, price: filterPrice, ...otherFilter } = filter;
  const [filterPriceMin, filterPriceMax] = filterPrice;

  const changeValue = (event, el) => {
    const { name, value } = event.target;
    let num = Number(value);
    if (num !== undefined) {
      switch (name) {
        case 'priceMin':
          //@ts-ignore
          setPrice([num, price[1]]);
          break;

        case 'priceMax':
          //@ts-ignore
          setPrice([price[0], num]);
          break;
        default:
          setPrice([priceMin, priceMax]);
          break;
      }
    }

    if (el) {
      setPrice(el);
    }
  };

  const isActiveSubmit = () => {
    const currentFilter = { ...otherFilter, price: filterPriceMin + filterPriceMax ? true : false };

    if (currentFilter.price && isPriceChecked) {
      if (filterPriceMin !== price[0] || filterPriceMax !== price[1]) {
        return false;
      }
    }

    return isEqual(currentFilter, { ...selectedFilter, price: isPriceChecked });
  };

  return (
    <Dialog open={isOpened} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
      <DialogTitle id="form-dialog-title">Оберіть фільтри:</DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            category: filter.category,
            shop: filter.shop,
            size: filter.size,
            disabled: filter.disabled,
            discounted: filter.discounted,
            availability: filter.availability,
            selectCategory: !!filter.category,
            selectShop: !!filter.shop,
            selectSize: !!filter.size,
            selectDisabled: !!filter.disabled,
            selectDiscounted: !!filter.discounted,
            selectAvailability: !!filter.availability,
            selectPrice: isPriceChecked,
          }}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className={style.mainForm}>
              <div className={style.box}>
                <Field
                  className={style.checkbox}
                  onClick={() => {
                    handleInputChange('category', '', setFieldValue);
                    handleCheckbox('selectCategory', values);
                  }}
                  type="checkbox"
                  name="selectCategory"
                />
                <InputCategories
                  filter={filter}
                  values={values}
                  handleInputChange={handleInputChange}
                  setFieldValue={setFieldValue}
                />
              </div>
              <div className={style.box}>
                <Field
                  className={style.checkbox}
                  onClick={() => {
                    handleInputChange('shop', '', setFieldValue);
                    handleCheckbox('selectShop', values);
                  }}
                  type="checkbox"
                  name="selectShop"
                />
                <InputLabel disabled={!values.selectShop}>Магазин:</InputLabel>
                <Field
                  fullWidth
                  component={Select}
                  type="select"
                  name="shop"
                  variant="standard"
                  onChange={(event) => {
                    handleInputChange('shop', event.target.value, setFieldValue);
                  }}
                  disabled={!values.selectShop}
                >
                  {shopList?.map((shop: IShopList) => (
                    <MenuItem key={shop.id} value={shop.parserName}>
                      {convertCamelCaseToNormal(shop.parserName)}
                    </MenuItem>
                  ))}
                </Field>
              </div>
              <div className={style.box}>
                <Field
                  className={style.checkbox}
                  onClick={() => {
                    handleInputChange('disabled', '', setFieldValue);
                    handleCheckbox('selectDisabled', values);
                  }}
                  type="checkbox"
                  name="selectDisabled"
                />
                <InputLabel disabled={!values.selectDisabled}>Disabled:</InputLabel>
                <Field
                  fullWidth
                  component={Select}
                  type="select"
                  name="disabled"
                  variant="standard"
                  onChange={(event) => {
                    handleInputChange('disabled', event.target.value, setFieldValue);
                  }}
                  disabled={!values.selectDisabled}
                >
                  <MenuItem value={'true'}>Так</MenuItem>
                  <MenuItem value={'false'}>Ні</MenuItem>
                </Field>
              </div>
              <div className={style.box}>
                <Field
                  className={style.checkbox}
                  onClick={() => {
                    handleInputChange('discounted', '', setFieldValue);
                    handleCheckbox('selectDiscounted', values);
                  }}
                  type="checkbox"
                  name="selectDiscounted"
                />
                <InputLabel disabled={!values.selectDiscounted}>Акція:</InputLabel>
                <Field
                  fullWidth
                  component={Select}
                  type="select"
                  name="discounted"
                  variant="standard"
                  onChange={(event) => {
                    handleInputChange('discounted', event.target.value, setFieldValue);
                  }}
                  disabled={!values.selectDiscounted}
                >
                  <MenuItem value={'true'}>Так</MenuItem>
                  <MenuItem value={'false'}>Ні</MenuItem>
                </Field>
              </div>
              <div className={style.box}>
                <Field
                  className={style.checkbox}
                  onClick={() => {
                    handleInputChange('availability', '', setFieldValue);
                    handleCheckbox('selectAvailability', values);
                  }}
                  type="checkbox"
                  name="selectAvailability"
                />
                <InputLabel disabled={!values.selectAvailability}>Наявність:</InputLabel>
                <Field
                  fullWidth
                  component={Select}
                  type="select"
                  name="availability"
                  variant="standard"
                  onChange={(event) => {
                    handleInputChange('availability', event.target.value, setFieldValue);
                  }}
                  disabled={!values.selectAvailability}
                >
                  <MenuItem value={'true'}>Так</MenuItem>
                  <MenuItem value={'false'}>Ні</MenuItem>
                </Field>
              </div>
              <div className={style.box}>
                <Field
                  className={style.checkbox}
                  onClick={() => {
                    handleInputChange('size', '', setFieldValue);
                    handleCheckbox('selectSize', values);
                  }}
                  type="checkbox"
                  name="selectSize"
                />
                <Field
                  fullWidth
                  component={TextField}
                  type="text"
                  label="Розмір"
                  name="size"
                  onChange={(event) => {
                    handleInputChange('size', event.target.value, setFieldValue);
                  }}
                  disabled={!values.selectSize}
                />
              </div>
              <div className={style.box}>
                {reloadPrice && <div className={style.spinner}></div>}
                <Field
                  className={style.checkboxSlider}
                  type="checkbox"
                  onClick={() => {
                    setPriceChecked(!isPriceChecked);
                    handleCheckbox('selectPrice', values);
                    if (isPriceChecked) setPrice([null, null]);
                  }}
                  name="selectPrice"
                  disabled={reloadPrice || (!priceMin && !priceMax) || priceMin === priceMax}
                />
                <div className={style.sliderContainer}>
                  <Typography
                    className={style.inputSlider}
                    style={
                      values.selectPrice ? { color: !darkMode ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.74)' } : { color: !darkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.54)' }
                    }
                  >
                    Ціна:
                  </Typography>

                  <Slider
                    min={priceMin}
                    max={priceMax}
                    value={price[0] && price[1] ? price : [priceMin, priceMax]}
                    onChange={changeValue}
                    valueLabelDisplay="auto"
                    name="price"
                    classes={{
                      root: style.root,
                      rail: style.rail,
                      track: style.track,
                      thumb: style.thumb,
                      markLabel: style.markLabel,
                    }}
                    disabled={reloadPrice || !values.selectPrice}
                  />
                  <div>
                    <Field
                      id="priceMin"
                      label="Number"
                      type="number"
                      name="priceMin"
                      value={price[0]}
                      min={priceMin}
                      max={price[1]}
                      onChange={changeValue}
                      disabled={reloadPrice || !values.selectPrice}
                    />
                    <Field
                      id="priceMax"
                      label="Number"
                      type="number"
                      name="priceMax"
                      value={price[1]}
                      min={price[0]}
                      max={priceMax}
                      onChange={changeValue}
                      disabled={reloadPrice || !values.selectPrice}
                    />
                  </div>
                </div>
              </div>
              <div className={style.box}>
                <Button
                  variant="contained"
                  className={classNames(classes.btn, classes.btnReset)}
                  onClick={() => handleReset(setFieldValue, values)}
                  disabled={isActiveReset(selectedFilter, isPriceChecked)}
                  style={{ marginLeft: 0, marginRight: 0 }}
                >
                  Очистити
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
                  disabled={isActiveSubmit()}
                >
                  Примінити
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModalProductFilter;
