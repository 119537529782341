import { IDraw } from '../../interfaces/IDraw';
import { IActions } from '../../interfaces/actions';
import {
  CREATE_DRAW_START,
  CREATE_DRAW_SUCCESS,
  CREATE_DRAW_ERROR,
  EDIT_DRAW_ERROR,
  EDIT_DRAW_START,
  EDIT_DRAW_SUCCESS,
  GET_DRAW_ERROR,
  GET_DRAW_START,
  GET_DRAW_SUCCESS,
  GET_ACTIVE_DRAW_ERROR,
  GET_ACTIVE_DRAW_START,
  GET_ACTIVE_DRAW_SUCCESS,
  GET_DRAW_USERS_START,
  GET_DRAW_USERS_SUCCESS,
  GET_DRAW_USERS_ERROR,
  SET_DRAW_WINNER_START,
  SET_DRAW_WINNER_SUCCESS,
  SET_DRAW_WINNER_ERROR,
  SAVE_DRAW_START,
  SAVE_DRAW_SUCCESS,
  SAVE_DRAW_ERROR,
  GET_DRAW_REQUEST,
} from '../types';

export const initialState: IDraw = {
  instagram: '',
  id: undefined,
  isActive: false,
  conditions: '',
  dateRaffle: '',
  name: '',
  prizeFund: undefined,
  error: '',
  drawUsers: [],
  completedDraws: [],
  searchValue: null,
  winner: false,
};

export const drawsReducer = (state = initialState, action: IActions): IDraw => {
  switch (action.type) {
    case GET_DRAW_REQUEST:
      return { ...state, searchValue: action.data.searchValue };

    // case GET_DRAW_SUCCESS:
    //   return { ...state, raffles: action.payload };

    // case GET_DRAW_ERROR:
    //   return { ...state, error: action.payload.error };

    case CREATE_DRAW_START:
      return { ...state, ...action.data };

    case CREATE_DRAW_SUCCESS:
      return { ...state, ...action.data, drawUsers: [], id: action.data };

    case CREATE_DRAW_ERROR:
      return { ...state, error: action.data.error };

    case GET_DRAW_START:
      return { ...state };

    case GET_DRAW_SUCCESS:
      return { ...state, completedDraws: action.data };

    case GET_DRAW_ERROR:
      return { ...state, error: action.data.error };

    case EDIT_DRAW_START:
      return { ...state, ...action.data };

    case EDIT_DRAW_SUCCESS:
      return { ...state, ...action.data };

    case EDIT_DRAW_ERROR:
      return { ...state, error: action.data };

    case GET_ACTIVE_DRAW_START:
      return { ...state };

    case GET_ACTIVE_DRAW_SUCCESS:
      return { ...state, ...action.data };

    case GET_ACTIVE_DRAW_ERROR:
      return { ...state, error: action.data.error };

    case GET_DRAW_USERS_START:
      return { ...state, ...action.data };

    case GET_DRAW_USERS_SUCCESS:
      return { ...state, drawUsers: action.data };

    case GET_DRAW_USERS_ERROR:
      return { ...state, error: action.data.error };

    case SET_DRAW_WINNER_START:
      return { ...state, ...action.data, winner: true };

    case SET_DRAW_WINNER_SUCCESS:
      return {
        ...state,
        drawUsers: [
          ...state.drawUsers.map((user) => {
            if (user.user.id === action.data.userId) return { ...user, winner: action.data.winner };
            return user;
          }),
        ],
        error: null,
      };

    case SET_DRAW_WINNER_ERROR:
      return { ...state, error: action.data };

    case SAVE_DRAW_START:
      return { ...state, ...action.data };

    case SAVE_DRAW_SUCCESS:
      return {
        ...state,
        isActive: action.data.isActive,
        completedDraws: [
          ...state.completedDraws,
          {
            id: state.id,
            name: action.data.name,
            dateRaffle: state.dateRaffle,
            prizeFund: state.prizeFund,
            conditions: state.conditions,
            isActive: false,
          },
        ],
      };

    case SAVE_DRAW_ERROR:
      return { ...state, error: action.data };

    default:
      return state;
  }
};
