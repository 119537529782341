import { DateTime } from 'luxon';

const usePreviousYearDateRange = () => {

  const currentDate = DateTime.now().setZone('Europe/Kiev');

  const startOfPreviousYear = currentDate.minus({ years: 1 }).startOf('year');
  const endOfPreviouYear = startOfPreviousYear.endOf('year');

  const dateRange = {
    start: startOfPreviousYear!.toISODate().split('T')[0],
    end: endOfPreviouYear!.toISODate().split('T')[0],
  }

  return dateRange;
};

export default usePreviousYearDateRange;