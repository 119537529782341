import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store/store';
import { getInvoicesListRequest } from '../../../../store/actions/invoice.actions';
import cx from 'classnames';

const useStyles = makeStyles({
  head: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 1,
    userSelect: 'none',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  idColumn: {
    minWidth: '34px',
  },
  createdColumn: {
    minWidth: '132px',
  },
});

const sizeIcon = 'small';

const InvoiceTableHeader: React.FC = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const { page, limit, sort, sortDirect } = useSelector((state: RootState) => state.invoice);

  const handleSortChange = (column: string) => {
    let nowSortDirection = 'asc';

    if (column === sort) {
      nowSortDirection = sortDirect === 'asc' ? 'desc' : 'asc';
    }

    dispatch(getInvoicesListRequest(page, limit, column, nowSortDirection));
  };

  return (
    <TableHead classes={{ root: classes.head }}>
      <TableRow>
        <TableCell onClick={() => handleSortChange('id')}>
          <div className={cx(classes.container, classes.idColumn)}>
            ID
            {sort === 'id' &&
              (sortDirect === 'asc' ? (
                <ArrowDropUpIcon fontSize={sizeIcon} />
              ) : (
                <ArrowDropDownIcon fontSize={sizeIcon} />
              ))}
          </div>
        </TableCell>
        <TableCell>Назва</TableCell>
        <TableCell onClick={() => handleSortChange('createdAt')}>
          <div className={cx(classes.container, classes.createdColumn)}>
            Дата створення
            {sort === 'createdAt' &&
              (sortDirect === 'asc' ? (
                <ArrowDropUpIcon fontSize={sizeIcon} />
              ) : (
                <ArrowDropDownIcon fontSize={sizeIcon} />
              ))}
          </div>
        </TableCell>
        <TableCell>Тип</TableCell>
        <TableCell>Розмір</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default InvoiceTableHeader;
