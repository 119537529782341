import { Middleware } from 'redux';
import io from 'socket.io-client';
import { IActions } from '../../interfaces/actions';
import { IParserLog } from '../../pages/Parsers/ParsersPage';
import { getToken } from '../../services/local-storage-controller';
import { receiveMessage, socketConnected } from '../../store/actions/parsers.actions';
import {
  START_CONNECTING,
  STOP_CONNECTING,
  START_PARSING,
  STOP_PARSING,
  UPDATE_ALL,
  UPDATE_ONE,
  DELETE_ALL_PRODUCT_BY_KEY,
} from '../../store/types';

const parserMiddleware: Middleware = (store) => {
  const socket = io(`${process.env.REACT_APP_PROD_SOCKET}/socket-console`, {
    autoConnect: false,
    auth: { token: getToken() },
  });

  return (next) => (action: IActions) => {
    // Start socket connection
    if (action.type === START_CONNECTING) {
      if (!store.getState().parsers.isConnected) socket.connect();

      socket.on('log', (data: IParserLog) => {
        store.dispatch(receiveMessage(data));
      });

      next(action);
      store.dispatch(socketConnected());
      return next(action);
    }

    // Stop socket connection
    if (action.type === STOP_CONNECTING) {
      socket.off();
      socket.disconnect();
      return next(action);
    }

    // Start parsing
    if (action.type === START_PARSING) {
      socket.emit('START', action.data);
      return next(action);
    }

    // Stop parsing
    if (action.type === STOP_PARSING) {
      socket.emit('STOP', action.data);
      return next(action);
    }

    // Update all products
    if (action.type === UPDATE_ALL) {
      socket.emit('UPDATE_ALL', action.data);
      return next(action);
    }

    // Update one product
    if (action.type === UPDATE_ONE) {
      socket.emit('UPDATE_ONE', action.data);
      return next(action);
    }

    // Delete all products by key
    if (action.type === DELETE_ALL_PRODUCT_BY_KEY) {
      socket.emit('DELETE_ALL_PRODUCT_BY_KEY', action.data);
      return next(action);
    }

    next(action);
  };
};

export default parserMiddleware;
