import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme, ThemeOptions } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { setSlidesFirstRender } from '../../store/actions/slides.actions';
import SlideTable from '../Tables/Slides/Slides-table';
import FormDialog from '../Modals/Slide-modal';
import useSlidesModal from '../../hooks/useSlidesModal';
import { RootState } from '../../store/store';
import LightScreenLinearProgress from '../LightScreen/LightScreenLinearProgress';

const useStyles = makeStyles(
  (theme: Theme): ThemeOptions =>
    createStyles({
      tableWrapper: {
        padding: theme.spacing(2),
        '@media (max-width: 475px)': {
          marginTop: '30px',
        },
      },
    })
);

export enum cols {
  id = 'ID',
  name = 'Назва',
  text = 'Опис',
  image = 'Зображення',
  imageMobile = 'Зображення на телефоні',
  href = 'Посилання',
  isShown = 'Відображати',
  priority = 'Пріоритет',
}

const SlidesContainer: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { list: data, loading } = useSelector((state: RootState) => state.slides);
  const slidesCreateModalData = useSlidesModal();

  useEffect(() => {
    return () => {
      dispatch(setSlidesFirstRender(true));
    };
  }, []);

  return (
    <>
      {loading && <LightScreenLinearProgress />}
      <div className={classes.tableWrapper}>
        <SlideTable modalData={slidesCreateModalData} />
      </div>
      <FormDialog dispatch={dispatch} slidesLength={data.length} modalData={slidesCreateModalData} />
    </>
  );
};

export default SlidesContainer;
