import { FormControl, Select, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import CancelIcon from '@material-ui/icons/Cancel';
import { failSnackBar } from '../../../store/actions/snackbar.actions';

interface OrdersColorSelectorProps {
  disabled: boolean;
  value: string | null;
  handleChange: (e: any) => void;
  menuItems: Array<string>;
}

const OrdersColorSelector = ({ value, disabled, handleChange, menuItems }: OrdersColorSelectorProps) => {
  const loading = useSelector((state: RootState) => state.orders.loading);
  const [editable, setEditable] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isEditable = () => {
    if (loading) {
      return;
    }
    setEditable(true);
  };
  const onQuantityChangedCancel = () => {
    setEditable(false);
  };

  const isValueTypeString = typeof value === 'string';

  React.useEffect(() => {
    if (isValueTypeString && !menuItems.includes(value as string) && !editable) {
      dispatch(failSnackBar('Оберіть будь ласка колір.'));
    }
  }, [dispatch, editable, menuItems, value, isValueTypeString]);

  if (!isValueTypeString) {
    return null;
  }

  value = value ?? '';

  if (!menuItems.includes(value) && !editable) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <p style={{ color: 'red', margin: 0 }}>{value}</p>
        {menuItems.length > 0 && <EditIcon onClick={isEditable} fontSize="small" />}
      </div>
    );
  }

  if (editable || menuItems.includes(value)) {
    const filteredMenuItems = menuItems.filter((item) => !!item.trim());

    return (
      <FormControl
        disabled={disabled}
        style={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'row' }}
      >
        <Select value={value} label={value} onChange={handleChange}>
          {filteredMenuItems.map((item, i) => {
            return (
              <MenuItem key={i + `${item}`} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
        {!menuItems.includes(value) && (
          <CancelIcon style={{ width: '20px' }} onClick={onQuantityChangedCancel} />
        )}
      </FormControl>
    );
  }

  return null;
};

export default OrdersColorSelector;
