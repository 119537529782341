import { put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  fetchedSettings,
  getParserSettingsRequest,
  updateSettings,
  getCategoriesWithPriority,
  changeCategoriesPriority,
  generateFeedRequest,
} from './services/settings.service';
import {
  loadSettings,
  setParserSettings,
  setCategoriesWithPriority,
  updateSetting,
  getCategoriesWithPriority as getCategoriesWithPriorityAction,
  setFeedParameters,
  setLoadingSettings,
} from '../actions/settings.actions';
import { failSnackBar, successSnackBar } from '../actions/snackbar.actions';
import { IActions } from '../../interfaces/actions';

export function* fetchSettingsWorker(): SagaIterator {
  try {
    const settingsData = yield call(fetchedSettings);
    yield put(loadSettings(settingsData));
  } catch (error: any) {
    yield put(failSnackBar(error.message));
  }
}

export function* getParserSettingsWorker(): SagaIterator {
  try {
    const settingsData = yield call(getParserSettingsRequest);
    yield put(setParserSettings(settingsData));
  } catch (error: any) {
    yield put(failSnackBar(error.message));
  }
}

export function* generateFeedWorker(): SagaIterator {
  try {
    yield put(setLoadingSettings());
    const settingsData = yield call(generateFeedRequest);
    yield put(setFeedParameters(settingsData));
    yield put(successSnackBar());
  } catch (error: any) {
    yield put(failSnackBar(error.message));
  }
}

export function* updateParserSettingsWorker({ data }: IActions): SagaIterator {
  try {
    const settingsData = yield call(updateSettings, data);

    let newSettingsData = settingsData.filter((element) => element.name === 'parser')[0];
    yield put(setParserSettings(newSettingsData));
    yield put(successSnackBar());
  } catch (error: any) {
    yield put(failSnackBar(error.message));
  }
}

export function* updateSettingsWorker({ data }: IActions): SagaIterator {
  try {
    const updatedSettings = yield call(updateSettings, data);
    yield put(updateSetting(updatedSettings));
    yield put(successSnackBar());
  } catch (error: any) {
    yield put(failSnackBar(error.message));
  }
}

export function* getCategoriesWithPriorityWorker(): SagaIterator {
  try {
    const categories = yield call(getCategoriesWithPriority);
    yield put(setCategoriesWithPriority(categories));
  } catch (error: any) {
    yield put(failSnackBar(error.message));
  }
}

export function* changeCategoriesPriorityWorker({ data }: IActions): SagaIterator {
  try {
    const changeResult = yield call(changeCategoriesPriority, data);

    yield put(successSnackBar(changeResult.message));
    yield put(getCategoriesWithPriorityAction());
  } catch (error: any) {
    yield put(failSnackBar(error.message));
  }
}
