import { IActions } from '../../interfaces/actions';
import { ICategoryPhotoResponce } from '../../interfaces/ICategoryPhoto';
import {
  UPDATE_CATEGORY_IMAGES_REQUEST,
  UPDATE_CATEGORY_IMAGES_SUCCESS,
  UPDATE_CATEGORY_IMAGES_ERROR,
  CLEAR_UPDATE_CATEGORY_IMAGES_RESULT,
} from '../types';

// update category images

export const updateCategoryImagesRequest = (imagesToUpdate: FormData): IActions => ({
  type: UPDATE_CATEGORY_IMAGES_REQUEST,
  data: imagesToUpdate,
});

export const updateCategoryImagesSuccess = (response: ICategoryPhotoResponce): IActions => ({
  type: UPDATE_CATEGORY_IMAGES_SUCCESS,
  data: response,
});

export const updateCategoryImagesError = (message: string): IActions => ({
  type: UPDATE_CATEGORY_IMAGES_ERROR,
  data: message,
});

export const clearUpdateCategoryImagesResult = (): IActions => ({
  type: CLEAR_UPDATE_CATEGORY_IMAGES_RESULT,
  data: null,
});
