import React, {useState} from 'react'
import { ISlideItem } from '../../interfaces/ISlides';
import { ISlidesModal } from '../../interfaces/modals';
import { Button, TextField, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, ThemeOptions } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { COLORS } from '../../values/colors';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeleteSlides } from '../../store/actions/slides.actions';
import { RootState } from '../../store/store';
import { Dispatch } from 'redux';


interface FormDeleteProps {
  dispatch: Dispatch;
  slidesLength: number;
  row: ISlideItem;
  darkMode: boolean;
}

const useStyles = makeStyles(
  (t): ThemeOptions =>
    createStyles({
      icon: {
        cursor: 'pointer',
        transition: '0.3s all',
      },
      iconLight: {
        'color': COLORS.primaryRed,
        '&:hover': {
          color: COLORS.secondaryRed,
        },
      },
      iconDark: {
        'color': COLORS.darkRed,
        '&:hover': {
          color: COLORS.secondaryDarkRed,
        },
      },
    })
);

const FormDelete: React.FC<FormDeleteProps>  = ({row,dispatch, darkMode}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen]= useState(false)

  const {
    list: data,
    count,
    paginationLimit,
    paginationPage,
    sort,
    sortDirect,
    loading,
  } = useSelector((state: RootState) => state.slides);

  const handleClickDelete = (item: ISlideItem) => {
    const newPage =
      paginationPage > 1 && count - (paginationPage - 1) * paginationLimit === 1
        ? paginationPage - 1
        : paginationPage;

    dispatch(fetchDeleteSlides(item, newPage, paginationLimit, sort, sortDirect));
  };

  return (
    <div>
      <DeleteIcon
        className={classNames(classes.icon, darkMode ? classes.iconDark : classes.iconLight)}
        onClick={()=>{setIsOpen(true)}}
      />
      <Dialog
        open={isOpen}
        onClose={()=>setIsOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Ви впевнені, що хочете видалити слайд?</DialogTitle>
        <DialogContent dividers>
          <DialogActions>
            <Button
              onClick={()=>setIsOpen(false)}
              color="primary"
              variant="contained"
              className={classNames(classes.btn, darkMode ? classes.cancelBtnDark : classes.cancelBtn)}
            >
              Скасувати
            </Button>
            <Button
              className={classNames(classes.btn, darkMode ? classes.saveBtnDark : classes.saveBtn)}
              variant="contained"
              color="secondary"
              onClick={()=>{
                handleClickDelete(row)
                console.log(row)
              }}
            >
              Видалити
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default FormDelete