import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import grey from '@material-ui/core/colors/grey';
import { root } from '../../../../api/config';
import { SimpleFileUploadProps } from '../../../../interfaces/SimpleFileUploadProps';

const IconUpload = ({
  field,
  form: { setFieldValue },
  InputProps: inputProps,
  caption,
  fieldId,
  errors,
  categoryKey
}: SimpleFileUploadProps) => {
  const image = field.value;
  const [imageSrc, setImageSrc] = useState(image);

  useEffect(() => {
    if (typeof image !== 'string') {
      if (image?.size > 100 && image?.size <= 9999999) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageSrc(reader.result);
        };
        reader.readAsDataURL(image);
      }
    } else if (!imageSrc.includes(root) && image.length > 2) {
      setImageSrc(`${root}/static/uploads/icons/${image}?${new Date().getTime()}`);
    }
    else if (image.length < 2) {
      setImageSrc(`${root}/static/uploads/icons/${categoryKey}.svg`);
    }
  }, [image])

  const onUpload = async (event: React.ChangeEvent<any>) => {
    if (!event.target.files) return;
    const file = event.currentTarget.files[0];
    setFieldValue(field.name, file);
  };

  // @ts-ignore
  const useStyles = makeStyles({
    form_group: {
      cursor: 'pointer',
      margin: '1em',
      textAlign: 'center',
      position: 'relative',
    },
    label: {
      'border': '2px dashed grey',
      'borderRadius': 5,
      'padding': 5,
      'cursor': 'pointer',
      'textAlign': 'center',
      '&:hover': {
        border: '2px solid #ffff',
      },
      'width': '100%',
    },
    titleWrapper: {
      display: 'block',
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 25,
    },
    title: {
      color: grey,
      fontSize: 14,
    },
    errors: {
      color: '#ff0000',
      fontSize: 14,
    },
    image: {
      zIndex: 1000,
      height: 100,
      display: 'block',
      margin: 'auto',
    },
    inputCss: {
      opacity: 0,
      position: 'absolute',
      cursor: 'pointer',
      fontSize: 150,
      width: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  });

  const classes = useStyles();

  return (
    <div>
      <div className={classes.form_group}>
        <label htmlFor={fieldId} className={classes.label}>
          <div className={classes.titleWrapper}>
            {errors ? (
              <span className={classes.errors}>{errors}</span>
            ) : (
              <span className={classes.title}>{caption}</span>
            )}
          </div>
          <Input
            inputProps={{
              id: fieldId,
              type: 'file',
              name: field.name,
            }}
            onChange={onUpload}
            {...inputProps}
            className={classes.inputCss}
          />
          <img className={classes.image} src={imageSrc} alt={imageSrc} />
        </label>
      </div>
    </div>
  );
};

export default IconUpload;
