import React, {  } from 'react';
import style from './updateOne.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Typography,
} from '@material-ui/core';

import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import useModal from '../../../hooks/useModal';
import { RootState } from '../../../store/store';
import { ISettingParserParams } from '../../../interfaces/ISettings';
import { stylesParserPage } from '../styles';
import { fetchUpdateParserSettings, updateParserSettings } from '../../../store/actions/settings.actions';

const useStyles = makeStyles(stylesParserPage);

enum ParserSettingsDescription {
  updatePhoto = 'Оновлювати фото',
  createNewProducts = 'Створювати новий продукт',
  updateOldProducts = 'Оновлювати старий продукт',
  parserLimit = 'Відсоток для парсигу',
  updateOldCharacteristics = 'Оновлювати характеристики існуючих товарів',
}

type SettingsModalProps = {
  isButtonDeactive: boolean
  parserStatus: string
  parserName: string
}

const validateNumber = (value: number) => {
  if (typeof value !== 'number') {
    return 'Must be number';
  }
  if (value < 1) {
    return 'Must be greater than 1';
  }
  if (value > 100) {
    return 'Must be less than 100';
  }
};

const SettingsModal: React.FC<SettingsModalProps> = ({parserStatus, parserName, isButtonDeactive}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const { parsers } = useSelector((state: RootState) => state.settings)
  const { activeParser } = useSelector((state: RootState) => state.parsers);
  const { handleClickOpen, isOpened, handleClose } = useModal();  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {    
    const newSettings = {
      ...parsers,
      settings: {
        ...parsers.settings,
        [parserName]: {
          ...parsers.settings[parserName],
          [event.target.name]: event.target.checked
        }
      }
    }
    
    dispatch(updateParserSettings(newSettings))
  };

  const currentSettings: ISettingParserParams = parsers.settings[parserName] || {}

  const onSubmit = (values: any) => {    
    const newSettings = {
        ...parsers.settings,
        [parserName]: {
          ...parsers.settings[parserName],
          parserLimit: values.parserLimit
        }
    }
    dispatch(fetchUpdateParserSettings('parser', newSettings))
    handleClose();
  };

  const mappedElements = Object.values(currentSettings).map((element: any, childrenIndex) => {
            const fieldName = ParserSettingsDescription[Object.keys(currentSettings)[childrenIndex]];
            return (
              <div className={classes.lineItem} key={childrenIndex}>
                <Typography className={classes.title}>
                  {fieldName ? fieldName : Object.keys(currentSettings)[childrenIndex]}:
                </Typography>
                {typeof element === 'boolean' && (
                  <Switch
                    className={darkMode ? classes.switchDark : classes.switch}
                    checked={element}
                    onChange={handleChange}
                    name={Object.keys(currentSettings)[childrenIndex]}
                  />
                )}
                {typeof element === 'number' && (
                  <Field
                    component={TextField}
                    validate={validateNumber}
                    type="number"
                    name={Object.keys(currentSettings)[childrenIndex]}
                  />
                )}
                {typeof element === 'string' && (
                  <Field
                    component={TextField}
                    type="string"
                    name={Object.keys(currentSettings)[childrenIndex]}
                  />
                )}
              </div>
            );
          })

  return (
    <>
      <div>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
          disabled={isButtonDeactive || (parserStatus === 'STARTED' ? true : false)}
        >
          {activeParser.toUpperCase()} Settings
        </Button>

        <Dialog
          open={isOpened}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="form-dialog-title">Налаштування парсера {parserName.toUpperCase()}</DialogTitle>
          <DialogContent dividers>
            <Formik
              initialValues={{
                createNewProducts: currentSettings.createNewProducts,
                isWorks: currentSettings.isWorks,
                parserLimit: currentSettings.parserLimit,
                updateOldCharacteristics: currentSettings.updateOldCharacteristics,
                updateOldProducts: currentSettings.updateOldProducts,
                updatePhoto: currentSettings.updatePhoto,
              }}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form className={style.mainForm}>
                  {mappedElements}
                  <DialogActions>
                    <Button
                      className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
                      variant="contained"
                      type="submit"
                    >
                      Зберегти
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default SettingsModal;