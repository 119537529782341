import React, { Dispatch, SetStateAction, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import Card from '@material-ui/core/Card';
import { customStylesDataTable } from './CustomStylesDataTable';
import CustomProductsTablePaginator from '../Paginator/ProductsPaginator';

import { RootState } from '../../store/store';
import { COLORS } from '../../values/colors';

interface ProductsDataTableProps {
  columns: any[];
  data: any[];
  title: ReactElement<any> | string;
  onRowClicked?: (row: any) => void;
  count?: number;
  limit?: number;
  setLimit?: Dispatch<SetStateAction<number>>;
  paginationServer?: boolean;
  defaultSortFieldId?: string | number | null;
  customStyles?: any;
  paginationPage?: number;
  setSortColumn?: (column: any, direction: any) => void;
  sortDirect: string;
}

const AppProductsDataTable: React.FC<ProductsDataTableProps> = ({
  data,
  columns,
  title,
  onRowClicked = () => {},
  count,
  limit,
  setLimit = () => {},
  setSortColumn = () => {},
  paginationServer = true,
  defaultSortFieldId = '',
  paginationPage,
  customStyles = customStylesDataTable,
  sortDirect,
}) => {
  const { darkMode } = useSelector((state: RootState) => state.theme);
  const defaultSortAsc = sortDirect === 'asc' ? true : false;
  // const { status, user } = data;
  // console.log(data[9].status);
  const conditionalRowStyles = [
    {
      when: (row) => row?.id,
      style: (row) => {
        const baseStyles = {
          'position': 'relative',
          'minHeight': '70px',
          // 'opacity': row?.status === 'pending' ? '0.3' : '1',
          'backgroundColor': darkMode ? COLORS.darkTableColor : COLORS.primaryLight,
          '&:hover': {
            backgroundColor: darkMode ? COLORS.secondaryDarkGray : COLORS.primaryOttoman,
          },
        };
        if (row.status === 'pending' || row.updateParams) {
          return {
            ...baseStyles,
            '&::after': {
              content: '"ОЧІКУЄТЬСЯ"',
              position: 'absolute',
              top: '0',
              left: '0',
              color: '#000',
              fontWeight: 'bold',
              textAlign: 'center',
              height: '20px',
              width: '10%',
              backgroundColor: '#00BFFF',
              zIndex: '2',
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              backgroundColor: '#e4e4e486',
              zIndex: '1',
              pointerEvents: 'none',
            },
          };
        }
        return baseStyles;
      },
    },
  ];

  const dataTableStyles = {
    headRow: {
      style: {
        backgroundColor: `${darkMode ? COLORS.darkTableColor : COLORS.primaryLight}`,
      },
    },
    headCells: {
      style: {
        backgroundColor: `${darkMode ? COLORS.darkTableColor : COLORS.primaryLight}`,
      },
    },
    header: {
      style: {
        backgroundColor: `${darkMode ? COLORS.darkTableColor : COLORS.primaryLight}`,
      },
    },
  };

  return (
    <Card>
      <DataTable
        style={{ backgroundColor: '#000' }}
        data={data}
        columns={columns}
        title={title}
        theme={darkMode ? 'dark' : 'default'}
        customStyles={{ ...customStyles, ...dataTableStyles }}
        highlightOnHover={true}
        onRowClicked={onRowClicked}
        pointerOnHover={true}
        fixedHeader={true}
        fixedHeaderScrollHeight={'60vh'}
        pagination
        paginationDefaultPage={paginationPage}
        paginationComponent={CustomProductsTablePaginator}
        paginationTotalRows={count}
        paginationRowsPerPageOptions={[5, 10, 20, 30, 40, 50, 100]}
        paginationServer={paginationServer}
        paginationPerPage={limit}
        onChangeRowsPerPage={(l) => setLimit(l)}
        onSort={(e, direction) => setSortColumn(e, direction)}
        defaultSortAsc={defaultSortAsc}
        defaultSortFieldId={defaultSortFieldId}
        sortFunction={(rows) => rows}
        conditionalRowStyles={conditionalRowStyles}
      />
    </Card>
  );
};

export default AppProductsDataTable;
