import { v1 as uuid } from 'uuid';
import { ImageOrderData } from '../ProductForm/types/image-order-map';
import { ImagePreview } from '../ProductForm/types/image-preview';

function getCollectImageOrderDataReducer() {
  return (acc: ImageOrderData[], curImg: ImagePreview, index: number) => {
    if (curImg.fileName) {
      acc.push({ fileName: curImg.fileName, index });
    }
    return acc;
  };
}

function generateFileName(originalFileName: string) {
  const fileExtension = originalFileName.split('.').pop();

  return `${uuid()}.${fileExtension}`;
}

export { generateFileName, getCollectImageOrderDataReducer };
