import { withFormik } from 'formik';
import { Dispatch } from 'redux';

import { addTreeCategoryRequest } from '../../../../store/actions/treeCategories.actions';
import InnerForm from './InnerForm';
import { IAddTreeCategory } from '../../../../interfaces/ITreeCategory';
import { treeCategoryValidationShema } from '../../../../pages/TreeCategories/TreeCategoryInfo/treeCategoryValidationShema';

interface TreeCategoryFormProps {
  dispatch: Dispatch;
  parentId?: number;
  closeModal: () => void;
  userId: number;
}

const AddTreeCategoryForm = withFormik<TreeCategoryFormProps, IAddTreeCategory>({
  validationSchema: treeCategoryValidationShema,
  handleSubmit: (values: IAddTreeCategory, { setSubmitting, props }) => {
    setSubmitting(false);

    const { closeModal, parentId, dispatch, userId } = props;
    const { name, key, description, icon } = values;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('key', key);
    formData.append('description', description);
    if (parentId) formData.append('parentId', '' + parentId);
    formData.append('icon', icon);
    formData.append('userId', userId.toString());
    dispatch(addTreeCategoryRequest(formData));
    closeModal();
  },
})(InnerForm);

export default AddTreeCategoryForm;
