import React from 'react';

interface ISearchResultHighlight {
  data: string | number;
  regExp: RegExp;
  filter: string;
}

export const SearchResultHighlight = ({ data, regExp, filter }: ISearchResultHighlight) => {
  const dataWithoutFilter = data.toString().split(regExp);
  const res = dataWithoutFilter.map((part, i, arr) => {
    console.log(part)
    if (i === arr.length - 1) return <>{part}</>;
    return (
      <>
        {part}
        <span style={{ backgroundColor: '#26bd62' }}>{filter}</span>
      </>
    );
  });
  return <span>{res}</span>;
};
