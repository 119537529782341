import React, { useEffect } from 'react';
import { Field, Form, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles, ThemeOptions } from '@material-ui/core/styles';
import { alpha, Button, createStyles, DialogActions, Theme, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { RootState } from '../../../../../store/store';
import { COLORS } from '../../../../../values/colors';
import { ICategoryPriority } from '../../../../../interfaces/ISettings';
import { checkForUnwantedCharacters } from '../../../../../utils/checkForUnwantedCharacters';

const useStyles = makeStyles(
  (theme: Theme): ThemeOptions =>
    createStyles({
      form: {
        width: '100%',
        textAlign: 'center',
      },
      inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        justifyContent: 'center',
        padding: '0 2rem',
      },
      fieldContainer: {
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        height: '5rem',
        width: '75%',
      },
      title: {
        marginRight: '1rem',
        width: '250px',
      },
      input: {
        marginTop: '-1rem',
        width: '100%',
      },
      secondaryHeading: {
        flexGrow: 1,
        marginLeft: '30px',
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
      btn: {
        padding: '6px 15px 6px 15px',
        borderRadius: '30px',
        color: COLORS.primaryLight,
      },
      btnLight: {
        'backgroundColor': COLORS.primaryGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryGreen,
        },
      },
      btnDark: {
        'backgroundColor': COLORS.darkGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryDarkGreen,
        },
      },
      switch: {
        '& .MuiSwitch-switchBase.Mui-checked': {
          'color': COLORS.primaryGreen,
          '&:hover': {
            backgroundColor: alpha(COLORS.primaryGreen, theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: COLORS.primaryGreen,
        },
        'marginLeft': 'auto',
      },
      switchDark: {
        '& .MuiSwitch-switchBase.Mui-checked': {
          'color': COLORS.darkGreen,
          '&:hover': {
            backgroundColor: alpha(COLORS.darkGreen, theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: COLORS.darkGreen,
        },
        'margin': 'left',
      },
    })
);

const InnerForm: React.FC<FormikProps<ICategoryPriority>> = (props) => {
  const classes = useStyles();

  useEffect(() => {
    props.validateForm();
  }, []);

  const { darkMode } = useSelector((state: RootState) => state.theme);

  const [state, setState] = React.useState<ICategoryPriority>(props.initialValues);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldValue = event.target.value;

    setState({
      ...state,
      priority: parseInt(fieldValue),
    });
    props.setFieldValue('priority', parseInt(fieldValue));
  };

  return (
    <Form className={classes.form}>
      <div className={classes.inputContainer}>
        <div className={classes.fieldContainer}>
          <Typography className={classes.title}>{state.name}:</Typography>
          <Field
            component={TextField}
            className={classes.input}
            type="number"
            id={state.key}
            name={state.name}
            label="Пріоритет"
            value={state.priority || ''}
            onKeyDown={checkForUnwantedCharacters}
            onChange={handleChange}
          />
        </div>
      </div>
      <Typography className={classes.secondaryHeading}>
        Значення пріоритету починається з 1. Категорії які будуть мати менше значення, будуть розташовані вище
        за інші
      </Typography>
      <DialogActions>
        <Button
          className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
          variant="contained"
          type="submit"
          disabled={!props.isValid}
        >
          Зберегти
        </Button>
      </DialogActions>
    </Form>
  );
};

export default InnerForm;
