import { IActions } from '../../interfaces/actions';
import { ISlideItem, ISlideUpdateValues, ISlideVisibility } from '../../interfaces/ISlides';
import {
  ADD_SLIDE,
  LOAD_SLIDES,
  REQUEST_SLIDES,
  REQUEST_ADD_SLIDES,
  REQUEST_UPDATE_SLIDES,
  UPDATE_SLIDE,
  REQUEST_DELETE_SLIDES,
  DELETE_SLIDE,
  REQUEST_UPDATE_SLIDE_VISIBILITY,
  LOAD_SLIDES_ERROR,
  SET_SLIDES_FIRST_RENDER,
  GET_SLIDES_BY_RANGE_REQUEST,
  GET_SLIDES_BY_RANGE_SUCCESS,
  GET_SLIDES_BY_RANGE_ERROR,
} from '../types';

export const loadSlides = (slides: ISlideItem[]): IActions => ({ type: LOAD_SLIDES, data: slides });
export const loadSlidesError = (err): IActions => ({ type: LOAD_SLIDES_ERROR, data: err });
export const fetchSlides = (page = 1, limit = 10, sort = 'id', sortDirect = 'asc'): IActions => ({
  type: REQUEST_SLIDES,
  data: { page, limit, sort, sortDirect },
});
export const setSlidesFirstRender = (isRender: boolean): IActions => ({
  type: SET_SLIDES_FIRST_RENDER,
  data: isRender,
});

// add slide
export const fetchAddSlides = (slide: FormData): IActions => ({
  type: REQUEST_ADD_SLIDES,
  data: slide,
});
export const addSlide = (slide: ISlideItem): IActions => ({
  type: ADD_SLIDE,
  data: slide,
});

// delete slide
export const fetchDeleteSlides = (
  slide: ISlideItem,
  page = 1,
  limit = 10,
  sort = 'id',
  sortDirect = 'asc'
): IActions => ({
  type: REQUEST_DELETE_SLIDES,
  data: { slide, page, limit, sort, sortDirect },
});
export const deleteSlide = (data): IActions => ({
  type: DELETE_SLIDE,
  data,
});

// update slide
export const fetchUpdateSlides = (slide: ISlideUpdateValues): IActions => ({
  type: REQUEST_UPDATE_SLIDES,
  data: slide,
});
export const updateSlide = (slide: ISlideItem): IActions => ({
  type: UPDATE_SLIDE,
  data: slide,
});

// update visibility slide
export const fetchUpdateSlideVisibility = (slide: ISlideVisibility): IActions => ({
  type: REQUEST_UPDATE_SLIDE_VISIBILITY,
  data: slide,
});

// get slides by date
export const getSlidesByRangeRequest = (datesArray: string[], slideId?: number): IActions => ({
  type: GET_SLIDES_BY_RANGE_REQUEST,
  data: { datesArray, slideId },
});

export const getSlidesByRangeSuccess = (slides: any): IActions => ({
  type: GET_SLIDES_BY_RANGE_SUCCESS,
  data: slides,
});

export const getSlidesByRangeError = (message: string): IActions => ({
  type: GET_SLIDES_BY_RANGE_ERROR,
  data: message,
});
