import React from "react";

const Loading: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
  style={{ margin: "auto", background: "none" }}
  width="200"
  height="200"
  display="block"
  preserveAspectRatio="xMidYMid"
  viewBox="0 0 100 100"
  >
  <rect
    width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  >
  <animate
    attributeName="opacity"
  begin="-0.96s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(14.4 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.92s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(28.8 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.88s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(43.2 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.84s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(57.6 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.8s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(72 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.76s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(86.4 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.72s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(100.8 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.68s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(115.2 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.64s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(129.6 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.6s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(144 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.56s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(158.4 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.52s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(172.8 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.48s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(187.2 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.44s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(201.6 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.4s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(216 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.36s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(230.4 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.32s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(244.8 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.28s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(259.2 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.24s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(273.6 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.2s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(288 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.16s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(302.4 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.12s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(316.8 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.08s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(331.2 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="-0.04s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    <rect
  width="1"
  height="13"
  x="49.5"
  y="26.5"
  fill="#1d0e0b"
  rx="0.5"
  ry="6.5"
  transform="rotate(345.6 50 50)"
  >
  <animate
    attributeName="opacity"
  begin="0s"
  dur="1s"
  keyTimes="0;1"
  repeatCount="indefinite"
  values="1;0"
    ></animate>
    </rect>
    </svg>
);
}

export default Loading;
