import React from 'react';
import style from './updateOne.module.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import useModal from '../../../hooks/useModal';
import { RootState } from '../../../store/store';
import { stylesParserPage } from '../styles';

const useStyles = makeStyles(stylesParserPage);

type UpdateOneProps = {
  name: string;
  isButtonDeactive: boolean;
  parserStatus: string;
  parserName: string;
  updateCommand: (key: string) => void;
};

const UpdateOne: React.FC<UpdateOneProps> = ({
  name,
  updateCommand,
  parserStatus,
  parserName,
  isButtonDeactive,
}) => {
  const classes = useStyles();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const { activeParser } = useSelector((state: RootState) => state.parsers);
  const { handleClickOpen, isOpened, handleClose } = useModal();

  const onSubmit = (values: any) => {
    if (name === 'UpdateOne') {
      updateCommand(values.productKey);
    } else {
      updateCommand(activeParser)
    }
    handleClose();
  };

  return (
    <>
      <div>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
          disabled={isButtonDeactive || (parserStatus === 'STARTED' ? true : false)}
        >
          {name === 'UpdateOne' ? `Update One ${activeParser.toUpperCase()}` : `Delete all ${activeParser.toUpperCase()} products`}
        </Button>

        {name === 'UpdateOne' ? (
          <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle id="form-dialog-title">
              Введіть ключ товару від {parserName.toUpperCase()}
            </DialogTitle>
            <DialogContent dividers>
              <Formik
                initialValues={{
                  productKey: '',
                }}
                onSubmit={onSubmit}
              >
                {({ values, setFieldValue }) => (
                  <Form className={style.mainForm}>
                    <div className={style.box}>
                      <Field
                        fullWidth
                        component={TextField}
                        type="text"
                        label="Ключ товару"
                        name="productKey"
                      />
                    </div>
                    <div className={style.box}>
                      <Button
                        variant="contained"
                        type="submit"
                        className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        ) : (
          <Dialog
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Видалити всі продукти {activeParser.toUpperCase()}?
            </DialogTitle>
            <DialogActions >

              <Button
                onClick={handleClose}
                className={classNames(
                  classes.btn,
                  darkMode ? classes.declineButtonDark : classes.declineButton
                )}
              >
                Ні
              </Button>
              <Button
                onClick={onSubmit}
                className={classNames(
                  classes.btn,
                  darkMode ? classes.confirmButtonDark : classes.confirmButton
                )}
              >
                Так
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default UpdateOne;
