import dayjs from './dayjs';

function dateToUTCWithoutTime(date: Date | string | dayjs.Dayjs) {
  const dayjsDate = dayjs(date);

  const year = dayjsDate.year();
  const month = dayjsDate.month();
  const day = dayjsDate.date();

  return new Date(Date.UTC(year, month, day));
}

export default dateToUTCWithoutTime;
