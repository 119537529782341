import React, { FC } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { formatDateString } from '../../../utils/formatDate';
import { RootState } from '../../../store/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    summary: { backgroundColor: 'rgba(0, 0, 0, .03)' },
    span: { display: 'block', marginBottom: '8px' },
  })
);

const ParserStatus: FC = () => {
  const { statuses } = useSelector((state: RootState) => state.parsers);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Статус парсера: {statuses.parserStatus}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <span className={classes.span}>
              <b>Останній старт роботи парсера: </b>
              {formatDateString(statuses.lastStart ? statuses.lastStart : '  ---')}
            </span>
            <span className={classes.span}>
              <b>Останнє завершення роботи парсера: </b>
              {statuses.parserStatus === 'STOPPED'
                ? formatDateString(statuses.lastUpdate ? statuses.lastUpdate : '  ---')
                : ' parsing ...'}
            </span>
            <span className={classes.span}>
              <b>Останнє повідомлення парсера: </b>
              {statuses.lastMessage ? statuses.lastMessage : '  ---'}
            </span>
            <span className={classes.span}>
              <b>Загальна кількість збережених товарів парсером: </b>
              {statuses.lastMessage?.includes('parsing finished')
                ? statuses.allParsedProducts || '  ---'
                : '  ---'}
            </span>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ParserStatus;
