import React from 'react';
import { useSelector } from 'react-redux';
import InvoicesTable from '../../components/Tables/Invoices/InvoicesTable';
import { RootState } from '../../store/store';
import AddBtn from '../../components/AddBtn/AddBtn';
import GenerateInvoiceModal from '../../components/Modals/GenerateInvoiceModal/GenerateInvoiceModal';
import useModal from '../../hooks/useModal';
import LightScreenLinearProgress from '../../components/LightScreen/LightScreenLinearProgress';
import Preloader from '../../components/Preloader/Preloader';
import useInvoices from '../../hooks/useInvoices';

const Invoices = () => {
  const { loading, isFirstRender } = useSelector((state: RootState) => state.invoice);
  const generateInvoiceModalData = useModal();
  const { handleClickOpen } = generateInvoiceModalData;
  const { dispatch } = useInvoices();

  return (
    <>
      {loading && <LightScreenLinearProgress />}
      <div>
        <div className="btns-wrapper">
          <AddBtn title="Згенерувати інвойс" handleAdd={handleClickOpen} />
        </div>
        {isFirstRender ? <Preloader /> : <InvoicesTable dispatch={dispatch} />}
        <GenerateInvoiceModal dispatch={dispatch} modalData={generateInvoiceModalData}></GenerateInvoiceModal>
      </div>
    </>
  );
};

export default Invoices;
