import { IActions } from '../../interfaces/actions';
import { IParserStatus, ISocketEmit } from '../../interfaces/IParsers';
import { IParserLog } from '../../pages/Parsers/ParsersPage';

import {
  CHANGE_PARSER,
  GET_PARSERS_STATUSES, 
  SAVE_PARSERS_STATUSES, 
  SET_LOADING,
  START_CONNECTING,
  STOP_CONNECTING,
  START_PARSING,
  RECEIVE_MESSAGE,
  SOCKET_CONNECTED,
  STOP_PARSING,
  UPDATE_ALL,
  SAVE_IN_PROGRESS_STATUS,
  UPDATE_ONE,
  DELETE_ALL_PRODUCT_BY_KEY,
} from '../types';

// get all
export const getParsersStatusesRequest = (parserName: string): IActions => ({
  type: GET_PARSERS_STATUSES,
  data: {parserName},
});

export const saveParsersStatuses = (statuses: IParserStatus): IActions => ({
  type: SAVE_PARSERS_STATUSES,
  data: {statuses},
});

export const setLoading = (isLoading: boolean): IActions => ({
  type: SET_LOADING,
  data: {isLoading},
});

export const changeParser = (activeParser: string): IActions => ({
  type: CHANGE_PARSER,
  data: {activeParser},
});


export const startConnecting = (): IActions => ({
  type: START_CONNECTING,
});

export const stopConnecting = (): IActions => ({
  type: STOP_CONNECTING,
});

export const socketConnected = (): IActions => ({
  type: SOCKET_CONNECTED,
});

export const receiveMessage = (data: IParserLog): IActions => ({
  type: RECEIVE_MESSAGE,
  data: data
});

export const startParsing = (data: ISocketEmit): IActions => ({
  type: START_PARSING,
  data: data
});

export const stopParsing = (data: ISocketEmit): IActions => ({
  type: STOP_PARSING,
  data: data
});

export const updateAll = (data: ISocketEmit): IActions => ({
  type: UPDATE_ALL,
  data: data
});

export const deleteAll = (data: ISocketEmit): IActions => ({
  type: DELETE_ALL_PRODUCT_BY_KEY,
  data: data
});

export const updateOne = (data: ISocketEmit): IActions => ({
  type: UPDATE_ONE,
  data: data
});

export const saveInProgressParser = (inProgressParser: string): IActions => ({
  type: SAVE_IN_PROGRESS_STATUS,
  data: inProgressParser
});

