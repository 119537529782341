import { IActions } from '../../interfaces/actions';
import { IInvoiceData } from '../../interfaces/IInvoice';
import {
  GENERATE_INVOICE_ERROR,
  GENERATE_INVOICE_REQUEST,
  GENERATE_INVOICE_SUCCESS,
  GET_INVOICES_LIST_ERROR,
  GET_INVOICES_LIST_REQUEST,
  GET_INVOICES_LIST_SUCCESS,
  REMOVE_INVOICE_ERROR,
  REMOVE_INVOICE_REQUEST,
  REMOVE_INVOICE_SUCCESS,
  SET_INVOICES_FIRST_RENDER,
} from '../types';
import isEqual from 'lodash.isequal';

const initialState: IInvoiceData = {
  invoicesList: [],
  error: null,
  page: 1,
  limit: 5,
  sort: 'createdAt',
  sortDirect: 'desc',
  count: 0,
  totalPages: 0,
  loading: true,
  isFirstRender: true,
};

const invoice = (state = initialState, action: IActions) => {
  switch (action.type) {
    case GET_INVOICES_LIST_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
        page: action.data.page,
        limit: action.data.limit,
        sort: action.data.sort,
        sortDirect: action.data.sortDirect,
      };
    }

    case GET_INVOICES_LIST_SUCCESS: {
      const updatedState = {
        ...state,
        invoicesList: action.data.invoices,
        count: action.data.count,
        totalPages: action.data.totalPages,
        loading: false,
        isFirstRender: false,
      };

      return isEqual(updatedState, state) ? state : updatedState;
    }

    case GET_INVOICES_LIST_ERROR: {
      return {
        ...state,
        error: action.data,
      };
    }

    case SET_INVOICES_FIRST_RENDER: {
      return {
        ...state,
        isFirstRender: action.data,
      };
    }

    case REMOVE_INVOICE_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }

    case REMOVE_INVOICE_SUCCESS: {
      const newInvoicesList = state.invoicesList?.filter(
        (i) => i?.invoiceFile?.name !== action.data.invoiceName
      );

      return {
        ...state,
        invoicesList: newInvoicesList,
      };
    }

    case REMOVE_INVOICE_ERROR: {
      return {
        ...state,
        error: action.data,
      };
    }

    case GENERATE_INVOICE_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }

    case GENERATE_INVOICE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case GENERATE_INVOICE_ERROR: {
      return {
        ...state,
        error: action.data,
      };
    }

    default:
      return state;
  }
};

export default invoice;
