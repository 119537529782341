import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store/store';
import { Button, IconButton, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import { getProductsRequest } from '../../../store/actions/products.actions';
import { setOrdersSearchValue, getOrdersRequest } from './../../../store/actions/orders.actions';
import { getUsersRequest } from '../../../store/actions/users.actions';
import { failSnackBar } from './../../../store/actions/snackbar.actions';
import { COLORS } from '../../../values/colors';
import queryString from 'query-string';
import { cleanObject } from '../../../utils/cleanObject';
import { getDrawRequest } from '../../../store/actions/draw.actions';

interface SearchProps {}

const useStyles = makeStyles((theme) => ({
  search: {
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'position': 'relative',
    'borderRadius': theme.shape.borderRadius,
    'marginRight': theme.spacing(2),
    'marginLeft': 0,
    'width': '100%',
    'minWidth': '220px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    '@media (max-width: 475px)': {
      alignItems: 'left',
      flexDirection: 'column',
      padding: '2px 0',
    },
  },
  searchIcon: {
    'padding': theme.spacing(0, 2),
    'height': '100%',
    'pointerEvents': 'none',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'color': '#fff',
    '@media (max-width: 475px)': {
      display: 'none',
    },
  },
  searchSelect: {
    color: 'white',
    width: '140px',
  },
  searchSelectDark: {
    '&:after': {
      'border-color': COLORS.secondaryDarkGreen,
      'color': 'white',
    },
  },
  inputRoot: {
    '& :-webkit-autofill': {
      '-webkit-box-shadow': `0 0 0px 1000px ${COLORS.primaryGreen} inset`,
      '-webkit-text-fill-color': 'white',
    },
    'position': 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '8px',
      marginLeft: '25px',
      height: '1px',
      backgroundColor: theme.palette.type === 'dark' ? '#fff' : 'rgba(0, 0, 0, 0.42)',
      transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      pointerEvents: 'none',
    },

    'color': '#fff',
    '@media (max-width: 475px)': {
      width: '150px',
    },
  },
  form: {
    'display': 'block',
    '@media (max-width: 475px)': {
      width: '200px',
    },
  },
  inputInput: {
    'padding': theme.spacing(1, 1, 1, 0),
    'paddingLeft': `calc(1em + ${theme.spacing(4)}px)`,
    'transition': theme.transitions.create('width'),
    'width': '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    '@media (max-width: 475px)': {
      paddingLeft: '4px',
    },
  },
  searchBtn: {
    'transition': 'opacity 0s',
    // 'backgroundColor': 'rgb(188, 188, 188, 0.5)',
    'alignSelf': 'center',
    'marginRight': '40px',
    'marginBottom': '6px',
    'color': '#fff',
    '&:hover': {
      backgroundColor: COLORS.secondaryGreen,
    },
  },
  crossIcon: {
    color: '#fff',
    transition: '0.5s ease',

  },
  menuItem: {
    '&:hover': {
      backgroundColor: COLORS.primaryOttoman,
    },
    '&.Mui-selected': {
      backgroundColor: COLORS.secondaryOttoman,
    },
  },
  selectRoot: {
    'color': 'white',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}));

const searchOptions = [
  { name: 'Продукти', key: 'products' },
  { name: 'Категорії', key: 'categories' },
  { name: 'Користувачі', key: 'users' },
  { name: 'Замовлення', key: 'orders' },
  { name: 'Розіграші', key: 'draws' },
];

const Search: React.FC<SearchProps> = (props) => {
  const { paginationLimit, sort, sortDirect, filter } = useSelector((state: RootState) => state.products);
  const {
    paginationLimit: usersLimit,
    sort: usersSort,
    sortDirect: usersSortDirect,
  } = useSelector((state: RootState) => state.users);

  const {
    limit: ordersLimit,
    filter: ordersFilter,
    sortingInfo: ordersSorting,
  } = useSelector((state: RootState) => state.orders);

  const { darkMode } = useSelector((state: RootState) => state.theme);
  const classes = useStyles(props);
  const history = useHistory();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [searchOption, setSearchOption] = useState<string>('products');

  const initialValues = {
    searchValue: '',
    searchOption: searchOptions[0].key,
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.searchOption === 'products') {
        if (!isNaN(Number(values.searchValue))) {
          filter.id = Number(values.searchValue);
        } else {
          filter.name = values.searchValue;
        }
        const querySearch = cleanObject({
          limit: paginationLimit !== 10 ? paginationLimit : null,
          sort: sort !== 'id' ? sort : null,
          sortDirect: sortDirect !== 'asc' ? sortDirect : null,
          filterId: filter.id,
          filterName: filter.name,
          filterShop: filter.shop,
          filterCategory: filter.category,
          filterPriceMin: filter.price[0],
          filterPriceMax: filter.price[1],
        });
        if (history.location.pathname === '/products') {
          dispatch(getProductsRequest(1, paginationLimit, sort, sortDirect, filter));
        }
        history.push({
          pathname: '/products',
          search: queryString.stringify(querySearch),
          state: querySearch,
        });
      }
      if (values.searchOption === 'categories') {
        history.push({
          pathname: '/search',
          state: {
            searchValue: values.searchValue,
            searchOption: values.searchOption,
          },
        });
      }
      if (values.searchOption === 'users') {
        if (history.location.pathname === '/users') {
          dispatch(getUsersRequest(1, usersLimit, usersSort, usersSortDirect, values.searchValue));
        }
        history.push({
          pathname: '/users',
          search: queryString.stringify({
            limit: usersLimit,
            sort: usersSort,
            sortDirect: usersSortDirect,
            searchValue: values.searchValue,
          }),
          state: {
            limit: usersLimit,
            sort: usersSort,
            sortDirect: usersSortDirect,
            searchValue: values.searchValue,
          },
        });
      }
      if (values.searchOption === 'orders') {
        if (values.searchValue.replace(/\d/g, '')) {
          dispatch(failSnackBar('При пошуку замовлення можна використовувати тільки цифри.'));
          setSubmitting(false);
          return;
        }

        if (history.location.pathname === '/orders') {
          dispatch(getOrdersRequest(1, ordersLimit, ordersFilter, ordersSorting, values.searchValue));
        } else {
          dispatch(setOrdersSearchValue(values.searchValue));
        }

        const { phoneNumber, ...otherOrdersFilter } = ordersFilter;

        const querySearch = cleanObject({
          limit: ordersLimit !== 10 ? ordersLimit : null,
          sort: ordersSorting.sort !== 'createdAt' ? ordersSorting.sort : null,
          sortDirect: ordersSorting.sortDirect !== 'desc' ? ordersSorting.sortDirect : null,
          ...otherOrdersFilter,
        });

        history.push({
          pathname: '/orders',
          search: queryString.stringify({ ...querySearch, searchValue: values.searchValue }),
          state: { ...querySearch, searchValue: values.searchValue },
        });
      }
      if (values.searchOption === 'draws') {
        // if (history.location.pathname === '/draws') {
        //   dispatch(getDrawRequest(1, values.searchValue));
        // }
        if (Number(values.searchValue)) {
          dispatch(getDrawRequest(1, values.searchValue));
        } else {
          dispatch(failSnackBar('При пошуку розіграшів можна використовувати тільки цифри.'));
          setSubmitting(false);
          return;
        }
        // const querySearch = cleanObject({
        //   limit: DrawLimit !== 10 ? DrawLimit : null,
        //   sort: DrawSorting.sort !== 'dateDraw' ? DrawSorting.sort : null,
        //   sortDirect: DrawSorting.sortDirect !== 'desc' ? DrawSorting.sortDirect : null,
        //   filterName: values.searchValue,
        // });

        history.push({
          pathname: '/draws',
          // search: queryString.stringify(querySearch),
          // state: querySearch,
        });
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (location.pathname === '/products') {
      formik.setFieldValue('searchOption', 'products');
    } else if (location.pathname === '/tree-categories') {
      setSearchOption('categories');
      formik.setFieldValue('searchOption', 'categories');
    } else if (location.pathname === '/users') {
      formik.setFieldValue('searchOption', 'users');
    } else if (location.pathname === '/orders') {
      formik.setFieldValue('searchOption', 'orders');
    } else if (location.pathname === '/draws') {
      formik.setFieldValue('searchOption', 'draws');
    }
  }, [location.pathname]);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const isSearchValueEmpty = !formik.values.searchValue.trim().length;

  const updateUrlForSearchOption = () => {
    const updateUrl = (pathname: string, searchParams?: any) => {
      history.push({
        pathname,
        search: queryString.stringify(searchParams),
        state: searchParams,
      });
    };
    if (history.location.pathname === '/products') {
      filter.name = null;
      filter.id = null;
      dispatch(getProductsRequest(1, paginationLimit, sort, sortDirect, filter));
      updateUrl('/products', { searchValue: null });
    } else if (history.location.pathname === '/users') {
      dispatch(getUsersRequest(1, usersLimit, usersSort, usersSortDirect, null));
      updateUrl('/users', { searchValue: null });
    } else if (history.location.pathname === '/orders') {
      dispatch(getOrdersRequest(1, ordersLimit, ordersFilter, ordersSorting, null));
      updateUrl('/orders', { searchValue: null });
    } else if (history.location.pathname === '/draws') {
      dispatch(getDrawRequest(1, null));
      updateUrl('/draws', { searchValue: null });
    }
  };

  const clearIcon = () => {
    return (
      <IconButton
        className={classes.crossIcon}
        // disabled={formik.isSubmitting || isSearchValueEmpty}
        // style={isSearchValueEmpty ? { opacity: '0' } : { opacity: '1' }}
        onClick={() => {
          formik.setFieldValue('searchValue', '');
          updateUrlForSearchOption();
        }}
        onMouseDown={handleMouseDown}
      >
        <ClearIcon/>
      </IconButton>
    );
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
      className={classes.form}
    >
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        {/* product, categori, user, order, raffle */}
        <Select
          type="select"
          name="searchOption"
          id={'option-field'}
          value={formik.values.searchOption}
          className={`${classes.searchSelect} ${darkMode && classes.searchSelectDark}`}
          classes={{ root: classes.selectRoot }}
          onChange={(e) => {
            setSearchOption(e.target.value as string);
            formik.handleChange(e);
            if (e.target.value === 'categories') {
              history.push(`/tree-categories`);
            } else {
              history.push(`/${e.target.value}`);
            }
          }}
          onBlur={formik.handleBlur}
        >
          {searchOptions.map((option) => {
            return (
              <MenuItem
                className={darkMode ? '' : classes.menuItem}
                key={'option' + option.key}
                value={option.key}
              >
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
        <InputBase
          name="searchValue"
          id="searchValue-field"
          onChange={(e) => {
            formik.handleChange(e);
            formik.handleSubmit();
            // if (history.location.pathname === '/orders') {
            //   formik.handleSubmit();
            // }
          }}
          onBlur={formik.handleBlur}
          placeholder="Пошук…"
          value={formik.values.searchValue}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          endAdornment={clearIcon()}
        />
        <Button
          // style={
          //   isSearchValueEmpty ? { opacity: '0', height: '0', padding: '0', margin: '0' } : { opacity: '1' }
          // }
          type="submit"
          className={classes.searchBtn}
          // disabled={formik.isSubmitting || isSearchValueEmpty}
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => console.log(paginationLimit, sort, sortDirect, filter)}
        >
          Знайти
        </Button>
      </div>
    </form>
  );
};

export default Search;
