import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import useOrders from '../../hooks/useOrders';
import { setOrdersFirstRender } from '../../store/actions/orders.actions';
import OrdersTable from '../../components/Tables/Orders/OrdersTable';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import LightScreenLinearProgress from '../../components/LightScreen/LightScreenLinearProgress';
import ColumnsMenu from '../../components/ColumnsMenu/ColumnsMenu';
import ColumnsBtn from '../../components/ColumnsBtn/ColumnsBtn';
import AddCategoryModal from '../../components/Modals/AddCategoryModal/AddCategoryModal';
import OrderFilter from '../../components/Tables/Orders/Filter/OrderFilter';
import Preloader from '../../components/Preloader/Preloader';
import Badge from '@material-ui/core/Badge';
import { cleanObject } from '../../utils/cleanObject';

enum cols {
  orderId = 'OrderID',
  userId = 'UserID',
  createdAt = 'Створено',
  updatedAt = 'Оновлено',
  tel = 'Телефон',
  email = 'Email',
  name = "Ім'я",
  street = 'Відділення',
  comment = 'Коментар',
  notcall = 'Не передзвонювати',
  deliveryMethod = 'Спосіб доставки',
  courierDeliveryAddress = "Адреса для кур'єрської доставки",
  sum = 'Сума',
  PaymentMethod = 'Спосіб оплати',
  status = 'Статус',
  liqpayPaymentStatus = 'Статуc оплати',
}

const OrdersPage: React.FC = () => {
  const { loading, searchValue, filter, page, limit, sortingInfo, isFirstRender } = useSelector(
    (state: RootState) => state.orders
  );
  const { list } = useOrders(searchValue);
  const [showColumnsMenu, setShowColumnsMenu] = useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeColumns, setActiveColumns] = useState<string[]>([
    cols.orderId,
    cols.userId,
    cols.createdAt,
    cols.updatedAt,
    cols.tel,
    cols.email,
    cols.name,
    cols.street,
    cols.comment,
    cols.notcall,
    cols.deliveryMethod,
    cols.courierDeliveryAddress,
    cols.sum,
    cols.PaymentMethod,
    cols.status,
    cols.liqpayPaymentStatus,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setOrdersFirstRender(true));
    };
  }, []);

  useLayoutEffect(() => {
    const querySearch = {} as QueryTypes;
    if (!!page && page !== 1) querySearch.page = page;
    if (!!limit && limit !== 10) querySearch.limit = limit;
    if (!!sortingInfo.sort && sortingInfo.sort !== 'createdAt') querySearch.sort = sortingInfo.sort;
    if (!!sortingInfo.sortDirect && sortingInfo.sortDirect !== 'desc')
      querySearch.sortDirect = sortingInfo.sortDirect;
    if (!!filter.status) querySearch.status = filter.status;
    if (!!filter.paymentStatus) querySearch.paymentStatus = filter.paymentStatus;
    if (!!filter.phoneNumber && !searchValue) querySearch.phoneNumber = filter.phoneNumber;
    if (!!filter.minAmount && !!filter.maxAmount) {
      querySearch.minAmount = filter.minAmount;
      querySearch.maxAmount = filter.maxAmount;
    }
    if (!!filter.startDate && !!filter.endDate) {
      querySearch.endDate = filter.endDate;
      querySearch.startDate = filter.startDate;
    }
    if (!!searchValue) querySearch.searchValue = searchValue;

    history.push({
      pathname: '/orders',
      search: queryString.stringify(querySearch),
      state: { update: true },
    });
  }, [page, limit, filter, sortingInfo, searchValue]);

  const handleColumns = (column: string) =>
    activeColumns.includes(column)
      ? setActiveColumns(activeColumns.filter((col) => col !== column))
      : setActiveColumns([...activeColumns, column]);

  const isDisabledFilter = () => searchValue && list.length === 1 && list[0].id === searchValue;

  const countActiveFilter = () => {
    let countFilter = Object.keys(cleanObject(filter)).length;
    if (filter.minAmount && filter.maxAmount) {
      countFilter--;
    }
    if (filter.startDate && filter.endDate) {
      countFilter--;
    }
    if (isDisabledFilter()) return 0;
    return countFilter;
  };

  return (
    <>
      {loading && <LightScreenLinearProgress />}

      {showColumnsMenu && (
        <ColumnsMenu
          allColumns={cols}
          activeColumns={activeColumns}
          showColumnsMenu={showColumnsMenu}
          setShowColumnsMenu={setShowColumnsMenu}
          handleColumns={handleColumns}
        />
      )}

      <div className="btns-wrapper">
        <Badge
          overlap="rectangular"
          color="error"
          badgeContent={countActiveFilter()}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <OrderFilter disabledFilter={isDisabledFilter()} />
        </Badge>
        <ColumnsBtn handleClick={() => setShowColumnsMenu(true)} />
      </div>

      <div className="content-wrapper">
        <AddCategoryModal openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} />

        {isFirstRender ? <Preloader /> : list && <OrdersTable list={list} activeColumns={activeColumns} />}
      </div>
    </>
  );
};

export default OrdersPage;

type QueryTypes = {
  page?: string | number;
  limit?: string | number;
  status?: string;
  paymentStatus?: string;
  phoneNumber?: string;
  minAmount?: string | number;
  maxAmount?: string | number;
  startDate?: string;
  endDate?: string;
  sort?: string;
  sortDirect?: string;
  searchValue?: string | null;
};
