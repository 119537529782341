import React, { FC, useState } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import SmsIcon from '@material-ui/icons/Sms';
import DescriptionIcon from '@material-ui/icons/Description';
import styles from './OrdersTable.module.scss';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import GoBackBtn from '../../GoBackBtn/GoBackBtn';
import { ICurrentOrder } from '../../../interfaces/IOrders';
import { COLORS } from '../../../values/colors';
import { AppDispatch, RootState } from '../../../store/store';
import { deleteOrderByIdRequest, updateOrderParcelNumber } from '../../../store/actions/orders.actions';
import DeleteIcon from '@material-ui/icons/Delete';
import { api } from '../../../api/api';

interface OrdersItemTableHeaderProps {
  order: ICurrentOrder;
  darkMode: boolean;
}

function getAmountData(amount: number, amountWithoutDiscount: number | null) {
  const isDiscount = amountWithoutDiscount ? amountWithoutDiscount > amount : false;

  amountWithoutDiscount = amountWithoutDiscount ?? 0;

  const totalAmount = isDiscount ? amount : amountWithoutDiscount;

  return {
    isDiscount,
    totalAmount: totalAmount ?? 0,
  };
}

const OrdersItemTableHeader: FC<OrdersItemTableHeaderProps> = ({ order, darkMode }) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const { user } = useSelector((state: RootState) => state.user);

  const { amountWithoutDiscount, amount } = order;

  const { isDiscount, totalAmount } = getAmountData(amount, amountWithoutDiscount);

  // const totalAmount = order.productToOrder.map((item) => item.amount).reduce((a, b) => a + b, 0);

  const parcelNumberArray = order?.productToOrder.reduce((acc: string[], { parcelNumber }) => {
    if (parcelNumber && parcelNumber !== '0' && !acc.includes(parcelNumber)) {
      acc.push(parcelNumber);
    }
    return acc;
  }, []);

  const preparedForRenderParcelNumber =
    parcelNumberArray.length !== 0 ? parcelNumberArray.join(' ; ') : 'Не встановлено';

  const handleChangeParcelNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value;
    const inputValueToNumber = Number(targetValue);
    if (
      Number.isNaN(inputValueToNumber) ||
      !Number.isInteger(inputValueToNumber) ||
      inputValueToNumber < 0 ||
      targetValue.includes('.')
    ) {
      setError('Введіть ціле число');
      return;
    }

    setError('');
    setValue(targetValue.trim());
  };

  const onSubmitParcelNumber = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!value.trim()) {
      return;
    }

    order.productToOrder.forEach((el) => {
      dispatch(updateOrderParcelNumber(order.id, el.id, { parcelNumber: value.split(' ').join('') }));
    });
    setValue('');
  };

  const handleDelete = () => {
    dispatch(deleteOrderByIdRequest(order.id));
  };

  return (
    <div className={styles.orderHeader}>
      <GoBackBtn handleGoBack={() => history.push('/orders')} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px 0',
          paddingRight: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}
        >
          <div className={styles.headerTitle}>Замовлення № {order.id}</div>
          <div className={styles.headerUserDataWrapp}>
            <div className={styles.headerUserData}>
              <PersonPinIcon
                style={darkMode ? { color: COLORS.darkGreen } : { color: COLORS.primaryGreen }}
              />
              {order.additionalFirstName ? order.additionalFirstName : order.user.firstName}&nbsp;
              {order.additionalLastName ? order.additionalLastName : order.user.lastName}
            </div>
            {user?.role.name === 'moderator' && (
              <div className={styles.deleteIcon}>
                <a href="#" onClick={handleDelete}>
                  <DeleteIcon style={{ color: COLORS.primaryRed }} />
                  Видалити
                </a>
              </div>
            )}
          </div>
          <div className={styles.headerUserData}>
            <a href={`mailto:${order.additionalEmail ? order.additionalEmail : order.user.email}`}>
              <EmailIcon style={darkMode ? { color: COLORS.darkGreen } : { color: COLORS.primaryGreen }} />
              {order.additionalEmail ? order.additionalEmail : order.user.email}
            </a>
          </div>
          {/* <div className={styles.headerUserData}>
        <a
          href={`tel:${
            order.additionalNumber
              ? order.additionalNumber.replace(/ /g, '')
              : order.user.phoneNumber.replace(/ /g, '')
          }`}
        >
          <PhoneInTalkIcon style={darkMode ? { color: COLORS.darkGreen } : { color: COLORS.primaryGreen }} />
          {order.additionalNumber ? order.additionalNumber : order.user.phoneNumber}
        </a>
      </div> */}
          {order.notcall ? (
            <div className={styles.headerUserData}>
              <PriorityHighIcon
                style={darkMode ? { color: COLORS.darkGreen } : { color: COLORS.primaryGreen }}
              />
              <div className={styles.notcall}> Не Передзвонювати</div>
            </div>
          ) : (
            <div className={styles.headerUserData}>
              <PriorityHighIcon
                style={darkMode ? { color: COLORS.primaryRed } : { color: COLORS.secondaryRed }}
              />
              <div className={styles.notcall}>Передзвонювати</div>
            </div>
          )}
          {order.comment && (
            <div className={styles.commentBlock}>
              <SmsIcon style={darkMode ? { color: COLORS.darkGreen } : { color: COLORS.primaryGreen }} />
              <b>Коментар до замовлення: </b>
              <span>{order.comment}</span>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}
        >
          <div className={styles.amount}>Нараховано кешбеку: {order.orderCashback}</div>
          <div className={styles.amount}>Використано кешбеку: {order.cashbackUsed}</div>
          <div className={styles.amount}>
            Використано купон на суму:{' '}
            {order.coupon && Object.keys(order.coupon).length ? order.coupon.discountAmount : 0}
          </div>

          {/* <div>
        <DescriptionIcon style={darkMode ? { color: COLORS.darkGreen } : { color: COLORS.primaryGreen }} />
        <span className={styles.headerUserData}>
          <b>Номер ТТН:</b> {preparedForRenderParcelNumber}
        </span>
        <p className={styles.remark}>
          Ви можете ввести номер ТТН, який буде для усіх товарів даного замовлення
        </p>
        <p className={styles.remark}>* Змінити номер ТТН для кожного товару окремо можна у таблиці</p>
        <form onSubmit={onSubmitParcelNumber} className={styles.formParcelNumber}>
          <input
            type="text"
            value={value}
            onChange={handleChangeParcelNumber}
            style={{ width: '220px' }}
            placeholder={'ввести номер ТТН'}
            title="Введіть ціле цисло"
          />
          <button disabled={!Number.isInteger(Number(value))}>Гаразд</button>
        </form>
        {error && <p className={styles.errorMessage}>{error}</p>}
      </div> */}

          <div className={styles.amount}>
            Загальна сума:&nbsp;{totalAmount}
            {isDiscount && <sup className={styles['orderHeader__price-discount-label']}>%</sup>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersItemTableHeader;
