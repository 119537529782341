import { NotificationDataStatus } from '../../enums/notifications';
import { IActions } from '../../interfaces/actions';
import { INotification, INotificationState } from '../../interfaces/INotification';
import arrayReplaceObjById from '../../utils/array-replace-obj-by-id';
import mergeArrayOfObjects from '../../utils/merge-array';
import {
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_ERROR,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATION_SUCCESS,
} from '../types';

const initialState: INotificationState = {
  notifications: [],
  dataStatus: NotificationDataStatus.IDLE,
};

function getSortNotificationByDate(isAsc?: boolean) {
  return ({ updatedAt: updatedAt1 }, { updatedAt: updatedAt2 }) => {
    const date1 = new Date(updatedAt1);
    const date2 = new Date(updatedAt2);

    if (isAsc) {
      return date1.getTime() - date2.getTime();
    } else {
      return date2.getTime() - date1.getTime();
    }
  };
}

const notifications = (state = initialState, action: IActions) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATIONS_SUCCESS: {

      const sortNotificationByDateFunc = getSortNotificationByDate(); 

      const updatedNotifications = mergeArrayOfObjects<INotification>(
        state.notifications,
        Array.isArray(action.data) ? action.data : []
      ).sort(sortNotificationByDateFunc);

      return {
        ...state,
        notifications: updatedNotifications,
        dataStatus: NotificationDataStatus.SUCCESS,
      };
    }

    case GET_ALL_NOTIFICATIONS: {
      return {
        ...state,
        dataStatus: NotificationDataStatus.PENDING,
      };
    }

    case GET_ALL_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        dataStatus: NotificationDataStatus.ERROR,
      };
    }

    case READ_NOTIFICATION_SUCCESS: {
      const updatedNotifications = action.data
        ? arrayReplaceObjById([...state.notifications], [action.data])
        : state.notifications;

      return {
        ...state,
        notifications: updatedNotifications,
        dataStatus: NotificationDataStatus.SUCCESS,
      };
    }

    default:
      return state;
  }
};

export default notifications;
