import { call, put } from '@redux-saga/core/effects';
import { SagaIterator } from '@redux-saga/types';
import { IActions } from '../../interfaces/actions';

import {
  deleteCommentError,
  deleteCommentSuccess,
  getCommentsByRangeError,
  getCommentsByRangeSuccess,
  getCommentsError,
  getCommentsSuccess,
} from '../actions/comments.actions';
import { failSnackBar, successSnackBar } from '../actions/snackbar.actions';
import { apiDeleteComment, apiGetByRangeComments, apiGetComments } from './services/comments.service';

export function* getCommentsWorker({ data: { page, limit, sort, sortDirect } }: IActions): SagaIterator {
  try {
    const comments = yield call(apiGetComments, page, limit, sort, sortDirect);
    yield put(getCommentsSuccess(comments));
  } catch (error) {
    yield put(failSnackBar(error.message));
    yield put(getCommentsError(error.message));
  }
}

export function* getCommentsByDateRangeWorker({ data: datesArray }: IActions): SagaIterator {
  try {
    const comments = yield call(apiGetByRangeComments, datesArray);
    yield put(getCommentsByRangeSuccess(comments));
  } catch (error) {
    yield put(failSnackBar(error.message));
    yield put(getCommentsByRangeError(error.message));
  }
}

export function* deleteCommentWorker({ data: payload }: IActions): SagaIterator {
  try {
    const { data } = yield call(apiDeleteComment, payload);
    yield put(deleteCommentSuccess(data));
    yield put(successSnackBar());

  } catch (error) {
    yield put(failSnackBar(error.message));
    yield put(deleteCommentError(error.message));
  }
}
