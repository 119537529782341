import { useEffect, useState } from 'react';

const useQueryDelay = (
  payload: object,
  getCurrentRequest: (filter: object) => void,
  option?: boolean,
  delay: number = 300
) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    if (!option) {
      const newTimer = setTimeout(() => {
        getCurrentRequest(payload);
      }, delay);
      setTimer(newTimer);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [payload]);
};

export default useQueryDelay;
