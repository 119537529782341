import React from 'react';
import { Form, FormikProps } from 'formik';
import { makeStyles, ThemeOptions } from '@material-ui/core/styles';
import { Button, createStyles, DialogActions, Theme, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { IFormCarouselValues } from '../../../../interfaces/widget-form';
import { RootState } from '../../../../store/store';
import { COLORS } from '../../../../values/colors';
import DateMoment from '../../../Common/Date-moment';

import preloader from '../../../Preloader/loading.svg';

const useStyles = makeStyles(
  (theme: Theme): ThemeOptions =>
    createStyles({
      form: {
        width: '100%',
      },
      inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
      },
      title: {
        width: '210px',
        marginRight: '10px',
      },
      input: {
        width: '150px',
        textAlign: 'center',
      },
      btn: {
        padding: '6px 15px 6px 15px',
        borderRadius: '30px',
        color: COLORS.primaryLight,
        marginBottom: '23px'
      },
      secondaryHeading: {
        flexGrow: 1,
        marginLeft: '30px',
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
      btnLight: {
        'backgroundColor': COLORS.primaryGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryGreen,
        },
      },
      btnDark: {
        'backgroundColor': COLORS.darkGreen,
        '&:hover': {
          backgroundColor: COLORS.secondaryDarkGreen,
        },
      },
      preloaderContainer: {
        width: '230px'
      },
      preloader: {
        marginLeft: '130px',
        width: '60px',
      }
    })
);

const InnerForm: React.FC<FormikProps<IFormCarouselValues>> = () => {
  const classes = useStyles();

  const { darkMode } = useSelector((state: RootState) => state.theme);

  const feedSettings = useSelector((state: RootState) =>
    state.settings.list.filter(item => item.name === 'feed-settings')
  );
  const isLoading = useSelector((state: RootState) =>
    state.settings.loading
  );

  return (
    <Form className={classes.form}>
      <div className={classes.inputContainer}>
        <Typography className={classes.title}>
          Остання успішна генерація фіда
        </Typography>
        <Typography className={classes.secondaryHeading}>
          {feedSettings[0]?.settings?.lastGenerated ?
            <DateMoment date={feedSettings[0].settings.lastGenerated}/> :
            'Невідомо (фід відстуній / не оновлювався)'
          }
        </Typography>
      </div>
      <DialogActions>
        { isLoading ?
          <div className={classes.preloaderContainer}>
            <img className={classes.preloader} src={preloader} alt='loading'/>
          </div>
            :
          <Button
            className={classNames(classes.btn, darkMode ? classes.btnDark : classes.btnLight)}
            variant="contained"
            type="submit"
          >
            Оновити фід
          </Button>
        }
      </DialogActions>
    </Form>
  );
};

export default InnerForm;
