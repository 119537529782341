import { api } from '../../../api/api';
import { ICategoryPriority } from '../../../interfaces/ISettings';
import { IActions } from '../../../interfaces/actions';

export async function fetchedSettings() {
  const settings = await api.settings.get();
  return settings.data;
}

export async function getParserSettingsRequest() {
  const settings = await api.settings.getParsers();
  return settings.data;
}

export async function generateFeedRequest() {
  const settings = await api.settings.generateFeed();
  return settings.data;
}

export async function updateSettings(data: IActions) {
  const updatedSettings = await api.settings.put(data);
  return updatedSettings.data;
}

export async function getCategoriesWithPriority() {
  const categories = await api.settings.getCategoriesWithPriority();
  return categories.data;
}

export async function changeCategoriesPriority(categoryPriority: ICategoryPriority[]) {
  const changeResult = await api.settings.changeCategoriesPriority(categoryPriority);
  return changeResult.data;
}
